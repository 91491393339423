var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"specs-list",style:({ background: _vm.params.background })},[_vm._l((_vm.params.innerElements),function(spec,elementIndex){return _c('div',{key:elementIndex,class:[
      'specs-list-item',
      _vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex
        ? ' specs-list-item--active'
        : '',
      spec.props.isMain === 'Yes' ? 'specs-list-item--header' : '',
    ],on:{"click":function($event){$event.stopPropagation();return _vm.setActiveElement({ componentIndex: _vm.componentIndex, elementIndex })}}},[(spec.props.titleContent.trim() !== '')?_c('div',{staticClass:"specs-list-item-key",class:'stream-text-' + _vm.params.titleFontSize,style:({
        color: _vm.params.titleColor,
        fontFamily: _vm.params.titleFontFamily,
        fontWeight: _vm.params.titleFontWeight,
        border: `${_vm.params.borderWidth}px solid ${_vm.params.borderColor}`
      }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(spec.props.titleContent))}}):_vm._e(),(spec.props.descriptionContent.trim() !== '')?_c('div',{staticClass:"specs-list-item-value",class:'stream-text-' + _vm.params.descriptionFontSize,style:({
        color: _vm.params.descriptionColor,
        fontFamily: _vm.params.descriptionFontFamily,
        fontWeight: _vm.params.descriptionFontWeight,
        border: `${_vm.params.borderWidth}px solid ${_vm.params.borderColor}`
      }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(spec.props.descriptionContent))}}):_vm._e(),(
        _vm.activeElement === elementIndex &&
        _vm.activeComponent === _vm.componentIndex &&
        _vm.params.innerElements.length > 1
      )?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeElement({ componentIndex: _vm.componentIndex, elementIndex })}}}):_vm._e()],1)}),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }