<template>
  <svg @click="openPreview(epackID)" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" fill="white" stroke="#D6DEE2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9454 11.168C20.2956 10.9345 20.7545 10.9453 21.0933 11.1949L30.5933 18.1949C31.0379 18.5226 31.1328 19.1486 30.8052 19.5932C30.4775 20.0378 29.8515 20.1327 29.4069 19.8051L28.0001 18.7685V28C28.0001 28.5523 27.5524 29 27.0001 29H22.0001H18.0001H13.0001C12.4478 29 12.0001 28.5523 12.0001 28V18.8685L10.5548 19.8321C10.0953 20.1384 9.47441 20.0142 9.16806 19.5547C8.8617 19.0952 8.98588 18.4743 9.44541 18.168L12.434 16.1755C12.442 16.17 12.4501 16.1646 12.4583 16.1593L19.9454 11.168ZM19.0001 27H21.0001V23H19.0001V27ZM23.0001 27V22C23.0001 21.4477 22.5524 21 22.0001 21H18.0001C17.4478 21 17.0001 21.4477 17.0001 22V27H14.0001V17.5352L20.4714 13.221L26.0001 17.2948V27H23.0001Z" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "Home",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
