var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stream-cols-2",style:({ background: _vm.params.background, justifyContent: _vm.params.colsAlign })},[_vm._l((_vm.params.innerElements),function(icon,elementIndex){return _c('div',{key:elementIndex,class:'stream-col' + ((_vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex) ? ' stream-col--active' : ''),on:{"click":function($event){$event.stopPropagation();return _vm.setActiveElement({componentIndex: _vm.componentIndex, elementIndex})}}},[_c('div',{staticClass:"stream-col-inner"},[_c('div',{staticClass:"stream-col-img"},[(icon.props.youtubeID)?_c('div',{staticClass:"stream-col-img-inner"},[_c('iframe',{style:({borderRadius:_vm.params.borderRadius+'px'}),attrs:{"src":`https://www.youtube.com/embed/${icon.props.youtubeID}?enablejsapi=1&rel=0&autoplay=0`,"frameborder":"0"}})]):(icon.props.videoSrc)?[_c('video',{style:({borderRadius:_vm.params.borderRadius+'px'}),attrs:{"src":_vm.domain + icon.props.videoSrc,"playsinline":"","muted":"","controls":icon.props.controls == 'true' ? true : false,"loop":icon.props.repeat == 'true' ? true : false,"autoplay":icon.props.autoplay == 'true' ? true : false},domProps:{"muted":true}})]:[_c('img',{style:({borderRadius:_vm.params.borderRadius+'px',width: _vm.imgWidth}),attrs:{"src":_vm.domain + icon.props.src,"alt":""}})]],2),_c('div',{class:['stream-col-title', 'stream-text-'+ _vm.params.titleFontSize],style:({
          textAlign: _vm.params.textAlign,
          '--textalignmobile': _vm.params.textAlignMobile,
          color: _vm.params.titleColor,
          fontFamily: _vm.params.titleFontFamily,
          fontWeight: _vm.params.titleFontWeight,
        }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(icon.props.titleContent))}}),_c('div',{class:['stream-col-description', 'stream-text-'+ _vm.params.descriptionFontSize],style:({
          textAlign: _vm.params.textAlign,
          '--textalignmobile': _vm.params.textAlignMobile,
          color: _vm.params.descriptionColor,
          fontFamily: _vm.params.descriptionFontFamily,
          fontWeight: _vm.params.descriptionFontWeight,
        }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(icon.props.descriptionContent))}})]),(_vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex && _vm.params.innerElements.length > 1)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeElement({componentIndex: _vm.componentIndex, elementIndex})}}}):_vm._e()],1)}),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }