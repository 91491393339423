var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stream-picture-with-text",style:({background: _vm.params.background})},[_c('div',{staticClass:"stream-picture-with-text-img"},[(_vm.params.srcMob !== '')?[_c('img',{staticClass:"stream-mobile-show",attrs:{"src":_vm.domain + _vm.params.srcMob,"alt":""}}),_c('img',{staticClass:"stream-desktop-show",attrs:{"src":_vm.domain + _vm.params.src,"alt":""}})]:[_c('img',{attrs:{"src":_vm.domain + _vm.params.src,"alt":""}})],_c('div',{staticClass:"stream-picture-with-text-items"},_vm._l((_vm.params.innerElements),function(item,elementIndex){return _c('div',{key:elementIndex,staticClass:"stream-picture-with-text-item",class:['stream-picture-with-text-item-vertical-'+item.props.vertical,'stream-picture-with-text-item-horisontal-'+item.props.horisontal, (_vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex) ? 'stream-picture-with-text-item--active' : null],style:({
          width: item.props.textWidth+'%',
        }),on:{"click":function($event){$event.stopPropagation();return _vm.setActiveElement({ componentIndex: _vm.componentIndex, elementIndex })}}},[_c('div',{staticClass:"stream-picture-with-text-title",class:'stream-text-' + item.props.titleFontSize,style:({
            textAlign: item.props.textAlign,
            '--textalignmobile': item.props.textAlignMobile,
            '--titlecolormobile': item.props.titleColorMobile,
            color: item.props.titleColor,
            fontFamily: _vm.params.titleFontFamily,
            fontWeight: _vm.params.titleFontWeight,
          }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(item.props.titleContent))}}),_c('div',{staticClass:"stream-picture-with-text-description",class:'stream-text-' + item.props.descriptionFontSize,style:({
            textAlign: item.props.textAlign,
            '--textalignmobile': item.props.textAlignMobile,
            '--descriptioncolormobile': item.props.descriptionColorMobile,
            color: item.props.descriptionColor,
            fontFamily: _vm.params.descriptionFontFamily,
            fontWeight: _vm.params.descriptionFontWeight,
          }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(item.props.descriptionContent))}}),(_vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex && _vm.params.innerElements.length > 1)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeElement({componentIndex: _vm.componentIndex, elementIndex})}}}):_vm._e()],1)}),0)],2),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }