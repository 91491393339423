<template>
  <div
    :class="activeType === 'mobile' ? 'ra-b6' : 'ra-b7'"
    :style="{ textAlign: params.textAlign }"
  >
    <div :class="['ra-c', activeType === 'mobile' ? 'ra-c0' : 'ra-c8']">
      <div
        v-for="(col, elementIndex) in params.innerElements"
        :class="[
          activeType === 'mobile' ? null : 'ra-d',
          activeType === 'mobile' ? 'ra-c1' : 'ra-d1',
          activeElement === elementIndex && activeComponent === componentIndex
            ? 'constructor-stream-col--active'
            : null,
        ]"
        :key="elementIndex"
        @click.stop="setActiveElement({ componentIndex, elementIndex })"
      >
        <div class="ra-e7 ra-c2">
          <div>
            <img
              loading="lazy"
              :src="
                domain +
                (activeType === 'mobile' ? col.props.srcMob : col.props.src)
              "
              class="ra-e8 ui-n6"
            />
          </div>
        </div>
        <div>
          <span :class="['ra-d5', 'ra-d6', 'ra-d9', 'ra-e2']">
            <span
              :class="'stream-text-' + params.titleFontSize"
              v-html="handleNewLine(col.props.titleContent)"
            ></span>
          </span>
          <span :class="['ra-d9']">
            <span
              :class="'stream-text-' + params.descriptionFontSize"
              v-html="handleNewLine(col.props.descriptionContent)"
            ></span>
          </span>
        </div>
        <RemoveItem
          v-if="
            activeElement === elementIndex &&
            activeComponent === componentIndex &&
            params.innerElements.length > 1
          "
          @click.native="removeElement({ componentIndex, elementIndex })"
        />
      </div>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "OzonCol3",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.constructor-stream-col--active {
  box-shadow: 0 0 1px 1px #f500ed;
}
</style>
