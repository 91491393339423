var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.activeType === 'mobile' ? 'ra-b6' : 'ra-b7',style:({ textAlign: _vm.params.textAlign })},[_c('div',{class:['ra-c', _vm.activeType === 'mobile' ? 'ra-c0' : null]},[_c('div',{class:[
        _vm.activeType === 'mobile' ? 'ra-c1' : 'ra-c4',
        _vm.params.direction === 'left' ? 'ra-c5' : null,
      ]},[_c('div',{class:['ra-e7', _vm.activeType === 'mobile' ? 'ra-c2' : 'ra-c7']},[_c('div',[_c('img',{staticClass:"ra-e8 ui-n6",attrs:{"loading":"lazy","src":_vm.domain + (_vm.activeType === 'mobile' ? _vm.params.srcMob : _vm.params.src)}})])]),_c('div',{class:_vm.activeType === 'mobile' ? null : 'ra-c6'},[_c('div',[_c('span',{class:[
              'ra-d5',
              _vm.activeType === 'mobile' ? 'ra-d6' : null,
              'ra-d9',
              _vm.activeType === 'mobile' ? 'ra-e1' : 'ra-e0',
            ]},[_c('span',{class:'stream-text-' + _vm.params.titleFontSize,domProps:{"innerHTML":_vm._s(_vm.handleNewLine(_vm.params.titleContent))}})]),_c('span',{staticClass:"ra-d9"},[_c('span',{class:'stream-text-' + _vm.params.descriptionFontSize,domProps:{"innerHTML":_vm._s(_vm.handleNewLine(_vm.params.descriptionContent))}})])])])])]),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }