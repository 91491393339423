<template>
  <div class="popup--constructor popup--preview">
    <div class="popup--preview__box">
      <div class="popup--preview__box__header header--small-padding">
        <div class="app-navbar__logo mr-3">
          <va-icon-vuestic />
        </div>
        <div
          class="popup--preview__box__close"
          @click="epackCloseHendler"
        >
          Close e-pack
          <IconClosePopup />
        </div>
      </div>
      <div class="popup--preview__box__header">
        <ConstructorToggle @update="updateToggle" />
         <div
          v-show="inType === 'ConstructorData'"
          class="select-block"
        >
          <div class="select-block__title">{{$t('constructor.SelectLocale')}}</div>
          <vn-select-add
            :label="$t('constructor.Locales')"
            :addHandler="() => {this.addLocalePopupShow = true}"
            :delHandler="()=>{this.removeLocalePopupShow = true}"
            @returnValForDelete="val=>{this.localeForDelete = val}"
            v-model="selectedLocale"
            icon="search"
            :options="localesList"
            noClear
          />
        </div>
        <div
          v-show="inType === 'ConstructorData'"
          class="select-block"
        >
          <div class="select-block__title">{{$t('constructor.SelectTemplate')}}</div>
          <vn-select-add
            :label="$t('constructor.Templates')"
            :non-removables="['master_template']"
            :addHandler="() => {this.addTemplatePopupShow = true}"
            :delHandler="()=>{this.removeTemplatePopupShow = true}"
            @returnValForDelete="val=>{this.templateForDelete = val}"
            v-model="selectedTemplate"
            icon="search"
            :options="templatesList"
            noClear
          />
        </div>
        <div
          v-show="inType === 'ConstructorData'"
          class="select-block"
        >
          <div class="select-block__title">Select size</div>
          <div class="select-block__content">
            <div
              class="select-block__content__item select-block__content__item--stroke"
              :class="activeType === 'desktop' ? 'active' : null"
              @click="changeSize('desktop')"
            >
              <Desktop />
            </div>
            <div
              :class="[
                'select-block__content__item',
                'select-block__content__item--fill',
                activeType === 'laptop' ? 'active' : '',
              ]"
              @click="changeSize('laptop')"
            >
              <Laptop />
            </div>
            <div
              :class="[
                'select-block__content__item',
                'select-block__content__item--stroke',
                activeType === 'tablet' ? 'active' : '',
              ]"
              @click="changeSize('tablet')"
            >
              <Tablet />
            </div>
            <div
              :class="[
                'select-block__content__item',
                'select-block__content__item--fill',
                activeType === 'mobile' ? 'active' : '',
              ]"
              @click="changeSize('mobile')"
            >
              <Mobile />
            </div>
          </div>
        </div>
        <div class="actions" v-if="currentEpackage !== null">
          <div class="select-block__title">Actions</div>
          <div class="select-block__content">
            <div class="history-steps">
              <va-button
                class="constructor-nav-button"
                title="Undo"
                :style="{ marginTop: 0, marginBottom: 0 }"
                @click="historyUndo"
                :disabled="
                  epackHistoryStep === historyLimit ||
                  epackHistoryStep === epackHistory.length - 1
                "
              >
                <Undo />
              </va-button>
              <va-button
                class="constructor-nav-button"
                title="Redo"
                :style="{ marginTop: 0, marginBottom: 0 }"
                @click="historyRedo"
                :disabled="epackHistoryStep === 0"
              >
                <Redo />
              </va-button>
            </div>
            <va-button
              class="constructor-nav-button"
              title="Save"
              :style="{ marginTop: 0, marginBottom: 0 }"
              @click="saveEpack"
              :disabled="isSaved"
            >
              <Save />
            </va-button>
            <va-button
              class="constructor-nav-button"
              title="Compress Images"
              :style="{ marginTop: 0, marginBottom: 0 }"
              @click="compressEpackPopup = true"
            >
              <SaveCompress />
            </va-button>
            <va-button
              class="constructor-nav-button"
              title="Sinc with master_template"
              :style="{ marginTop: 0, marginBottom: 0 }"
              @click="syncPopupShow = true"
              :disabled="currentTemplate === 'master_template'"
            >
              <Sync />
            </va-button>
            <a
              :href="previewPath"
              target="_blank"
              title="View saved page"
              class="va-button constructor-nav-button va-button--default va-button--default va-button--normal"
              :class="isSaved && currentEpackage !== 'untitled' ? null : 'va-button--disabled'"
            >
              <Eye />
            </a>
            <DownloadEpackButton
              :epackID="currentEpackage.toString()"
              title="Download Epack"
              :disabled="!isSaved || currentEpackage === 'untitled'"
              className="va-button va-button--default va-button--normal constructor-nav-button"
            />
            <va-button
              v-if="isAdminUser || checkUserPermission('Publish_stream')"
              class="constructor-nav-button"
              title="Upload to Stream"
              :style="{ marginTop: 0, marginBottom: 0 }"
              :disabled="!isSaved || currentEpackage === 'untitled'"
              @click="uploadPopupShow = true"
            >
              <Upload />
            </va-button>
          </div>
        </div>
        <va-progress-bar
          v-if="loading"
          indeterminate
          :style="{
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: '#ffffff',
          }"
        ></va-progress-bar>
      </div>
      <div class="popup--preview__box__rich popup--constructor__body">
        <div class="constructor-container" v-if="inType === 'EpackData'">
          <EpackData />
        </div>
        <div
          class="constructor-container"
          v-else-if="inType === 'ConstructorData'"
        >
          <ConstructorData />
        </div>
      </div>
    </div>
    <va-modal
      v-model="showSavePopup"
      size="small"
      title="Unsaved progress"
      message="You don't save Your progress on this page. If You leave this page all changes will be lost."
      okText="Stay on page"
      cancelText="Don't save"
      @cancel="leavePage"
      :noEscDismiss="true"
      :noOutsideDismiss="true"
    />

    <va-modal
      v-model="unsavedPopupShow"
      class="constructor-overlay"
      size="medium"
      title="Unsaved Epackage"
      :message="`You don't save Your progress in E-package with id ${unsavedEpackageID} . Please go to E-package's page and save it or yoo'l lose it.`"
      okText="Go to unsaved E-package"
      cancelText="Delete unseved progress"
      @ok="goToEpackage"
      @cancel="cancelRestore"
      :noEscDismiss="true"
      :noOutsideDismiss="true"
    />
    <va-modal
      v-model="syncPopupShow"
      size="medium"
      title="Sync template"
      :message="`This action will remove all changes in current template and replace them to content from Master Template.`"
      okText="Sync"
      cancelText="Cancel"
      @ok="syncComponents(); updateUsedFonts();"
    />
    <va-modal
      v-model="uploadPopupShow"
      size="medium"
      title="Upload to Stream"
      :message="`This action will upload E-package to Stream and clear history of changes in this E-package.`"
      okText="Upload"
      cancelText="Cancel"
      @ok="
        uploadEpackToPanel({epackID: currentEpackage, license: null, isOverwriting });
        historyClean();
      "
    >
    <va-checkbox v-if="isAdminUser" label="Overwrite current" v-model="isOverwriting"  />
    </va-modal>
    <va-modal
      v-model="removeLocalePopupShow"
      size="medium"
      title="Delete Locale"
      :message="`This action will delete locale.`"
      okText="Delete"
      cancelText="Cancel"
      @ok="deleteLocale(localeForDelete)"
    />
    <va-modal
      v-model="removeTemplatePopupShow"
      size="medium"
      title="Delete template"
      :message="`This action will delete template.`"
      okText="Delete"
      cancelText="Cancel"
      @ok="deleteTemplate(templateForDelete)"
    />
    <va-modal
      v-model="addLocalePopupShow"
      size="medium"
      :title="$t('constructor.AddLocale')"
      okText="Add"
      cancelText=""
      @ok="countryVal !== null ? addLocale(languageVal === null ? `${countryVal}` : `${countryVal}_${languageVal}`) : null"
    >
      <div class="layout fluid gutter--md">

        <div class="row">
          <div class="flex xs12 sm6">
          <va-select
            :label="$t('constructor.Country')"
            v-model="countryVal"
            icon="search"
            :options="countryList"
          />
          </div>
          <div class="flex xs12 sm6">
          <va-select
            :label="$t('constructor.Language')"
            v-model="languageVal"
            icon="search"
            :options="languageList"
          />
          </div>
        </div>
      </div>
    </va-modal>
    <va-modal
      v-model="addTemplatePopupShow"
      size="medium"
      :title="$t('constructor.AddLocale')"
      cancelText=""
      okText="Add"
      @ok="templateVal.trim() !== '' ? addTemplate(templateVal +'_template') : null"
    >
      <div class="layout fluid gutter--md">
        <div class="row">
          <div class="flex xs12">
            <va-input
              :label="$t('constructor.Template')"
              v-model="templateVal"
              class="input--new-template"
              noClear
            />
            <div class="new-tempalte__text">
              _template
            </div>
          </div>
        </div>
      </div>
    </va-modal>

    <va-modal
      v-model="chooselicensePopupShow"
      size="medium"
      :title="$t('Choose license')"
      cancelText=""
      okText="Add"
      @ok="uploadEpackToPanel({epackID: currentEpackage, license: licenseVal, isOverwriting});"
    >
      <div class="layout fluid gutter--md">
        <div class="row">
          <div class="flex xs12">
            <va-select
              :label="$t('License')"
              v-model="license"
              :options="licensesList.map(license => (license.name))"
              noClear
            />
          </div>
        </div>
      </div>
    </va-modal>
    <va-modal
      v-model="compressEpackPopup"
      size="medium"
      :title="$t('Choose compression')"
      cancelText=""
      okText="Compress"
      @ok="compressEpack({epackID: currentEpackage, quality: compression});"
    >
      <div class="layout fluid gutter--md">
        <div class="row">
          <div class="flex xs12">
            <va-slider v-model="compression" value-visible :min="0" :max="100" />
          </div>
        </div>
      </div>
    </va-modal>
  </div>
</template>

<script>
import dcopy from "deep-copy";
import { mapActions, mapMutations, mapState } from "vuex";

import VnSelectAdd from '../ui/vn-select-add.vue';
import VaIconVuestic from "../iconset/VaIconVuestic.vue";
import Eye from "../ui/icons/Eye.vue";
import Desktop from "../ui/icons/constructor/Desktop.vue";
import Laptop from "../ui/icons/constructor/Laptop.vue";
import Tablet from "../ui/icons/constructor/Tablet.vue";
import Mobile from "../ui/icons/constructor/Mobile.vue";
import Save from "../ui/icons/constructor/Save.vue";
import SaveCompress from "../ui/icons/constructor/SaveCompress.vue";
import Sync from "../ui/icons/constructor/Sync.vue";
import Undo from "../ui/icons/constructor/Undo.vue";
import Redo from "../ui/icons/constructor/Redo.vue";
import Upload from "../ui/icons/constructor/Upload.vue";
import IconClosePopup from "../ui/icons/IconClosePopup.vue";
import ConstructorToggle from "./ConstructorToggle.vue";
import EpackData from "./EpackData.vue";
import ConstructorData from "./ConstructorData.vue";
import DownloadEpackButton from "./DownloadEpackButton.vue";
import countryList from "../../data/constructor/countries.json";
import languageList from "../../data/constructor/languages.json";

export default {
  name: "Constructor",
  components: {
    VnSelectAdd,
    ConstructorToggle,
    IconClosePopup,
    Save,
    Sync,
    Undo,
    Redo,
    Desktop,
    Laptop,
    Tablet,
    Mobile,
    EpackData,
    ConstructorData,
    DownloadEpackButton,
    VaIconVuestic,
    Eye,
    Upload,
    SaveCompress,
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_CONSTRUCTOR_URL,
      selectedTemplate: null,
      selectedLocale: null,
      unsavedPopupShow: this.unsavedPopupShowStore,
      addLocalePopupShow:false,
      removeLocalePopupShow:false,
      addTemplatePopupShow:false,
      removeTemplatePopupShow:false,
      showSavePopup: false,
      syncPopupShow: false,
      uploadPopupShow: false,
      compressEpackPopup: false,
      inType: "ConstructorData",
      epackHistory: [],
      epackHistoryStep: 0,
      historyLimit: 20,
      availableHistoryUpdate: true,
      localeForDelete: null,
      templateForDelete: null,
      countryVal: null,
      languageVal: null,
      templateVal: '',
      countryList: countryList,
      languageList: languageList,
      license: "",
      licenseVal: "",
      compression: 70,
      isOverwriting: false,
    };
  },
  computed: {
    ...mapState({
      epackData: (state) => state.constructorData.epackData,
      loading: (state) => state.constructorData.loading,
      isSaved: (state) => state.constructorData.isSaved,
      currentEpackage: (state) => state.constructorData.currentEpackage,
      availableFonts: (state) => state.constructorData.availableFonts,
      unsavedPopupShowStore: (state) => state.constructorData.unsavedPopupShow,
      unsavedEpackageID: (state) => state.constructorData.unsavedEpackageID,
      currentTemplate:(state) => state.constructorData.currentTemplate,
      currentLocale:(state) => state.constructorData.currentLocale,
      templatesList:(state) => state.constructorData.templatesList,
      activeSize:(state) => state.constructorData.activeSize,
      activeType:(state) => state.constructorData.activeType,
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      currentUserPermissionsArray: (state) => state.currentUserProfilePermissions,
      licensesList: (state) => state.constructorData.licensesList,
    }),

    componentsData() {
      return this.epackData.components;
    },
    localesList() {
      const computedLocales = [];
      Object.keys(this.epackData.components).forEach((localeName) => {
        computedLocales.push(localeName);
      });
      return computedLocales;
    },
    isAdminUser: function () {
      const userData = JSON.parse(localStorage.getItem("ttlUserInfo"));
      if (!userData.roles) {
        return false;
      } else {
        return userData.roles.includes("ROLE_ADMIN")
      }
    },
    previewPath() {
      if (this.currentEpackage === 'untitled') {
        return 'javascript:void(0);';
      }

      let pathToFile = 'iframe/index.html';
      if (this.currentTemplate === 'ozon_template' ) {
        return `${this.backendUrl}/api/epackage_template/${this.currentEpackage}?locale=${this.currentLocale}&template=${this.currentTemplate}`;
      } else if (this.currentTemplate === 'amazon_template') {
        return pathToFile = `${this.backendUrl}/uploads/${this.currentEpackage}/${this.currentLocale}/minisite/${this.currentTemplate}/content/json/index.json`;
      } else if (this.currentTemplate === 'mvideo_template' && this.currentLocale === 'ru') {
         return pathToFile = `${this.backendUrl}/api/epackage/preview?url=${this.backendUrl}/uploads/${this.currentEpackage}/${this.currentLocale}/minisite/${this.currentTemplate}/content/json/index.json`;
      }
      const path = `${this.backendUrl}/uploads/${this.currentEpackage}/${this.currentLocale}/minisite/${this.currentTemplate}/content/${pathToFile}`;
      return path;
    },
    chooselicensePopupShow: {
      get() {
        return this.licensesList.length ? true : false;
      },
      set(val) {
        if(val === false) {
          this.setLicensesList([]);
        }
      }
    }
  },

  methods: {
    ...mapActions("constructorData", [
      "loadEpack",
      "saveEpack",
      "uploadEpackToPanel",
      "setCurrentEpackId",
      "loadFonts",
      "setActiveComponent",
      "updateUsedFonts",
      "setCurrentTemplate",
      "setCurrentLocale",
      "changeSize",
      "syncComponents",
      "updateManifestData",
      "updateComponentsData",
      "getCategoriesList",
      "getUsersList",
      "setActiveComponent",
      "setActiveElement",
      "addLocale",
      "addTemplate",
      "deleteLocale",
      "deleteTemplate",
      "compressEpack",
      "closeEpack",
    ]),
    ...mapMutations("constructorData", [
      "setLicensesList",
    ]),
    updateToggle: function (inType) {
      this.inType = inType;
    },

    async leavePage() {
      await this.closeEpack(this.currentEpackage);
      this.$router.push("/panel/builder");
      localStorage.removeItem("epackData");
      localStorage.removeItem("epackageBackup");

    },
    historyClean() {
      localStorage.removeItem("epackData");
      this.epackHistoryStep = 0;
      this.epackHistory = this.epackHistory.slice(-1);
    },
    goToEpackage() {
      const epackageID = JSON.parse(localStorage.getItem("epackData")).epackID;
      this.$router.push("/panel/builder/" + epackageID, () => {
        this.setCurrentEpackId(this.$route.params.id);
        this.loadEpack();
        // this.loadFonts();
        this.setActiveComponent(null);
      });
    },
    cancelRestore() {
      localStorage.removeItem("epackData");
      this.loadEpack();
      // this.loadFonts();
      this.setActiveComponent(null);
    },
    historyUpdate() {
      if (this.epackHistoryStep !== 0) {
        this.epackHistory.splice(0, this.epackHistoryStep - 1);
        this.epackHistoryStep = 0;
      } else {
        if (history.length >= this.historyLimit) {
          this.epackHistory.splice(19, 1);
        }
        this.epackHistory.unshift(dcopy(this.epackData));
      }
    },
    hotkeysBind(e) {
      const evtobj = window.event ? event : e;
      if((evtobj.keyCode == 89 && evtobj.ctrlKey ) || (evtobj.metaKey && evtobj.keyCode == 90 && evtobj.shiftKey)) {
        e.preventDefault();
        this.historyRedo();
      } else if (evtobj.keyCode == 90 && (evtobj.ctrlKey || evtobj.metaKey)) {
        e.preventDefault();
        this.historyUndo();
      } else if (evtobj.keyCode == 83 && (evtobj.ctrlKey || evtobj.metaKey)) {
        e.preventDefault();
        this.saveEpack();
      } else if (evtobj.keyCode == 27) {
        e.preventDefault();
        this.deselectElement();
      }
    },
    historyChange() {
      this.availableHistoryUpdate = false;
      const historyBackup = dcopy(this.epackHistory);
      this.updateComponentsData(historyBackup[this.epackHistoryStep].components);
      this.updateManifestData(historyBackup[this.epackHistoryStep].manifest);
      this.$forceUpdate();
      setTimeout(() => {
        this.epackHistory = historyBackup;
        this.availableHistoryUpdate = true;
      }, 200);
    },
    historyUndo() {
      if (
        this.epackHistoryStep < this.historyLimit &&
        this.epackHistoryStep < this.epackHistory.length - 1
      ) {
        this.epackHistoryStep++;
        this.$forceUpdate();
        this.historyChange();
      }
    },
    historyRedo() {
      if (this.epackHistoryStep > 0) {
        this.epackHistoryStep--;
        this.$forceUpdate();
        this.historyChange();
      }
    },
    deselectElement() {
      if(this.activeElement !== null) {
        this.setActiveElement({componentIndex:this.activeComponent, elementIndex: null});
      } else if(this.activeComponent !== null) {
        this.setActiveComponent(null);
      }
    },
    checkUserPermission(permission) {
      const currentPermissionArray = this.currentUserPermissionsArray.filter(elem => elem.name === permission);
      return currentPermissionArray.length ? true : false;
    },
    epackCloseHendler() {
      if(this.isSaved) {
        this.leavePage()
      } else {
        this.showSavePopup = true;
      }
    }
  },
  watch: {
    selectedTemplate() {
      this.setCurrentTemplate(this.selectedTemplate);
    },
    selectedLocale() {
      this.setCurrentLocale(this.selectedLocale);
    },
    currentLocale() {
      this.selectedLocale = this.currentLocale;
    },
    currentTemplate() {
      this.selectedTemplate = this.currentTemplate;
    },
    epackData: {
      handler: function () {
        const epackageBackup = {
          id: this.epackData.manifest.packageID,
          data: this.epackData.components,
        };
        if (this.epackBackupChecked) {
          localStorage.setItem("epackageBackup", JSON.stringify(epackageBackup));
        }
        if(this.availableHistoryUpdate) {
          this.historyUpdate();
        }
      },
      deep: true,
    },
    componentsData: {
      handler() {

        let manifestTemplates = {};
        if(this.currentLocale !== null) {

          if(this.epackData.components.hasOwnProperty(this.currentLocale)) {
            let templates = this.epackData.components[this.currentLocale];
            Object.keys(templates).map(template => {
              if(templates[template].length) {
                if (template === "ozon_template") {
                  manifestTemplates["ozon_template"] = {
                    description: "",
                    contentTypes: ["json"],
                  };
                } else {
                  manifestTemplates[template] = {
                    description: "",
                    contentTypes: ["html", "json", "iframe"],
                  };
                }
              }
            });
          }
          const manifestData = dcopy(this.epackData.manifest);
          if( !manifestData.data.hasOwnProperty(this.currentLocale)) {
            manifestData.data[this.currentLocale] = {};
          }
          manifestData.data[this.currentLocale].minisite = manifestTemplates;
          this.updateManifestData(manifestData);
        }
      },
      deep: true,
    },
    unsavedPopupShowStore() {
      this.unsavedPopupShow = this.unsavedPopupShowStore;
    },
    license() {
      if(this.license !== '') {
        const licenseElem = this.licensesList.find(license => (license.name === this.license));
        this.licenseVal = licenseElem?.id;
      }
    }
  },
  created() {
    this.setCurrentEpackId(this.$route.params.id);
    this.loadEpack();
    this.loadFonts();
    this.setActiveComponent(null);
    // this.getCategoriesList();
    if(this.isAdminUser) {
      this.getUsersList();
    }
    this.selectedTemplate = this.currentTemplate;
    this.selectedLocale =  this.currentLocale;
    document.addEventListener("keydown", this.hotkeysBind);
  },
  destroyed() {
    document.removeEventListener("keydown", this.hotkeysBind);
    this.setCurrentTemplate(null);
    this.setCurrentLocale(null);
  },
};
</script>

<style lang="scss">
.popup--constructor {
  &__body {
    padding: 0 !important;
  }

  .popup--preview__box {
    padding: 0;
  }

  .header--small-padding {
    padding: 16px 20px;
    border-bottom: 1px solid #e3e9ed;
  }

  .popup--preview__box__header {
    justify-content: space-between;
  }

  .popup--preview__box__header,
  .popup--preview__box__rich {
    border-radius: 0;
  }

  .popup--preview__box__close {
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    color: #000000;
    font-weight: 550;

    svg {
      margin-left: 12px;
    }
  }

  .select-block__content {
    display: inline-flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #e3e9ed;
  }

  .select-block__content__item {
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;

    &--stroke {
      svg {
        display: block;

        path {
          stroke: #8d8ca8;
        }
      }

      &.active {
        svg {
          path {
            stroke: #000000;
          }
        }
      }
    }

    &--fill {
      svg {
        display: block;

        path {
          fill: #8d8ca8;
        }
      }

      &.active {
        svg {
          path {
            fill: #000000;
          }
        }
      }
    }

    &.active {
      background: #e3e9ed;
      cursor: default;
    }
  }
}

.popup--preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #eeeeee;
    padding: 10px;
    height: 100%;
    width: 100%;
    max-width: 100%;

    .select-block {
      margin-left: 24px;
      width: 40%;

      &__title {
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }

      &__content {
        font-size: 36px;

        i {
          margin-right: 16px;
          cursor: pointer;
          transition: 0.26s;
          color: #000000;

          &:last-child {
            margin-right: 0;
          }

          &:hover,
          &.active {
            color: #4598db;
          }

          &.active {
            cursor: default;
          }
        }
      }
    }

    &__close {
      border: 1px solid #e5e8ea;
      border-radius: 3px;
      width: 40px;
      height: 40px;
      margin-left: auto;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    &__header,
    &__rich {
      background-color: #ffffff;
      border-radius: 8px;
      display: flex;
      padding: 20px;
    }

    &__header {
      display: flex;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      position: relative;
    }

    &__rich {
      padding: 0 0 16px 0;
      // height: calc(100% - 124px);
      // height: calc(100% - 195px);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 1px solid #e5e8ea;

      .iframe {
        width: 100%;
        height: auto;
        margin: 16px auto 0;
        transition: 0.3s;
        border: 1px solid #e5e8ea;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }

    .popup--constructor__body {
      height: calc(100% - 195px);
    }

    .constructor-container {
      width: 100%;
    }

    .search-fields {
      border-top: 1px solid #d6dee2;
      padding-top: 32px;

      input {
        width: 100%;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .constructor-align-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .constructor-nav-button {
    margin: 0 2px;
    padding: 8px;

    svg {
      display: block;

      path {
        fill: #ffffff;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .history-steps {
    display: flex;
    padding-right: 8px;
  }
}

.constructor-overlay {
  .va-button {
    margin: 0.375rem 0.5rem;
  }
}

.input--new-template {
  border: 1px solid #d6dee2 !important;
  border-radius: 6px;
  padding: 5px 16px 0;
  margin-bottom: 0;
  display: inline-block;
  width: calc(100% - 200px);
}

.new-tempalte__text {
  font-size: 16px;
  display: inline-block;
}
</style>
