<template>
  <!-- <div> -->
  <!-- <div
      :class="activeType === 'mobile' ? 'ra-b6' : 'ra-b8'"
      :style="{ textAlign: params.textAlign }"
    >
      <span class="ra-d9 ra-e0">
        <span
          :class="'stream-text-' + params.fontSize"
          v-html="handleNewLine(params.content)"
        ></span>
      </span>
      <RemoveItem
        v-if="componentIndex === activeComponent"
        @click.native="removeComponent(componentIndex)"
      />
    </div> -->

  <div>
    <div
      class="title-with-description ra-b7"
      :class="[
        params.padding === 'type1' ? 'type1' : null,
        params.padding === 'type2' ? 'type2' : null,
        params.theme === 'default' ? 'default' : null,
        params.theme === 'primary' ? 'primary' : null,
        params.theme === 'secondary' ? 'secondary' : null,
        params.theme === 'tertiary' ? 'tertiary' : null,
        // activeType === 'mobile' ? 'ra-d5' : 'ra-d4',
      ]"
    >
      <span
        class="title"
        :class="[
          params.space === 's' ? 'space-s' : null,
          params.space === 'm' ? 'space-m' : null,
          params.titleColor === 'color1' ? 'color1' : null,
          params.titleColor === 'color2' ? 'color2' : null,
          params.titleColor === 'color3' ? 'color3' : null,
          params.titleColor === 'color4' ? 'color4' : null,
        ]"
        :style="{ textAlign: params.titleTextAlign }"
        ><span
          :class="'stream-text-' + params.titleFontSize"
          v-html="handleNewLine(params.titleContent)"
        ></span
      ></span>
      <span
        class="description"
        :class="[
          params.descriptionColor === 'color1' ? 'color1' : null,
          params.descriptionColor === 'color2' ? 'color2' : null,
          params.descriptionColor === 'color3' ? 'color3' : null,
          params.descriptionColor === 'color4' ? 'color4' : null,
        ]"
        :style="{ textAlign: params.descriptionTextAlign }"
        ><span
          :class="'stream-text-' + params.descriptionFontSize"
          v-html="handleNewLine(params.descriptionContent)"
        ></span
      ></span>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
  <!-- </div> -->
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";

export default {
  name: "OzonTitle",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement", "removeComponent"]),
  },
};
</script>

<style lang="scss" scoped>

.title-with-description {
  margin-top: 48px;
}
.color1 {
  color: #001a34;
}

.color2 {
  color: #3d5165;
}

.color3 {
  color: #808d9a;
}
.color4 {
  color: #ffffff;
}

.title {
  display: block;
}

.description {
  display: block;
}
.title-with-description {
  border-radius: 12px;
}

.type1 {
  padding: 0;
}

.type2 {
  padding: 40px;
}

.space-s {
  margin-bottom: 8px;
}

.space-m {
  margin-bottom: 16px;
}

.primary {
  background: #005bff;
}

.secondary {
  background: transparent;
  border: 2px solid #005bff;
}

.tertiary {
  background: #eff3f6;
}

.small-pc.tablet.mobile {
  .type2 {
    padding: 24px 16px;
  }
}
</style>