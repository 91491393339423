<template>
  <div class="constructor-cover-panel">
    <div
      class="row"
    >
      <div
        v-for="(param, index) in coversStructure"
        :key="index"
        class="xs12 md12 flex"
      >
        <div
          class="constructor-cover-panel__item"
        >
          <Textarea
            v-if="param.type === 'text'"
            :label="param.nameText"
            :startValue="param.defaultValue"
            v-model="param.defaultValue"
          />
          <UploadImg
            v-else-if="param.type === 'file'"
            v-model="param.defaultValue"
            :name="param.name"
            :index="index"
            :label="param.nameText"
            :recommendedWidth="param.recommendedWidth"
            :width="param.width"
            :height="param.height"
            :resetUrl="param.resetValue"
            @imgUpdate="imgUpdate"
          />
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dcopy from "deep-copy";

import Textarea from "./editPanels/Textarea/Textarea.vue";
import UploadImg from "./editPanels/UploadImg/UploadImg.vue";

export default {
  name: "CoverPanel",
  components: {
    Textarea,
    UploadImg,
  },
  data() {
    return {
      coversStructure: [],
    };
  },
  computed: {
    ...mapState({
      cover: (state) => state.constructorData.cover,
      coverData: (state) => state.constructorData.epackData.coverData,
    }),
    coverParams() {
      const coverParams = dcopy(this.cover.props.map(elem => ({
        ...elem,
        defaultValue: this.coverData.hasOwnProperty(elem.name) ? this.coverData[elem.name] : elem.defaultValue,
      })))
      return coverParams;
    }
  },
  methods: {
    ...mapActions('constructorData',['updateCoversData']),
    imgUpdate (response) {
      this.coversStructure[response.index].defaultValue = response.src;
    },
    updateCover (data) {
      const coverData = {};
      data.forEach((elem) => (
        coverData[elem.name] = elem.defaultValue
      ));

      this.updateCoversData(coverData);
    },
  },
  mounted() {
    this.coversStructure = dcopy(this.coverParams);
  },
  watch: {
    coversStructure: {
      handler() {
        // const coverData = dcopy(this.coversStructure);
        this.updateCover(this.coversStructure);
      },
      deep: true,
    }
  }
};
</script>

<style lang="scss">
.constructor-cover-panel {
  padding: 16px;

  &__item {
    padding: 0.75rem;
    // padding: 10px !important;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    // border: 1px solid #e5e8ea;
    border: none;
    border-radius: 6px;
    height: 40px;
    cursor: pointer;

    &--delete-button {
      color: #ffffff;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      background: #db4545;

      svg {
        margin-left: 4px;
        display: block;

        path {
          fill: #ffffff;
        }
      }
    }
  }
  .img-load__preview__file {
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .flex {
    padding: 0 !important;
  }

  .va-input {
    border: 1px solid rgb(241, 244, 248) !important;
    border-radius: 6px;
    padding: 21px 16px 5px !important;
    margin-bottom: 0;

    &__container {
      height: auto;

      &__label {
        left: 0;
        position: absolute;
        font-size: 12px;
        color: #8d9ca8 !important;
        font-weight: normal;
        text-transform: none;
      }
    }
  }

  .dropzone--white {
    padding: 1.5rem 0.5rem 0.5rem;

    .va-file-upload__field__button {
      background: #e3e9ed !important;
      color: #000000 !important;
      border: none;
    }

    .va-file-upload__field {
      padding: 0 0.5rem 1rem;
      text-align: center;
    }

    &.dropzone--vertical {
      .va-file-upload__field__text {
        padding-right: 0;
        padding-bottom: 10px;
      }
    }
  }
}

.input--color .va-input__container__content-wrapper input {
  height: 1.5em;
}
</style>