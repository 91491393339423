<template>
  <div
    :class="['spacer', params.size].join(' ')"
    :style="{
      background: params.background,
    }"
  >
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex);"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from '../../../ui/RemoveItem.vue';

export default {
  name: "EldSpacer",
  props: {
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement","removeComponent",]),
  },
};
</script>

<style lang="scss" scoped>
.spacer {
  &.large {
    padding-bottom: 80px;
  }

  &.medium {
    padding-bottom: 40px;
  }

  &.small {
    padding-bottom: 20px;
  }

  &.extra-small {
    padding-bottom: 10px;
  }

  &.small-pc.tablet.mobile {
    &.large {
      padding-bottom: 40px;
    }

    &.medium {
      padding-bottom: 24px;
    }

    &.small {
      padding-bottom: 16px;
    }

    &.extra-small {
      padding-bottom: 8px;
    }
  }
}
</style>
