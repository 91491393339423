<template>
  <div class="video-load">
    <div v-if="label" class="video-load-text">
      {{label}}
    </div>
    <div class="video-load-container">
      <div class="video-load-container__overlay" v-if="loading">
        <va-progress-bar
          indeterminate
          :style="{width: '100%',position: 'absolute',left: 0, bottom: '-12px',}"
        />
      </div>
      <div class="video-load__inner">
        <va-file-upload
          type="single"
          v-model="video"
          class="video-load__dropzone dropzone--white dropzone--vertical"
          :style="{background: '#ffffff', border: '2px dashed #D6DEE2'}"
          dropzone
        />
        <div v-if="value !== ''" class="video-load__preview">
          <div class="video-load__preview__file">
            <video :src="domain + value" playsinline muted controls></video>
          </div>
          <div
            v-if="!value.includes('placeholders')"
            class="video-load-reset-button"
            @click="resetUploadedFile(name)"
          >
            <RemoveItem class="video-load__button"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import APIConstructor from "../../../../services/API/APIConstructor";
import {showToastError} from '../../../../services/Helpers/HelperToast';
import RemoveItem from "../../ui/RemoveItem.vue";
export default {
  name: "UploadVideo",
  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    index: {
      type: Number,
    },
    value: {
      type: String,
    },
  },
  methods: {
    ...mapActions("constructorData",["resetUploadedFile"]),
  },
  components: {
    RemoveItem,
  },
  computed: {
    ...mapState({
      currentEpackage: (state) => state.constructorData.currentEpackage,
    }),
  },
  data() {
    return {
      loading: false,
      video: [],
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  watch: {
    video: {
      handler: function () {
        if (this.video.length) {
          let formData = new FormData();
          formData.append("file", this.video[this.video.length - 1]);
          this.loading = true;
          console.log(this.video[this.video.length - 1]);
          APIConstructor.APIPost(
            `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/load/video`,
            formData,
            (response) => {
              if(response.code === 200) {
                this.$emit("videoUpdate", {
                  index: this.index,
                  src: response.data,
                });
              } else if (response.code === 402) {
                showToastError(
                  response.message,
                  this.$toast,
                );
              } else {
                showToastError(
                  "Something wrong. <br/> Please try later",
                  this.$toast,
                );
              };
              this.loading = false;
            },
            (error) => {
              if (error.response && error.response.status === 402) {
                showToastError(
                  error.response.data.data.message,
                  this.$toast,
                );
              } else {
                showToastError(
                  "Something wrong. <br/> Please try later",
                  this.$toast,
                );
              }
              this.loading = false;
            },
          );
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.video-load-text {
  font-size: 14px;
  line-height: 1.29;
  margin-bottom: 12px;
}

.video-load-container {
  position: relative;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    align-items: center;
    z-index: 2;
    border-radius: 0.375rem;

    .va-progress-bar__buffer {
      background: transparent !important;
    }
  }

  .video-load {
    &__preview {
      padding: 8px 4px;
      display: flex;
      justify-content: space-between;

      &__file {
        height: 80px;
        width: 80%;

        video {
          width: auto;
          height: 100%;
          border-radius: 6px;
        }
      }
    }

    &__button {
      top: 0;
      right: 0;
      left: 0;
      position: relative;
      cursor: pointer;
    }
  }

  .va-file-upload--dropzone {
    background: none !important;
  }

  .va-file-upload-single-item {
    display: none;
  }

  .va-file-upload-list {
    display: none;
  }
}
</style>
