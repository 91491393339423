<template>
  <div :class="activeType === 'mobile' ? 'ra-b6' : 'ra-b7'">
    <div :class="['ra-c', activeType === 'mobile' ? 'ra-c0' : null]">
      <div class="ra-c1">
        <div :class="['ra-e7', activeType === 'mobile' ? 'ra-c2' : 'ra-c3']">
          <div>
            <img
              loading="lazy"
              :src="
                domain + (activeType === 'mobile' ? params.srcMob : params.src)
              "
              alt=""
              class="picture"
            />
          </div>
        </div>
      </div>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";

export default {
  name: "OzonPicture",
  props: {
    params: {
      type: Object,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement", "removeComponent"]),
  },
};
</script>

<style lang="scss" scoped>
.ra-b6 .picture,
.ra-b7 .picture {
  display: block;
  width: 100%;
  height: auto;
}

.ra-c .ra-c1 img {
  max-width: 100%;
  width: auto;
  margin: 0 auto;
}
</style>
