<template>
  <div class="img-load">
    <div v-if="label" class="img-load-text">
      {{ label }}
    </div>
    <div class="img-load-container">
      <div class="img-load-container__overlay" v-if="loading">
        <va-progress-bar
          indeterminate
          :style="{
            width: '100%',
            position: 'absolute',
            left: 0,
            bottom: '-12px',
          }"
        />
      </div>
      <div class="img-load__inner">
        <va-file-upload
          type="single"
          v-model="image"
          class="img-load__dropzone dropzone--white dropzone--vertical"
          :style="{ background: '#ffffff', border: '2px dashed #D6DEE2' }"
          dropzone
        />
        <div class="img-load__text">
          Quality (works only on aploading)
        </div>
        <va-slider
          value-visible
          v-model="imageQuality"
          label-value=""
          :min="0"
          :max="100"
        />
        <div class="img-load__preview" v-if="value !== ''">
          <div class="img-load__preview__file">
            <img :src="domain + value" alt="" />
          </div>
          <div class="img-load-actions" v-if="!value.includes('placeholders')">
            <div @click="resetLocalFile(name,resetUrl)" class="img-load-reset-button">
              <RemoveItem class="img-load__button" />
            </div>
             <!-- <div class="img-load__magic">
              <div
                class="img-load__magic__icon"
                @click="isMagicMenuOpened = true"
                title="Improve image"
              >
                <MagicStick />
              </div>
              <div v-if="isMagicMenuOpened && Object.keys(upgradeParams).length" class="img-load__magic__menu">
                <div class="img-load__magic__close">
                  <div
                    class="img-load__magic__close__inner"
                    @click="isMagicMenuOpened = false"
                    title="Close Magic menu"
                  >
                    <CloseImg />
                  </div>
                </div>
                <div
                  v-for="(panel, panelIndex) in panelsList"
                  :key="'panel-'+panelIndex"
                  class="img-load__magic__panel"
                >
                  <div class="img-load__magic__panel__title">{{panel.title}}</div>
                  <div class="img-load__magic__list">
                    <div
                      v-for="(element, elementIndex) in panel.elements"
                      :key="'panel-'+panelIndex+'preset'+elementIndex"
                      :class="['img-load__magic__menu__item', upgradeParams[panel.value] === element.value ? 'img-load__magic__menu__item--active' : null]"
                      @click="setImgUgradeParam(panel.value, element.value)"
                      :title="element.title"
                    >
                      <component
                        :is="element.icon"
                      />
                    </div>
                  </div>
                </div>
                <div class="img-load__magic__button">
                  <button 
                    class="img-load__magic__button__inner va-button va-button--default va-button--normal"
                    @click="upgradeImg"
                  >
                    Upgrade
                  </button>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import APIConstructor from "../../../../services/API/APIConstructor";
import { showToastError } from "../../../../services/Helpers/HelperToast";
import RemoveItem from "../../ui/RemoveItem.vue";
// import CenterImg from "../../../ui/icons/constructor/CenterImg.vue";
// import ClearBgImg from "../../../ui/icons/constructor/ClearBgImg.vue";
// import UpgradeImgQuality from "../../../ui/icons/constructor/UpgradeImgQuality.vue";
// import NotUpscaleImg from "../../../ui/icons/constructor/NotUpscaleImg.vue";
// import Number1 from "../../../ui/icons/constructor/Number1.vue";
// import Number2 from "../../../ui/icons/constructor/Number2.vue";
// import Number3 from "../../../ui/icons/constructor/Number3.vue";

// import UpscaleImg from "../../../ui/icons/constructor/UpscaleImg.vue";
// import MagicStick from "../../../ui/icons/constructor/MagicStick.vue";
// import CloseImg from "../../../ui/icons/constructor/CloseImg.vue";

export default {
  name: "UploadImg",
  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    index: {
      type: Number,
    },
    value: {
      type: String,
    },
    recommendedWidth: {
      type: Number,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    resetUrl: {
      type: String,
    }
  },
  components: {
    RemoveItem,
    // CenterImg,
    // UpgradeImgQuality,
    // NotUpscaleImg,
    // Number1,
    // Number2,
    // Number3,
    // ClearBgImg,
    // UpscaleImg,
    // MagicStick,
    // CloseImg,
  },
  computed: {
    ...mapState({
      currentEpackage: (state) => state.constructorData.currentEpackage,
      currentTemplate: (state) => state.constructorData.currentTemplate,
    }),
  },
  data() {
    return {
      loading: false,
      image: [],
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
      isMagicMenuOpened: false,
      upgradeParams: {},
      imageQuality: 100,
      panelsList: [
        {
          title: "Sizes",
          value: "width",
          elements: [
            {
              title: "Not Upscale Image",
              value: null,
              icon: "NotUpscaleImg",
            },
            {
              title: "Upscale Image",
              value: this.recommendedWidth,
              icon: "UpscaleImg",
            },
          ],
        },
        {
          title: "Presets",
          value: "preset",
          elements: [
            {
              title: "Soft",
              value: "smart_resize",
              icon: "Number1",
            },
            {
              title: "Smart",
              value: "smart_enhance",
              icon: "Number2",
            },
            {
              title: "Strong",
              value: "photo",
              icon: "Number3",
            },
          ]
        }
      ],
    };
  },
  methods: {
    ...mapActions("constructorData", ["resetUploadedFile"]),
    resetLocalFile(name,resetUrl) {
      if(resetUrl) {
        this.$emit("imgUpdate", {
          index: this.index,
          src: resetUrl,
        });
      } else {
        if (name !== "") {
          this.resetUploadedFile(name);
        } else {
          this.$emit("imgUpdate", {
            index: this.index,
            src: "",
          });
        }
      }
    },
    setImgUgradeParam(param, value) {
      this.upgradeParams[param] = value;
      this.$forceUpdate();
    },
    upgradeImg() {
      this.loading = true;
      APIConstructor.APIPost(
        `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/image/change`,
        {
          ...this.upgradeParams,
          epackage: this.currentEpackage,
          locale: "ru",
          template: this.currentTemplate,
          image: this.value,
        },
        (response) => {
          if (response.code === 200) {
            this.$emit("imgUpdate", {
              index: this.index,
              src: response.message,
            });
          } else if (response.code === 402) {
            showToastError(response.message, this.$toast);
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this.$toast
            );
          }
          this.loading = false;
        },
        (error) => {
          if (error.response.code === 402) {
            showToastError(error.response.message, this.$toast);
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this.$toast
            );
          }
          this.loading = false;
        }
      );
    },
  },
  watch: {
    image: {
      handler: function () {
        if (this.image.length) {
          let formData = new FormData();
          formData.append("file", this.image[this.image.length - 1]);
          if(this.imageQuality) {
            formData.append("quality", this.imageQuality);
          }
          if(this.width) {
            formData.append("width", this.width);
          }
          if(this.height) {
            formData.append("height", this.height);
          }
          this.loading = true;
          APIConstructor.APIPost(
            `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/load/img`,
            formData,
            (response) => {
              if (response.code === 200) {
                this.$emit("imgUpdate", {
                  index: this.index,
                  src: response.data,
                });
              } else if (response.code === 402) {
                showToastError(response.message, this.$toast);
              } else {
                showToastError(
                  "Something wrong. <br/> Please try later",
                  this.$toast
                );
              }
              this.loading = false;
            },
            (error) => {
              if (error.response.status === 402) {
                showToastError(error.response.data.data.message, this.$toast);
              } else {
                showToastError(
                  "Something wrong. <br/> Please try later",
                  this.$toast
                );
              }
              this.loading = false;
            }
          );
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.panelsList.forEach(elem => {
      this.upgradeParams[elem.value] = elem.elements[0].value;
    });
  },
};
</script>

<style lang="scss">
.img-load-text {
  font-size: 14px;
  line-height: 1.29;
  margin-bottom: 12px;
}

.img-load-container {
  position: relative;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    align-items: center;
    z-index: 2;
    border-radius: 0.375rem;

    .va-progress-bar__buffer {
      background: transparent !important;
    }
  }

  .img-load {
    &__text {
      font-size: 14px;
      line-height: 1.29;
      margin-top: 12px;
      margin-bottom: 8px;
    }

    &__preview {
      padding: 8px 4px;
      display: flex;
      justify-content: space-between;

      &__file {
        height: 80px;
        width: 80%;
        overflow: hidden;
        border-radius: 6px;

        img {
          width: auto;
          height: 100%;
          border-radius: 6px;
        }
      }
    }

    &__button {
      top: 0;
      right: 0;
      left: 0;
      position: relative;
      cursor: pointer;
    }
    &-actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__magic {
      position: relative;

      &__close {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 4px;
        top: 4px;

        &__inner {
          // padding: 8px;
          cursor: pointer;

          &:hover {
            background: #e3e9ed;
            border-radius: 4px;
          }

          svg {
            display: block;
          }
        }
      }

      &__icon {
        border-radius: 4px;
        padding: 8px;
        background: #e3e9ed;
        cursor: pointer;

        svg {
          display: block;
        }
      }

      &__list {
        display: inline-flex;
        border: 1px solid #e3e9ed;
        padding: 4px;
        border-radius: 4px;
      }

      &__menu {
        position: absolute;
        right: -4px;
        bottom: -4px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e3e9ed;
        // padding: 4px;

        &__item {
          padding: 8px;
          cursor: pointer;
          margin-right: 4px;

          &--active,
          &:hover {
            background: #e3e9ed;
            border-radius: 4px;
          }

          &:last-child {
            margin-right: 0;
          }

          svg {
            display: block;
          }
        }
      }

      &__panel {
        border-bottom: 1px solid #e3e9ed;
        padding: 16px 8px;

        &__title {
          font-size: 14px;
          line-height: 1.29;
          margin-bottom: 8px;
        }
      }

      &__button {
        padding: 16px 8px;

        &__inner {
          margin: 0;
          width: 100%;
        }
      }
    }
  }

  .va-file-upload--dropzone {
    background: none !important;
    padding: 24px 8px;

    .va-file-upload__field {
      padding: 0;
    }

    .va-file-upload__field__button {
      padding: 12px;
      width: 100%;
    }
  }

  .va-file-upload-single-item {
    display: none;
  }

  .va-file-upload-list {
    display: none;
  }
}
</style>
