var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title-with-description ra-b7",class:[
      _vm.params.padding === 'type1' ? 'type1' : null,
      _vm.params.padding === 'type2' ? 'type2' : null,
      _vm.params.theme === 'default' ? 'default' : null,
      _vm.params.theme === 'primary' ? 'primary' : null,
      _vm.params.theme === 'secondary' ? 'secondary' : null,
      _vm.params.theme === 'tertiary' ? 'tertiary' : null,
      // activeType === 'mobile' ? 'ra-d5' : 'ra-d4',
    ]},[_c('span',{staticClass:"title",class:[
        _vm.params.space === 's' ? 'space-s' : null,
        _vm.params.space === 'm' ? 'space-m' : null,
        _vm.params.titleColor === 'color1' ? 'color1' : null,
        _vm.params.titleColor === 'color2' ? 'color2' : null,
        _vm.params.titleColor === 'color3' ? 'color3' : null,
        _vm.params.titleColor === 'color4' ? 'color4' : null,
      ],style:({ textAlign: _vm.params.titleTextAlign })},[_c('span',{class:'stream-text-' + _vm.params.titleFontSize,domProps:{"innerHTML":_vm._s(_vm.handleNewLine(_vm.params.titleContent))}})]),_c('span',{staticClass:"description",class:[
        _vm.params.descriptionColor === 'color1' ? 'color1' : null,
        _vm.params.descriptionColor === 'color2' ? 'color2' : null,
        _vm.params.descriptionColor === 'color3' ? 'color3' : null,
        _vm.params.descriptionColor === 'color4' ? 'color4' : null,
      ],style:({ textAlign: _vm.params.descriptionTextAlign })},[_c('span',{class:'stream-text-' + _vm.params.descriptionFontSize,domProps:{"innerHTML":_vm._s(_vm.handleNewLine(_vm.params.descriptionContent))}})])]),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }