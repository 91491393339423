<template>
  <div class="row">
    <div class="constructor__panel__elements">
      <div class="constructor-panel__title">Edit element - {{activeElement + 1}}</div>
      <div
        v-for="(param, index) in elementProps"
        :key="index"
        class="flex xs12 md12"
      >
        <div
          class="constructor-editor-panel__item"
        >
          <Textarea
            :label="param.nameText"
            v-if="param.type === 'text'"
            :startValue="param.defaultValue"
            v-model="param.defaultValue"
          />
          <!-- <va-input
          :label="param.name"
          v-else-if="param.type === 'color'"
          class="input--border input--color"
          type="color"
          v-model="param.defaultValue"
        /> -->
          <TextArray 
            v-else-if="param.type === 'textArray'"
            :label="param.nameText"
            :colsQn="colsQn"
            :currentValue="param.defaultValue"
            :index="index"
            :activeElement="activeElement"
            @textArrayUpdate="textArrayUpdate"
          />
          <ImgArray
            v-else-if="param.type === 'imgArray'"
            :label="param.nameText"
            :colsQn="colsQn"
            :currentValue="param.defaultValue"
            :index="index"
            :name="param.name"
            :activeElement="activeElement"
            @imgArrayUpdate="imgArrayUpdate"
          />
          <ColorArray
            v-else-if="param.type === 'colorArray'"
            :label="param.nameText"
            :colsQn="colsQn"
            :currentValue="param.defaultValue"
            :index="index"
            :name="param.name"
            :activeElement="activeElement"
            @colorArrayUpdate="colorArrayUpdate"
          />
          <ColorPanel
            v-else-if="param.type === 'color'"
            :label="param.nameText"
            :startValue="param.defaultValue"
            v-model="param.defaultValue"
          />
          <FontSizePanel
            v-else-if="param.type === 'fontSize'"
            :name="param.name"
            :options="param.values"
            :label="param.nameText"
            :currentValue="''+param.defaultValue"
            :index="index"
            @fontSizeUpdate="fontSizeUpdate"
          />
          <TextAlignPanel
            v-else-if="param.type === 'textAlign'"
            :name="param.name"
            :options="param.values"
            :label="param.nameText"
            :currentValue="param.defaultValue"
            :index="index"
            @textAlignUpdate="textAlignUpdate"
          />
          <UploadImg
            v-else-if="param.type === 'file'"
            v-model="param.defaultValue"
            :index="index"
            :label="param.nameText"
            :name="param.name"
            :recommendedWidth="param.recommendedWidth"
            @imgUpdate="imgUpdate"
          />
          <UploadVideo
            v-else-if="param.type === 'video'"
            v-model="param.defaultValue"
            :index="index"
            :label="param.nameText"
            :name="param.name"
            @videoUpdate="videoUpdate"
          />
          <va-input
            v-else-if="param.type === 'number'"
            type="number"
            :label="param.nameText"
            :name="param.name"
            v-model="param.defaultValue"
            min="1"
          />
          <!-- <va-select
          :label="param.name"
          v-else-if="param.type === 'select' && param.name === 'fontFamily'"
          :name="param.name"
          :options="param.values"
          v-model="param.defaultValue"
          clearable="false"
          @input="checkFieldName(param.name)"
        /> -->
          <va-select
            :label="param.nameText"
            v-else-if="param.type === 'select'"
            :name="param.name"
            :options="param.values"
            v-model="param.defaultValue"
            clearable="false"
            noClear
          />
        </div>
      </div>
      <div class="constructor-panel__controls">
        <button
          v-if="elementsList.length > 1 && elementsList.length > minCount"
          class="constructor-editor-panel__button constructor-editor-panel__button--delete-button"
          @click="removeElement({componentIndex:activeComponent,elementIndex:activeElement})"
        >
          <Trash />
        </button>
        <button
          v-if="elementsList.length < maxCount"
          class="constructor-editor-panel__button constructor-editor-panel__button--add-button"
          @click="addElement"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dcopy from "deep-copy";
import { mapActions, mapState } from "vuex";
import Trash from "../ui/icons/constructor/Trash.vue";

import ColorPanel from "./editPanels/ColorPanel/ColorPanel.vue";
import TextAlignPanel from "./editPanels/TextAlignPanel/TextAlignPanel.vue";
import FontSizePanel from "./editPanels/FontSizePanel/FontSizePanel.vue";
import Textarea from "./editPanels/Textarea/Textarea.vue";
import UploadImg from "./editPanels/UploadImg/UploadImg.vue";
import UploadVideo from "./editPanels/UploadVideo/UploadVideo.vue";
import TextArray from "./editPanels/TextArray/TextArray.vue";
import ImgArray from "./editPanels/ImgArray/ImgArray.vue";
import ColorArray from "./editPanels/ColorArray/ColorArray.vue";




export default {
  name: "InnerPanel",
  components: {
    UploadImg,
    UploadVideo,
    Textarea,
    TextArray,
    ImgArray,
    ColorArray,
    ColorPanel,
    FontSizePanel,
    TextAlignPanel,
    Trash,
  },
  data() {
    return {
      elementProps: [],
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      sectionsParams: (state) => state.constructorData.sectionsParams,
      localComponents: (state) => state.constructorData.epackData.components,
      availableFonts: (state) => state.constructorData.availableFonts,
      currentTemplate: (state) => state.constructorData.currentTemplate,
      currentLocale: (state) => state.constructorData.currentLocale,

    }),
    colsQn() {
      return this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].props.columns ? +this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].props.columns - 1 : null
    },
    elementsList() {
      return this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].props.innerElements;
    },
    innerElementsProp() {
      if (this.activeComponent !== null) {
        const activeComponentName = this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].component;
        const innerElementsProp = (this.sectionsParams[activeComponentName].props).find(elem => elem.name === "innerElements");
        if (!innerElementsProp) {
          return {};
        }
        return innerElementsProp;
      }
      return {};
    },
    minCount() {
      return this.innerElementsProp.minCount ? this.innerElementsProp.minCount : 0;
    },
    maxCount() {
      return this.innerElementsProp.maxCount ? this.innerElementsProp.maxCount : Infinity;
    },
  },
  methods: {
    ...mapActions("constructorData", ["updateComponentsData", "addElement", "removeElement"]),
    getElementProps() {
      if (this.activeElement !== null) {
        const activeComponentName = this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].component;
        const activeComponentStructure = this.sectionsParams[activeComponentName].props;
        let elementsList = [];
        activeComponentStructure.map((prop) => {
          if (prop.name === "innerElements") {
            elementsList = dcopy(prop.defaultValue);
          }
        });
        if(this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].props.innerElements[this.activeElement] && elementsList[0].props) {
          const activeElementStructure = elementsList[0].props;
          const activeElementValue = this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].props.innerElements[this.activeElement].props;
          activeElementStructure.map((prop) => {
            prop.defaultValue = activeElementValue[prop.name];
          });
          return activeElementStructure;
        }
      }
    },
    imgUpdate: function (response) {
      this.elementProps[response.index].defaultValue = response.src;
    },
    videoUpdate: function (response) {
      this.elementProps[response.index].defaultValue = response.src;
    },
    fontSizeUpdate: function (response) {
      this.elementProps[response.index].defaultValue = response.value;
    },
    textAlignUpdate: function (response) {
      this.elementProps[response.index].defaultValue = response.value;
    },
    textArrayUpdate: function (response) {
      this.elementProps[response.index].defaultValue = dcopy(response.value);
    },
    imgArrayUpdate: function (response) {
      this.elementProps[response.index].defaultValue = dcopy(response.value);
    },
    colorArrayUpdate: function (response) {
      this.elementProps[response.index].defaultValue = dcopy(response.value);
    },
  },
  watch: {
    activeComponent() {
      this.elementProps = this.getElementProps();
    },
    localComponents: {
      handler: function () {
        this.elementProps = this.getElementProps();

      },
      deep: true,
    },
    elementProps: {
      handler: function () {
        const elementForUpdate = {};
        this.elementProps.map((prop) => {
          elementForUpdate[prop.name] = prop.defaultValue;
        });
        const newComponents = dcopy(this.localComponents);
        newComponents[this.currentLocale][this.currentTemplate][this.activeComponent].props.innerElements[this.activeElement].props = elementForUpdate;
        this.updateComponentsData(newComponents);
      },
      deep: true,
    },
    activeElement() {
      this.elementProps = this.getElementProps();
      this.$forceUpdate();

    },
  },
  mounted() {
    this.elementProps = this.getElementProps();
  },
};
</script>

<style lang="scss" scoped>
.constructor__panel__elements {
  padding: 16px 0;
  border: 1px solid #e5e8ea;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
}

.constructor-panel__title {
  padding: 0 16px;
}

.constructor-panel__controls {
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  width: 100%;
}

.constructor-editor-panel__button {
  width: calc((100% - 8px));
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.constructor-editor-panel__button--add-button {
  color: #ffffff;
  background: #4ae387;
}
</style>
