var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stream-bubbles"},[_vm._l((_vm.params.innerElements),function(bubble,elementIndex){return _c('input',{key:elementIndex,staticClass:"stream-bubbles-dot-indicator",attrs:{"type":"radio","name":'stream-bubbles-' + _vm.componentIndex,"id":'stream-bubbles-' + _vm.componentIndex + '-dot-' + elementIndex},domProps:{"checked":elementIndex === 0}})}),_c('input',{staticClass:"stream-bubbles-dot-indicator stream-bubbles-close",attrs:{"type":"radio","name":'stream-bubbles-' + _vm.componentIndex,"id":'stream-bubbles-' + _vm.componentIndex + '-dot-close'}}),_c('div',{staticClass:"stream-bubbles-img"},[_c('img',{attrs:{"src":_vm.domain + _vm.params.src,"alt":""}}),_c('div',{staticClass:"stream-bubbles-dots"},_vm._l((_vm.params.innerElements),function(bubble,elementIndex){return _c('label',{key:elementIndex,class:'stream-bubbles-dot' + ((_vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex) ? ' stream-bubbles-dot--active' : ''),style:({
          left: bubble.props.left + '%',
          top: bubble.props.top + '%',
          background:_vm.params.bubbleBackground,
          color: _vm.params.bubbleColor,
          '--activebg': _vm.params.bubbleActiveBg,
          '--activecolor': _vm.params.bubbleActiveColor,
        }),attrs:{"for":'stream-bubbles-' + _vm.componentIndex + '-dot-' + elementIndex},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setActiveElement({ componentIndex: _vm.componentIndex, elementIndex })}}},[(_vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex && _vm.params.innerElements.length > 1)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeElement({ componentIndex: _vm.componentIndex, elementIndex })}}}):_vm._e()],1)}),0),_c('div',{staticClass:"stream-bubbles-items"},_vm._l((_vm.params.innerElements),function(bubble,elementIndex){return _c('div',{key:elementIndex,class:['stream-bubbles-item', 'stream-'+bubble.props.bubbleContentPosition ,(_vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex) ? 'stream-bubbles-item--active' : ''],style:({background: _vm.params.background}),on:{"click":function($event){$event.stopPropagation();return _vm.setActiveElement({ componentIndex: _vm.componentIndex, elementIndex })}}},[_c('div',{class:[
            'stream-bubbles-title',
            'stream-text-' + _vm.params.titleFontSize,
          ],style:({
            textAlign: bubble.props.textAlign,
            '--textalignmobile': bubble.props.textAlignMobile,
            color: _vm.params.titleColor,
            fontFamily: _vm.params.titleFontFamily,
            fontWeight: _vm.params.titleFontWeight,
          }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(bubble.props.titleContent))}}),_c('div',{class:[
            'stream-bubbles-description',
            'stream-text-' + _vm.params.descriptionFontSize,
          ],style:({
            textAlign: bubble.props.textAlign,
            '--textalignmobile': bubble.props.textAlignMobile,
            color: _vm.params.descriptionColor,
            fontFamily: _vm.params.descriptionFontFamily,
            fontWeight: _vm.params.descriptionFontWeight,
          }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(bubble.props.descriptionContent))}}),_c('label',{staticClass:"stream-bubbles-item-close",style:({
            '--activecolor': _vm.params.closeBubbleColor,
            '--activebg': _vm.params.closeBubbleBackground,
          }),attrs:{"for":'stream-bubbles-' + _vm.componentIndex + '-dot-close'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setActiveElement({ componentIndex: _vm.componentIndex, elementIndex:null })}}})])}),0)]),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }