<template>
  <div class="stream-standard-text">
    <div class="stream-standatd-text-headline">{{ params.headlineText }}</div>
    <div
      class="stream-standatd-text-body"
      v-html="handleNewLine(params.bodyText)"
    ></div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "StandardTextModule",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.stream-standard-text {
  max-width: 970px;
  margin: auto;
  padding: 40px 0;
  .stream-standatd-text-headline {
    font-size: 28px;
    line-height: 36px;
    padding-bottom: 10px;
    font-weight: bold;
    font-family: "Amazon Ember", Arial, sans-serif;
  }

  .stream-standatd-text-body {
    font-family: "Amazon Ember", Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;

    br {
      content: "";
      display: block;
      margin-bottom: 14px;
    }
  }
}
.small-pc.tablet.mobile {
  .stream-standard-text {
    padding: 20px 0;

    .stream-standatd-text-headline {
      font-size: 24px;
      line-height: 1.3;
    }
  }
}
</style>