<template>
  <div class="stream-accordion">
    <input
      class="stream-accordion-indicator"
      type="checkbox"
      :name="'stream-accordion-' + componentIndex"
      :id="'stream-accordion-' + componentIndex"
    />
    <div class="stream-accordion-image" :style="{ background: params.background }">
      <div v-if="params.youtubeID" class="stream-accordion-youtube__video">
        <iframe :src="`https://www.youtube.com/embed/${params.youtubeID}?enablejsapi=1&rel=0&autoplay=0`" frameborder="0" :style="{borderRadius:params.borderRadius+'px'}"></iframe>
      </div>
      <div v-else-if="params.videoSrc">
          <video :src="domain + params.videoSrc" :controls="params.controls == 'true' ? true : false" playsinline muted :loop="params.repeat == 'true' ? true : false" :autoplay="params.autoplay == 'true' ? true : false" :style="{borderRadius:params.borderRadius+'px'}"></video>
      </div>
      <img v-else :src="domain + params.src" alt="" :style="{borderRadius:params.borderRadius+'px'}"/>
    </div>
    <div :class="['stream-accordion-content','stream-'+params.spacer+'-spacer']">
      <div
        v-for="(item, elementIndex) in params.innerElements"
        :key="elementIndex"
        :class="[
          'stream-accordion-content-item',
          'stream-' + item.props.imgPosition,
          'stream-' + item.props.imgPositionMobile + '-mobile',
          activeElement === elementIndex && activeComponent === componentIndex
            ? 'stream-accordion--active'
            : '',
        ]"
        :style="{ background: item.props.background }"
        @click.stop="setActiveElement({ componentIndex, elementIndex })"
      > 
        <div class="stream-accordion-content-item-inner">
          <div class="stream-accordion-content-img">
            <div v-if="item.props.youtubeID" class="stream-accordion-content-youtube__video">
              <iframe :src="`https://www.youtube.com/embed/${item.props.youtubeID}?enablejsapi=1&rel=0&autoplay=0`" frameborder="0" :style="{borderRadius:params.borderRadius+'px'}"></iframe>
            </div>
            <div v-else-if="item.props.videoSrc">
                <video :src="domain + item.props.videoSrc" :controls="item.props.controls == 'true' ? true : false" playsinline muted :loop="item.props.repeat == 'true' ? true : false" :autoplay="item.props.autoplay == 'true' ? true : false" :style="{borderRadius:params.borderRadius+'px'}"></video>
            </div>
            <img v-else :src="domain + item.props.src" alt=""  :style="{borderRadius:params.borderRadius+'px'}"/>
          </div>
          <div class="stream-accordion-content-content">
            <div
              :class="[
                'stream-accordion-content-title',
                'stream-text-' + item.props.titleFontSize,
              ]"
              :style="{
                textAlign: item.props.textAlign,
                '--textalignmobile': item.props.textAlignMobile,
                color: item.props.titleColor,
                fontFamily: params.titleFontFamily,
                fontWeight: params.titleFontWeight,
              }"
              v-html="handleNewLine(item.props.titleContent)"
            ></div>
            <div
              :class="[
                'stream-accordion-content-description',
                'stream-text-' + item.props.descriptionFontSize,
              ]"
              :style="{
                textAlign: item.props.textAlign,
                '--textalignmobile': item.props.textAlignMobile,
                color: item.props.descriptionColor,
                fontFamily: params.descriptionFontFamily,
                fontWeight: params.descriptionFontWeight,
              }"
              v-html="handleNewLine(item.props.descriptionContent)"
            ></div>
          </div>
        </div>
        <div
          v-if="item.props.disclaimerContent.length" 
          class="stream-accordion-content-item-disclaimer"
          :class="'stream-text-' + item.props.disclaimerFontSize"
          :style="{
                textAlign: item.props.disclaimerTextAlign,
                color: item.props.disclaimerColor,
                fontFamily: params.descriptionFontFamily,
                fontWeight: params.descriptionFontWeight,
              }"
          v-html="handleNewLine(item.props.disclaimerContent)">
        </div>
        <RemoveItem
          v-if="
            activeElement === elementIndex &&
            activeComponent === componentIndex &&
            params.innerElements.length > 1
          "
          @click.native="removeElement({ componentIndex, elementIndex })"
        />
      </div>
    </div>
    <label
      class="stream-accordion-button"
      :class="params.customCloseIcon ? 'stream-accordion-button-no-rotate' : null"
      :for="'stream-accordion-' + componentIndex"
    >
      <div v-if="params.customIcon">
        <div v-if="params.customCloseIcon">
          <div class="stream-custom-button-open" v-html="params.customIcon"></div>
          <div class="stream-custom-button-close" v-html="params.customCloseIcon"></div>
        </div>
        <div v-else class="stream-custom-button" v-html="params.customIcon"></div>
      </div>
      <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="-20 -20 208 208" xml:space="preserve" class="injected-svg">
        <circle cx="84" cy="84" r="73.5" opacity="0.75" style="opacity: 0.75; fill: #000000;"></circle>
        <circle class="open-close-solid-stroke" cx="84" cy="84" r="61" opacity="1" style="opacity: 1; fill: none; stroke: #ffffff; stroke-width: 3; stroke-miterlimit: 10;"></circle>
        <g transform="matrix(1,0,0,1,0,0)" style="fill: #ffffff;">
          <rect x="72" y="82" width="24" height="4"></rect>
          <rect x="82" y="72" width="4" height="24"></rect>
        </g>
      </svg>
    </label>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "Accordion",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.stream-accordion--active {
  box-shadow: 0 0 1px 1px #f500ed;
  background: var(--activebg) !important;
  color: var(--activecolor) !important;
}

.stream-accordion {
  &-indicator {
    display: none;
  }

  &-image {
    position: relative;

    img {
      display: block;
      margin: 0 auto;
      height: auto;
      max-width: 100%;
    }

    video {
      display: block;
      margin: 0 auto;
      height: auto;
      max-width: 100%;
    }

    .stream-accordion-youtube__video {
      position: relative;
      padding-bottom: 54%;
      overflow: hidden;
      width: 100%;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;
      }

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &-button {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    translate: 0.3s;
  }
  &-content {
    display: none;

    &.stream-no-spacer {
      .stream-accordion-content-item {
        padding-bottom: 0;
      }
    }

    &.stream-small-spacer {
      .stream-accordion-content-item {
        padding-bottom: 10px;
      }
    }

    &.stream-medium-spacer {
      .stream-accordion-content-item {
        padding-bottom: 20px;
      }
    }

    &.stream-large-spacer {
      .stream-accordion-content-item {
        padding-bottom: 40px;
      }
    }

    &-img {
      img {
        display: block;
        margin: 0 auto;
        height: auto;
        max-width: 100%;
      }

      video {
        display: block;
        margin: 0 auto;
        height: auto;
        max-width: 100%;
      }

      .stream-accordion-content-youtube__video {
        position: relative;
        padding-bottom: 54%;
        overflow: hidden;
        width: 100%;

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          z-index: 1;
        }

        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .stream-accordion-content-item {
      position: relative;
      &:last-child {
        padding-bottom: 0;
      }

      &.stream-right,
      &.stream-left {
        .stream-accordion-content-item-inner {
          display: flex;
          .stream-accordion-content-img,
          .stream-accordion-content-content {
            width: 50%;
          }

          .stream-accordion-content-content {
            justify-content: center;
            display: flex;
            flex-direction: column;
            padding: 0 40px;
          }
        }
      }

      &.stream-right {
        .stream-accordion-content-item-inner {
          .stream-accordion-content-img {
            order: 2;
          }
        }
      }

      &.stream-top {
        .stream-accordion-content-item-inner {
          .stream-accordion-content-content {
            padding: 20px 40px;
          }
        }
      }

      &.stream-bottom {
        .stream-accordion-content-item-inner {
          display: flex;
          flex-direction: column;
          .stream-accordion-content-img {
            order: 2;
          }
          .stream-accordion-content-content {
            padding: 20px 40px;
          }
        }
      }
    }
  }

  .stream-accordion-indicator:checked ~ .stream-accordion-content {
    display: block;
  }

  .stream-custom-button-close {
    display: none;
  }

  .stream-accordion-indicator:checked ~ .stream-accordion-button {
    position: relative;
    left: 0;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    display: block;
    margin: 0 auto;

    &.stream-accordion-button-no-rotate {
      transform: none;
    }

    .stream-custom-button-open {
      display: none;
    }

    .stream-custom-button-close {
      display: block;
    }
  }

  .stream-accordion-content-item-disclaimer {
    padding: 10px 40px;
  }
}

.small-pc.tablet.mobile {
  .stream-accordion {
    .stream-accordion-content-item {
      &.stream-top-mobile {
        .stream-accordion-content-item-inner {
          display: block;

          .stream-accordion-content-img,
          .stream-accordion-content-content {
            width: 100%;
          }
          .stream-accordion-content-content {
            padding: 20px;
          }
        }
      }

      &.stream-right-mobile,
      &.stream-left-mobile {
        .stream-accordion-content-item-inner {
          display: flex;
          flex-direction: row;
          .stream-accordion-content-img,
          .stream-accordion-content-content {
            width: 50%;
          }

          .stream-accordion-content-content {
            padding: 0 20px;
          }
        }
      }

      &.stream-right-mobile {
        .stream-accordion-content-item-inner {
          .stream-accordion-content-img {
            order: 2;
          }
        }
      }

      &.stream-bottom-mobile {
        .stream-accordion-content-item-inner {
          display: flex;
          flex-direction: column;
          .stream-accordion-content-img,
          .stream-accordion-content-content {
            width: 100%;
          }

          .stream-accordion-content-content {
            padding: 20px;
          }

          .stream-accordion-content-img {
            order: 2;
          }
        }
      }
    }

    .stream-accordion-title,
    .stream-accordion-description {
      text-align: var(--textalignmobile) !important;
    }
  }
}
</style>

<style lang="scss">
.stream-custom-button-open,
.stream-custom-button-close,
.stream-custom-button {
  svg {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
}
</style>