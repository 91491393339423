<template>
  <div
    v-if="localComponents[currentLocale] && localComponents[currentLocale][currentTemplate] && localComponents[currentLocale][currentTemplate].length"
    class="constructor-layers"
  >
    <draggable
      v-model="localComponents[currentLocale][currentTemplate]"
      @end="changeActiveComponent($event)"
    >
      <div
        v-for="(component, componentIndex) in localComponents[currentLocale][currentTemplate]"
        :key="componentIndex"
        :class="'constructor-layers__item' + (activeComponent === componentIndex ? ' constructor-layers__item--active' : '')"
      >
        <div class="constructor-layers__item__main"
          @click="setActiveElement({componentIndex, elementIndex:null})"
        >
          <div class="constructor-layers__item__text" :title="component.component">
            {{ component.component }}
          </div>
          <div
            class="constructor-layers__item__copy"
            @click="copyComponent(componentIndex)"
          >
            <Copy />
          </div>
          <div
            class="constructor-layers__item__delete"
            @click="removeComponent(componentIndex)"
          >
            <Trash />
          </div>
        </div>
        <div
          v-if="component.props.innerElements"
          class="constructor-layers__elements"
        >
          <draggable
            v-model="component.props.innerElements"
            @end="changeActiveElement($event,componentIndex)"
          >
            <div
              v-for="(element, elementIndex) in component.props.innerElements"
              :key="elementIndex"
              :class="'constructor-layers__elements__item' + (activeElement === elementIndex && activeComponent === componentIndex ? ' constructor-layers__elements__item--active' : '')"
              @click="setActiveElement({componentIndex, elementIndex})"
            >
              <div class="constructor-layers__elements__item__text">
                {{ element.component }}
              </div>
              <div
                v-if="component.props.innerElements.length > 1 && currentTemplate !== 'amazon_template'"
                class="constructor-layers__elements__item__delete"
                @click="removeElement({componentIndex, elementIndex})"
              >
                <Trash />
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dcopy from "deep-copy";
import draggable from "vuedraggable";

import Trash from "../ui/icons/constructor/Trash";
import Copy from "../ui/icons/constructor/Copy";

export default {
  name: "LayersPanel",
  components: {
    draggable,
    Trash,
    Copy,
  },

  data() {
    return {
      localComponents: [],
    };
  },
  computed: {
    ...mapState({
      components: (state) => state.constructorData.epackData.components,
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      currentTemplate:(state) => state.constructorData.currentTemplate,
      currentLocale:(state) => state.constructorData.currentLocale,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveComponent", "setActiveElement", "updateComponentsData", "removeComponent", "copyComponent", "removeElement"]),

    changeActiveComponent: function (event) {
      const newIndex = event.newIndex;
      const oldIndex = event.oldIndex;
      let newActiveComponent = 0;
      if (oldIndex < this.activeComponent && newIndex < this.activeComponent) {
        newActiveComponent = this.activeComponent;
      } else if (oldIndex > this.activeComponent && newIndex > this.activeComponent) {
        newActiveComponent = this.activeComponent;
      } else if (oldIndex > this.activeComponent && newIndex < this.activeComponent) {
        newActiveComponent = this.activeComponent + 1;
      } else if (oldIndex < this.activeComponent && newIndex >= this.activeComponent) {
        newActiveComponent = this.activeComponent - 1;
      } else if (oldIndex === this.activeComponent) {
        newActiveComponent = newIndex;
      }
      this.setActiveComponent(newActiveComponent);
    },
    changeActiveElement: function (event,componentIndex) {

      if(componentIndex === this.activeComponent) {
        const newIndex = event.newIndex;
        const oldIndex = event.oldIndex;
        let newActiveElement = 0;
        if (oldIndex < this.activeElement && newIndex < this.activeElement) {
          newActiveElement = this.activeElement;
        } else if (oldIndex > this.activeElement && newIndex > this.activeElement) {
          newActiveElement = this.activeElement;
        } else if (oldIndex > this.activeElement && newIndex <= this.activeElement) {
          newActiveElement = this.activeElement + 1;
        } else if (oldIndex < this.activeElement && newIndex >= this.activeElement) {
          newActiveElement = this.activeElement - 1;
        } else if (oldIndex === this.activeElement) {
          newActiveElement = newIndex;
        }
        this.setActiveElement({componentIndex: componentIndex,elementIndex: newActiveElement});
      }
    },
  },
  watch: {
    components: {
      handler() {
        this.localComponents = dcopy(this.components);
      },
      deep: true,
    },
    localComponents: {
       handler() {
        this.updateComponentsData(this.localComponents);
       },
      deep: true,
    },
  },
  mounted() {
    this.localComponents = dcopy(this.components);
  },
};
</script>

<style lang="scss">
.constructor-layers {
  &:hover {
    .constructor-layers__item {
      color: #8d9ca8;

      &:hover {
        color: #000000;
      }
    }
  }

  &__item {
    font-size: 14px;
    line-height: 24px;

    &__main {
      display: flex;
      align-items: center;
      padding: 8px 16px;
    }

    .constructor-layers__elements {
      &__item {
        padding: 3px;
        padding-left: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        padding-right: 16px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 20px;
          width: 2px;
          height: 100%;
          background: #e3e9ed;
        }

        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 1px);
          left: 20px;
          width: 8px;
          height: 2px;
          background: #e3e9ed;
        }

        &:last-child {
          &::after {
            height: 50%;
          }
        }

        &:hover {
          .constructor-layers__elements__item__delete {
            display: block;
          }
        }
        &__copy,
        &__delete {
          max-width: 24px;
          min-width: 24px;
          display: none;
          cursor: pointer;

          &:hover {
            svg {
              path {
                fill: #000000;
              }
            }
          }

          svg {
            display: block;
          }
        }

        &--active {
          background: #f1f4f8;

          .constructor-layers__elements__item__delete {
            display: block;
          }
        }
      }
    }

    &--active {
      .constructor-layers__item__main {
        background: #f1f4f8;
        color: #000000;
      }

      .constructor-layers__item__delete {
        display: block;
      }
    }

    &__text {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 24px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__copy,
    &__delete {
      max-width: 24px;
      min-width: 24px;
      display: none;
      cursor: pointer;

      &:hover {
        svg {
          path {
            fill: #000000;
          }
        }
      }

      svg {
        display: block;
      }
    }

    &:hover {
      .constructor-layers__item__copy,
      .constructor-layers__item__delete {
        display: block;
      }
    }
  }
}
</style>
