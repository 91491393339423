<template>
  <div class="stream-picture-with-text" :style="{background: params.background}">
    
    <div class="stream-picture-with-text-img">
      <template v-if="params.srcMob !== ''">
        <img :src="domain + params.srcMob" alt="" class="stream-mobile-show"/>
        <img :src="domain + params.src" alt="" class="stream-desktop-show"/>
      </template>
      <template v-else>
        <img :src="domain + params.src" alt="" />
      </template>
      <div class="stream-picture-with-text-items">
        <div
          v-for="(item, elementIndex) in params.innerElements"
          :key="elementIndex"
          :class="['stream-picture-with-text-item-vertical-'+item.props.vertical,'stream-picture-with-text-item-horisontal-'+item.props.horisontal, (activeElement === elementIndex && activeComponent === componentIndex) ? 'stream-picture-with-text-item--active' : null]"
          class="stream-picture-with-text-item"
          :style="{
            width: item.props.textWidth+'%',
          }"
          @click.stop="setActiveElement({ componentIndex, elementIndex })"
        >
          <div
            class="stream-picture-with-text-title"
            :class="'stream-text-' + item.props.titleFontSize"
            :style="{
              textAlign: item.props.textAlign,
              '--textalignmobile': item.props.textAlignMobile,
              '--titlecolormobile': item.props.titleColorMobile,
              color: item.props.titleColor,
              fontFamily: params.titleFontFamily,
              fontWeight: params.titleFontWeight,
            }"
            v-html="handleNewLine(item.props.titleContent)"
          ></div>
          <div
            class="stream-picture-with-text-description"
            :class="'stream-text-' + item.props.descriptionFontSize"
            :style="{
              textAlign: item.props.textAlign,
              '--textalignmobile': item.props.textAlignMobile,
              '--descriptioncolormobile': item.props.descriptionColorMobile,
              color: item.props.descriptionColor,
              fontFamily: params.descriptionFontFamily,
              fontWeight: params.descriptionFontWeight,
            }"
            v-html="handleNewLine(item.props.descriptionContent)"
          ></div>
          <RemoveItem
            v-if="activeElement === elementIndex && activeComponent === componentIndex && params.innerElements.length > 1"
            @click.native="removeElement({componentIndex, elementIndex})"
          />
        </div>
      </div>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "PictureWithText",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};

</script>

<style lang="scss" scoped>

.stream-picture-with-text-item--active {
  box-shadow: 0 0 1px 1px #f500ed;
  background: var(--activebg) !important;
  color: var(--activecolor) !important;
}

.stream-picture-with-text {
  padding: 0;
  position: relative;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;

  &-img {
    img {
      display: block;
      margin: 0 auto;
      height: auto;
      max-width: 100%;
    }
  }

  &-item {
    position: absolute;
    padding: 30px;
    width: 50%;
    z-index: 1;

    &-vertical {
      &-top {
        top: 0;
      }

      &-middle {
        top: 50%;
        transform: translateY(-50%);
      }

      &-bottom {
        bottom: 0;
      }
    }

    &-horisontal {
      &-left {
        left: 0;
      }

      &-middle {
        left: 50%;
        transform: translateX(-50%);
      }

      &-right {
        right: 0;
      }
    }
    &.stream-picture-with-text-item-vertical-middle.stream-picture-with-text-item-horisontal-middle {
      transform: translate(-50%, -50%);
    }

    &-title {
      padding: 0;
      width: 100%;
    }

    &-description {
      padding: 0;
      margin: 0 auto;
      width: 100%;
    }
  }
}

#stream-container.small-pc.tablet .stream-picture-with-text-item {
  width: 100% !important;
  position: static;
  padding: 16px;
  transform: none !important;

  .stream-picture-with-text-title,
  .stream-picture-with-text-description {
    text-align: var(--textalignmobile) !important;
  }

  .stream-picture-with-text-title {
    color: var(--titlecolormobile) !important;
  }

  .stream-picture-with-text-description {
    color: var(--descriptioncolormobile) !important;
  }
}

</style>
