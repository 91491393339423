<template>
  <div class="stream-video" :style="{background: params.background}">
    <video
      :src="domain + params.src"
      :poster="params.poster !== undefined && params.poster.length ? domain + params.poster : ''"
      playsinline
      muted
      :controls="params.controls == 'true' ? true : false"
      :loop="params.repeat == 'true' ? true : false"
      :autoplay="params.autoplay == 'true' ? true : false"
      :style="{borderRadius:params.borderRadius+'px'}"
    ></video>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";

export default {
  name: "Video",
  props: {
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement", "removeComponent"]),
  },
};
</script>

<style lang="scss" scoped>
.stream-video {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  video {
    max-width: 100%;
    margin: 0 auto;
    height: auto;
    display: block;
  }
}
</style>
