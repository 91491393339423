<template>
  <div class="stream-header-image-text">
    <div class="stream-header-image-title">
      {{params.mainHeadlineText}}
    </div>
    <div class="stream-header-image-img">
      <img :src="domain + params.mainImage" :alt="params.mainImageAltText">
    </div>
    <div class="stream-header-image-subtitle">{{params.mainSubHeadlineText}}</div>
    <div class="stream-header-image-body" v-html="handleNewLine(params.mainBodyText)"></div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "StandardHeaderImageTextModule",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.stream-header-image-text {
  max-width: 970px;
  margin: auto;

  .stream-header-image-title {
    font-family: "Amazon Ember", Arial, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 4px;
    margin-bottom: 4px;
  }

  .stream-header-image-img {
    img {
      max-width: 100%;
      margin-bottom: 12px;
    }
  }

  .stream-header-image-subtitle {
    font-family: "Amazon Ember", Arial, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 4px;
    margin-bottom: 4px;
  }

  .stream-header-image-body {
    font-family: "Amazon Ember", Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;

    br {
      content: "";
      display: block;
      margin-bottom: 14px;
    }
  }
}

.small-pc.tablet.mobile {
  .stream-header-image-title,
  .stream-header-image-subtitle {
    font-size: 18px;
  }
}
</style>
