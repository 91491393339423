<template>
  <div
    class="stream-button"
    :style="{
      background: params.background,
      justifyContent: params.buttonPosition,
    }"
  >
    <a
      :href="params.src.length ? domain + params.src : 'javascript:void(0);'"
      class="stream-button-button"
      :class="`stream-text-${params.fontSize}`"
      :style="{
        fontFamily: params.fontFamily,
        fontWeight: params.fontWeight,
        background: params.buttonBackground,
        color: params.color,
        borderRadius: params.borderRadius+'px',
      }"
      target="_blank"
      download
    >
      {{ params.content }}
    </a>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "Button",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.stream-button {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

  .stream-button-button {
    display: inline-flex;
    min-width: 100px;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    border-radius: 8px;
    line-height: 1;
    cursor: pointer;
  }
}
</style>