<template>
  <div
    :class="activeType === 'mobile' ? 'ra-b6' : 'ra-b7'"
    :style="{ textAlign: params.textAlign }"
  >
    <div :class="['ra-c', activeType === 'mobile' ? 'ra-c0' : null]">
      <div
        :class="[
          activeType === 'mobile' ? 'ra-c1' : 'ra-c4',
          params.direction === 'left' ? 'ra-c5' : null,
        ]"
      >
        <div :class="['ra-e7', activeType === 'mobile' ? 'ra-c2' : 'ra-c7']">
          <div>
            <img
              loading="lazy"
              :src="
                domain + (activeType === 'mobile' ? params.srcMob : params.src)
              "
              class="ra-e8 ui-n6"
            />
          </div>
        </div>
        <div :class="activeType === 'mobile' ? null : 'ra-c6'">
          <div>
            <span
              :class="[
                'ra-d5',
                activeType === 'mobile' ? 'ra-d6' : null,
                'ra-d9',
                activeType === 'mobile' ? 'ra-e1' : 'ra-e0',
              ]"
            >
              <span
                :class="'stream-text-' + params.titleFontSize"
                v-html="handleNewLine(params.titleContent)"
              ></span>
            </span>
            <span class="ra-d9">
              <span
                :class="'stream-text-' + params.descriptionFontSize"
                v-html="handleNewLine(params.descriptionContent)"
              ></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";

export default {
  name: "OzonChess",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement", "removeComponent"]),
  },
};
</script>
