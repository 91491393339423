<template>
  <div class="master-styles">
    <slot/>
  </div>
</template>
<script>
export default {
  name: "MasterTemplateStyles",
};
</script>

<style lang="scss">
.master-styles .stream-text-1 {
  font-size: 14px;
  line-height: 1.43;
}

.master-styles .stream-text-2 {
  font-size: 20px;
  line-height: 1.5;
}

.master-styles .stream-text-3 {
  font-size: 28px;
  line-height: 1.28;
}

.master-styles .stream-text-4 {
  font-size: 36px;
  line-height: 1.33;
}

.master-styles .stream-text-5 {
  font-size: 48px;
  line-height: 1.33;
}

.master-styles .stream-text-6 {
  font-size: 60px;
  line-height: 1.36;
}

.master-styles .stream-text-7 {
  font-size: 72px;
  line-height: 1.28;
}

.master-styles .small-pc.tablet {
  .stream-text-1 {
    font-size: 12px;
    line-height: 1.5;
  }

  .stream-text-2 {
    font-size: 18px;
    line-height: 1.44;
  }

  .stream-text-3 {
    font-size: 24px;
    line-height: 1.33;
  }

  .stream-text-4 {
    font-size: 28px;
    line-height: 1.36;
  }

  .stream-text-5 {
    font-size: 36px;
    line-height: 1.33;
  }

  .stream-text-6 {
    font-size: 38px;
    line-height: 1.33;
  }

  .stream-text-7 {
    font-size: 60px;
    line-height: 1.36;
  }

  &.mobile {
    .stream-text-1 {
      font-size: 12px;
      line-height: 1.5;
    }

    .stream-text-2 {
      font-size: 16px;
      line-height: 1.5;
    }

    .stream-text-3 {
      font-size: 20px;
      line-height: 1.5;
    }

    .stream-text-4 {
      font-size: 24px;
      line-height: 1.33;
    }

    .stream-text-5 {
      font-size: 28px;
      line-height: 1.36;
    }

    .stream-text-6 {
      font-size: 36px;
      line-height: 1.33;
    }

    .stream-text-7 {
      font-size: 48px;
      line-height: 1.33;
    }
  }
}
</style>
