<template>
  <div class="stream-iframe">
    <iframe
      :src="params.link"
      :style="{ height: params.height+params.heightUnits }"
      class="stream-iframe-iframe"
      frameborder="0"
    ></iframe>
    <div class="stream-iframe-overlay"></div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "Iframe",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.stream-iframe {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  position: relative;

  &-iframe {
    width: 100%;
    border: none;
  }

  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
</style>