<template>
  <div v-if="fields.length">
    <div v-for="(field, fieldIndex) in fields" :key="fieldIndex">
      <Textarea
        :label="label + '-' + (fieldIndex + 1)"
        :startValue="field"
        v-model="fields[fieldIndex]"
      />
    </div>
  </div>
</template>

<script>
import Textarea from "../Textarea/Textarea.vue";

export default {
  name: "TextArray",
  components: {
    Textarea,
  },
  props: {
    label: {
      type: String,
    },
    index: {
      type: Number,
    },
    currentValue: {
      type: Array,
    },
    activeElement: {
      type: Number,
    },
    colsQn: {
      type: Number,
    },
  },
  data() {
    return {
      fields: [],
    };
  },
  methods: {
    createFields() {
      const fields = [];
      for (let i = 0; i <= this.colsQn; i++) {
        const currentElem = this.currentValue[i] ? this.currentValue[i] : "";

        fields.push(currentElem);
      }
      this.fields = fields;
    },
  },
  watch: {
    activeElement: function() {
      this.createFields();
    },
    colsQn: function () {
      this.createFields();
    },
    fields: {
      handler: function () {
        this.$emit("textArrayUpdate", {
          index: this.index,
          value: this.fields,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.createFields();
  },
};
</script>

<style lang="scss" scoped>
</style>
 