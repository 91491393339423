<template>
  <div class="constructor-editor-panel">
    <div
      class="row"
      v-if="componentProps.length"
    >
      <div v-if="changingComponents.includes(currentComponentName)">


      </div>
      <va-select
          label="Component"
          v-if="changingComponents.includes(currentComponentName) && activeElement === null"
          name="Component"
          :options="changingComponents"
          v-model="currentComponentName"
          clearable="false"
          noClear
        />
      <div
        v-for="(param, index) in componentProps"
        :key="index"
        class="xs12 md12 flex"
      >
        <div
          v-if="activeElement === null || (param.type === 'array' && activeElement !== null)"
          class="constructor-editor-panel__item"
        >
          <Textarea
            :label="param.nameText"
            v-if="param.type === 'text'"
            :startValue="param.defaultValue"
            v-model="param.defaultValue"
          />
          <ColorPanel
            :label="param.nameText"
            :startValue="param.defaultValue"
            v-else-if="param.type === 'color'"
            v-model="param.defaultValue"
          />
          <FontSizePanel
            v-else-if="param.type === 'fontSize'"
            :label="param.nameText"
            :name="param.name"
            :options="param.values"
            :currentValue="''+param.defaultValue"
            :index="index"
            @fontSizeUpdate="fontSizeUpdate"
          />
          <TextAlignPanel
            v-else-if="param.type === 'textAlign'"
            :name="param.name"
            :label="param.nameText"
            :options="param.values"
            :currentValue="param.defaultValue"
            :index="index"
            @textAlignUpdate="textAlignUpdate"
          />

          <UploadImg
            v-else-if="param.type === 'file'"
            v-model="param.defaultValue"
            :name="param.name"
            :index="index"
            :label="param.nameText"
            :recommendedWidth="param.recommendedWidth"
            @imgUpdate="imgUpdate"
          />
          <UploadVideo
            v-else-if="param.type === 'video'"
            v-model="param.defaultValue"
            :index="index"
            :label="param.nameText"
            :name="param.name"
            @videoUpdate="videoUpdate"
          />
          <va-input
            v-else-if="param.type === 'number'"
            type="number"
            :label="param.nameText"
            :name="param.name"
            v-model="param.defaultValue"
            :min="param.min ? param.min : 1"
            @input="columnsRefresh(param.defaultValue)"
          />
          <va-select
            :label="param.nameText"
            v-else-if="param.type === 'select' && (param.name === 'fontFamily' || param.name === 'titleFontFamily' || param.name === 'descriptionFontFamily' || param.name === 'disclaimerFontFamily')"
            :name="param.name"
            :options="param.values"
            v-model="param.defaultValue"
            clearable="false"
            @input="checkFieldName(param.name)"
            searchable
            noClear
          />
          <va-select
            :label="param.nameText"
            v-else-if="param.type === 'select' && param.name !== 'fontFamily' && param.name !== 'titleFontFamily' && param.name !== 'descriptionFontFamily' && param.name !== 'disclaimerFontFamily'"
            :name="param.name"
            :options="param.values"
            v-model="param.defaultValue"
            clearable="false"
            @input="checkFieldName(param.name)"
            noClear
          />
          <InnerPanel v-else-if="param.type === 'array' && activeElement !== null" />
        </div>
      </div>
    </div>

    <div class="constructor-editor-panel__buttons constructor-editor-panel__item">
      <button
        class="constructor-editor-panel__button constructor-editor-panel__button--delete-button"
        @click="removeComponent(activeComponent)"
      >
        Delete
        <Trash />
      </button>
    </div>
  </div>
</template>
<script>
import dcopy from "deep-copy";
import { mapActions, mapState } from "vuex";

import Trash from "../ui/icons/constructor/Trash.vue";

import Textarea from "./editPanels/Textarea/Textarea.vue";
import ColorPanel from "./editPanels/ColorPanel/ColorPanel.vue";
import TextAlignPanel from "./editPanels/TextAlignPanel/TextAlignPanel.vue";
import FontSizePanel from "./editPanels/FontSizePanel/FontSizePanel.vue";
import UploadImg from "./editPanels/UploadImg/UploadImg.vue";
import UploadVideo from "./editPanels/UploadVideo/UploadVideo.vue";
import InnerPanel from "./InnerPanel.vue";

export default {
  name: "EditorPanel",
  components: {
    UploadImg,
    UploadVideo,
    TextAlignPanel,
    FontSizePanel,
    Textarea,
    ColorPanel,
    InnerPanel,
    Trash,
  },
  data() {
    return {
      componentProps: [],
      changingComponents: [],
      currentComponentName: '', 
      changingComponentsList: {
        master_template: ['Col2','Col3','Col4','Icons'],
        tefal_template: ['Col2','Col3','Col4','Icons'],
        krups_template: ['Col2','Col3','Col4','Icons'],
        wmf_template: ['Col2','Col3','Col4','Icons'],
        moulinex_template: ['Col2','Col3','Col4','Icons'],
        rowenta_template: ['Col2','Col3','Col4','Icons'],
        mvideo_template: ['Col2','Col3','Col4','Icons'],
        eld_template: ['EldCol2','EldCol3','EldCol4','EldIcons'],
        ozon_template: ['OzonCol2','OzonCol3','OzonCol4'],
      }
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      sectionsParams: (state) => state.constructorData.sectionsParams,
      localComponents: (state) => state.constructorData.epackData.components,
      availableFonts: (state) => state.constructorData.availableFonts,
      currentTemplate:(state) => state.constructorData.currentTemplate,
      currentLocale:(state) => state.constructorData.currentLocale,
    }),
    editParams() {
      let activeComponentStructure = [];
      if (this.activeComponent !== null) {
        const activeComponentName = this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].component;
        activeComponentStructure = dcopy(this.sectionsParams[activeComponentName].props);
        activeComponentStructure.map((elem) => {
          if (elem.name !== "innerElements") {
            elem.defaultValue = dcopy(this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].props[elem.name]);
          }
        });
        const fontsList = [];
        this.availableFonts.map((font) => {
          fontsList.push(font.fontFamily);
        });
        activeComponentStructure.map((elem) => {
          if (elem.name === "fontFamily" || elem.name === "titleFontFamily" || elem.name === "descriptionFontFamily" ||  elem.name === "disclaimerFontFamily") {
            elem.values = fontsList;
          }
        });
      }
      return activeComponentStructure;
    },
  },
  methods: {
    ...mapActions("constructorData", ["updateComponentsData", "removeComponent", "updateUsedFonts"]),

    updateEpackContent: function (component) {
      const componentsForUpdate = dcopy(this.localComponents);
      const currentSection = componentsForUpdate[this.currentLocale][this.currentTemplate][this.activeComponent];

      component.map((elem) => {
        if (elem.name !== "innerElements") {
          currentSection.props[elem.name] = elem.defaultValue;
        }
      });

      this.updateComponentsData(componentsForUpdate);
    },

    fontChange: function () {
      let currentFont = "";
      this.componentProps.map((elem) => {
        if (elem.name === "fontFamily" || elem.name === "titleFontFamily" || elem.name === "descriptionFontFamily" ||  elem.name === "disclaimerFontFamily") {
          currentFont = elem.defaultValue;
        }
      });
      let fontIndex = -1;
      this.availableFonts.map((elem, index) => {
        if (elem.fontFamily === currentFont) {
          fontIndex = index;
        }
      });
      this.componentProps.map((elem) => {
        if (elem.name === "fontWeight" || elem.name === "titleFontWeight" || elem.name === "descriptionFontWeight"  ||  elem.name === "disclaimerFontWeight") {
          elem.values = this.availableFonts[fontIndex].fontWeight;
          elem.defaultValue = elem.values[0];
        }
      });
    },

    checkFieldName: function (name) {
      if (name === "fontFamily" || name === "titleFontFamily" || name === "descriptionFontFamily" ||  name === "disclaimerFontFamily") {
        this.fontChange();
        setTimeout(() => {
          this.updateUsedFonts();
        }, 100);
      }
      if (name === "fontWeight" || name === "titleFontWeight" || name === "descriptionFontWeight"  ||  name === "disclaimerFontWeight") {
        setTimeout(() => {
          this.updateUsedFonts();
        }, 100);
      }
    },
    videoUpdate: function (response) {
      this.componentProps[response.index].defaultValue = response.src;
    },
    imgUpdate: function (response) {
      this.componentProps[response.index].defaultValue = response.src;
    },
    fontSizeUpdate: function (response) {
      this.componentProps[response.index].defaultValue = response.value;
    },
    textAlignUpdate: function (response) {
      this.componentProps[response.index].defaultValue = response.value;
    },
    columnsRefresh(columnQn) {
      if(!this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].props.columns) {
      // if(!this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].props.innerElements) {
        return false;
      }
      const rowList = dcopy(this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].props.innerElements);
      const newRowList = [];

      rowList.forEach(row => {
        const content = dcopy(row.props.rowContent);
        const images = dcopy(row.props.rowImages);
        const backgrounds = dcopy(row.props.rowBackgrounds);
        const colors = dcopy(row.props.rowColors);
        const newRow = {};
        newRow.component = "Row";
        newRow.props = {};
        if(content.length >= columnQn) {
          content.length = columnQn;
        } else {
          for(let i = 0; i < columnQn - content.length; i++) {
            content.push('');
          }
        }

        if(images.length >= columnQn) {
          images.length = columnQn;
        } else {
          for(let i = 0; i < columnQn - images.length; i++) {
            images.push('');
          }
        }
        if(backgrounds.length >= columnQn) {
          backgrounds.length = columnQn;
        } else {
          for(let i = 0; i < columnQn - backgrounds.length; i++) {
            backgrounds.push('');
          }
        }
        if(colors.length >= columnQn) {
          colors.length = columnQn;
        } else {
          for(let i = 0; i < columnQn - colors.length; i++) {
            colors.push('');
          }
        }
        newRow.props.rowContent = content;
        newRow.props.rowImages = images;
        newRow.props.rowColors = colors;
        newRow.props.rowBackgrounds = backgrounds;

        newRowList.push(newRow);
      });
      const componentsForUpdate = dcopy(this.localComponents);
      componentsForUpdate[this.currentLocale][this.currentTemplate][this.activeComponent].props.innerElements = newRowList;
      this.updateComponentsData(componentsForUpdate);
    }
  },
  mounted() {
    this.componentProps = dcopy(this.editParams);
    this.changingComponents = this.changingComponentsList.hasOwnProperty(this.currentTemplate) ? dcopy(this.changingComponentsList[this.currentTemplate]) : [];
    this.currentComponentName = this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].component;

  },
  watch: {
    currentComponentName() {
      const componentsForUpdate = dcopy(this.localComponents)
      componentsForUpdate[this.currentLocale][this.currentTemplate][this.activeComponent].component = this.currentComponentName;
      this.updateComponentsData(componentsForUpdate);
    },
    currentTemplate() {
      this.changingComponents = this.changingComponentsList.hasOwnProperty(this.currentTemplate) ? dcopy(this.changingComponentsList[this.currentTemplate]) : [];
    },
    localComponents: {
      handler: function () {
        this.componentProps = dcopy(this.editParams);
      },
      deep: true,
    },
    activeComponent() {
      // handler: function () {
        this.componentProps = dcopy(this.editParams);
        this.currentComponentName = this.localComponents[this.currentLocale][this.currentTemplate][this.activeComponent].component;
      // },
      // deep: true,
    },
    componentProps: {
      handler: function () {
        this.updateEpackContent(this.componentProps);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.constructor-editor-panel {
  padding: 16px;

  &__item {
    padding: 0.75rem;
    // padding: 10px !important;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    // border: 1px solid #e5e8ea;
    border: none;
    border-radius: 6px;
    height: 40px;
    cursor: pointer;

    &--delete-button {
      color: #ffffff;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      background: #db4545;

      svg {
        margin-left: 4px;
        display: block;

        path {
          fill: #ffffff;
        }
      }
    }
  }

  .flex {
    padding: 0 !important;
  }

  .va-input {
    border: 1px solid rgb(241, 244, 248) !important;
    border-radius: 6px;
    padding: 21px 16px 5px !important;
    margin-bottom: 0;

    &__container {
      height: auto;

      &__label {
        left: 0;
        position: absolute;
        font-size: 12px;
        color: #8d9ca8 !important;
        font-weight: normal;
        text-transform: none;
      }
    }
  }

  .dropzone--white {
    padding: 1.5rem 0.5rem 0.5rem;

    .va-file-upload__field__button {
      background: #e3e9ed !important;
      color: #000000 !important;
      border: none;
    }

    .va-file-upload__field {
      padding: 0 0.5rem 1rem;
      text-align: center;
    }

    &.dropzone--vertical {
      .va-file-upload__field__text {
        padding-right: 0;
        padding-bottom: 10px;
      }
    }
  }
}

.input--color .va-input__container__content-wrapper input {
  height: 1.5em;
}
</style>
