<template>
  <div class="stream-description-wrapper">
    <div
      :class="['stream-description', 'stream-text-' + params.fontSize]"
      :style="{
        textAlign: params.textAlign,
        '--textalignmobile': params.textAlignMobile,
        background: params.background,
        color: params.color,
        fontFamily: params.fontFamily,
        fontWeight: params.fontWeight,
      }"
      v-html="handleNewLine(params.content)"
    ></div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";

export default {
  name: "Description",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement", "removeComponent"]),
  },
};
</script>

<style lang="scss" scoped>
.stream-description {
  // font-size: 21px;
  // line-height: 1.2;
  padding: 0 20px;
}

.small-pc.tablet.mobile .stream-description {
  // font-size: 18px;
  text-align: var(--textalignmobile) !important;
}
</style>
