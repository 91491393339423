<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="5" width="16" height="2" fill="black"/>
    <rect x="8" y="11" width="8" height="2" fill="black"/>
    <rect x="6" y="17" width="12" height="2" fill="black"/>
  </svg>
</template>

<script>
  export default {
    name: "TextAlignCenter",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
