<template>
  <div>
    <div class="stream-logo">
      <img
        :src="domain + params.mainImage"
        :alt="params.mainImageAltText"
        class="stream-logo-img"
      />
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "StandardCompanyLogoModule",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.stream-logo {
  margin-bottom: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;

  &-img {
    max-width: 100%;
    height: auto;
  }
}

.small-pc.tablet.mobile {
  .stream-logo {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 0;
  }
}
</style>