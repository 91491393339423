

<template>
    <svg  @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 13.9998V18.9998V19.9998H4V18.9998V4.99976C4 4.44976 4 3.99976 4 3.99976H10.02V2.99976V1.99976H4C2.9 1.99976 2 2.89976 2 3.99976V19.9998C2 21.0998 2.9 21.9998 4 21.9998H20C21.1 21.9998 22 21.0998 22 19.9998V13.9998H21H20Z" fill="white"/>
    <path d="M16.4992 17H7.5027C7.09195 17 6.85151 16.4639 7.10196 16.0989L9.65665 12.2324C9.847 11.9245 10.2477 11.9245 10.4481 12.221L12.8024 15.6655L14.3653 13.5213C14.413 13.4553 14.473 13.4024 14.5411 13.3665C14.6091 13.3305 14.6834 13.3124 14.7584 13.3135C14.8334 13.3146 14.9072 13.3349 14.9745 13.3728C15.0417 13.4107 15.1005 13.4654 15.1467 13.5327L16.8899 16.0761C17.1504 16.4525 16.92 17 16.4992 17Z" fill="white"/>
    <path d="M20.9788 8.11972V10.1138H13.9078V3.04269H15.9018L15.9089 6.69843L20.2717 2.33558C20.6622 1.94506 21.2954 1.94506 21.6859 2.33558C22.0764 2.72611 22.0764 3.35927 21.6859 3.7498L17.3231 8.11265L20.9788 8.11972Z" fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: "Save",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
  