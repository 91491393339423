<template>
  <div
    class="stream-ar"
    :style="{background: params.background}"
  >
    <div
      class="stream-ar-title"
      :class="`stream-text-${params.titleFontSize}`"
      :style="{
        textAlign: params.titleTextAlign,
        color: params.titleColor,
        fontFamily: params.titleFontFamily,
        fontWeight: params.titleFontWeight,
      }"
      v-html="handleNewLine(params.titleContent)"
    ></div>
    <div class="stream-ar-inner">
      <div class="stream-ar-img">
        <img
          :src="domain + params.src"
          alt=""
        />
      </div>
      <div class="stream-ar-text-outer">
        <div
          class="stream-ar-text"
          :class="`stream-text-${params.descriptionFontSize}`"
          :style="{
            textAlign: params.descriptionTextAlign,
            color: params.descriptionColor,
            fontFamily: params.descriptionFontFamily,
            fontWeight: params.descriptionFontWeight,
          }"
          v-html="handleNewLine(params.descriptionContent)"
        ></div>
        <a
          class="stream-ar-button"
          :class="`stream-text-${params.buttonFontSize}`"
          :style="{
            fontFamily: params.buttonFontFamily,
            fontWeight: params.buttonFontWeight,
            background: params.buttonBackground,
            color: params.buttonColor,
          }"
        >
          <svg width="35" height="42" viewBox="0 0 35 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.9" d="M1.09082 16.2364V11.4837L5.20121 9.11816" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M5.17957 32.8599L1.09082 30.4944V25.7417" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M21.578 37.6343L17.4677 39.9998L13.3789 37.6343" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M33.8877 25.7637V30.5164L29.7773 32.9036" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M29.8203 9.14014L33.9091 11.5273V16.2801" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M13.4219 4.3655L17.5106 2L21.621 4.38721" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M13.4004 18.6235L17.5108 20.989" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M1.09082 11.4839L5.20121 13.8711" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M21.6211 18.6235L17.5107 20.989" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M33.9091 11.5273L29.8203 13.8928" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M17.5107 25.742V20.9893" :stroke="params.buttonColor" stroke-width="2" stroke-miterlimit="10"></path>
            <path opacity="0.9" d="M17.4678 40.0003L17.4894 35.2476" stroke="blwhiteack" stroke-width="2" stroke-miterlimit="10"></path>
          </svg>
          <span v-html="handleNewLine(params.buttonContent)"></span>
        </a>
      </div>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "ARBanner",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.stream-ar {
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Graphik LCG";
  padding: 40px 0 30px;

  .stream-ar-title {
    text-align: center;
    line-height: 1;
    font-family: "Graphik LCG";
  }

  .stream-ar-inner {
    display: flex;
    padding: 55px 0;
  }

  .stream-ar-img {
    width: 72%;

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }

  .stream-ar-text-outer {
    padding-right: 4%;
    width: 28%;
  }

  .stream-ar-text {
    max-width: 247px;
  }
  .stream-ar-button {
    display: flex;
    width: 100%;
    max-width: 300px;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    border-radius: 8px;
    line-height: 1;
    margin: 30px 0 0;
    cursor: pointer;

    svg {
      display: block;
      width: 22px;
      height: auto;
      margin-right: 16px;
    }
  }
}

.small-pc.tablet {
  .stream-ar {
    .stream-ar-text-outer {
      width: 35%;
    }

    .stream-ar-img {
      width: 65%;
    }
  }

  &.mobile {
    .stream-ar {
      .stream-ar-inner {
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;
      }

      .stream-ar-text-outer {
        width: 100%;
        padding: 0;
      }

      .stream-ar-img {
        width: 100%;
      }
      .stream-ar-text {
        text-align: center;
        max-width: none;
        padding: 40px 0 0;
        width: 95%;
        margin: auto;
      }

      .stream-ar-button {
        margin: 30px auto 0;
        width: 95%;
      }
    }
  }
}
</style>