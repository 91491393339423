var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stream-disclaimer-wrapper"},[_c('div',{class:['stream-disclaimer', 'stream-text-'+ _vm.params.fontSize],style:({
      textAlign: _vm.params.textAlign,
      '--textalignmobile': _vm.params.textAlignMobile,
      background: _vm.params.background,
      color: _vm.params.color,
      fontFamily: _vm.params.fontFamily,
      fontWeight: _vm.params.fontWeight,
    }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(_vm.params.content))}}),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }