<template>
  <div>
    <div class="stream-side" :class="['stream-' + params.imagePosition]">
      <div class="stream-img">
        <img :src="domain + params.mainImage" :alt="params.mainImageAltText" />
      </div>
      <div class="stream-content">
        <div class="stream-headline">{{ params.mainHeadlineText }}</div>
        <div
          class="stream-body"
          v-html="handleNewLine(params.mainBodyText)"
        ></div>
      </div>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "StandardSingleSideImageModule",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.stream-side {
  display: flex;
  align-items: center;
  max-width: 970px;
  margin: auto;

  .stream-img {
    width: 300px;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.stream-right {
    .stream-img {
      order: 2;
    }

    .stream-content {
      padding-left: 0;
      padding-right: 40px;
    }
  }

  .stream-content {
    padding-left: 40px;
    width: calc(100% - 300px);
  }

  .stream-headline {
    font-weight: bold;
    font-size: 17px;
    line-height: 1.255;
    margin-bottom: 6px;
    font-family: "Amazon Ember", Arial, sans-serif;
  }

  .stream-body {
    padding: 0;
    margin: 0 0 14px 0;
    font-size: 14px;
    line-height: 20px;
    font-family: "Amazon Ember", Arial, sans-serif;

    br {
      content: "";
      display: block;
      margin-bottom: 14px;
    }
  }
}

.small-pc.tablet.mobile {
  .stream-side {
    flex-direction: column;
    width: auto;

    .stream-img {
      order: -1 !important;
      margin-bottom: 17px;
    }

    .stream-content {
      padding: 0 !important;
      width: 100%;
    }

    .stream-headline {
      font-size: 18px;
    }
  }
}
</style>
