<template>
  <div
    :class="['stream-grid', 'stream-' + params.direction]"
    :style="{
      background: params.background,
      '--imgcolwidth': params.imgColWidth
    }"
  >
    <div class="stream-grid-img">
      <div class="stream-grid-img-slider">
        <input
          v-for="(slide, elementIndex) in params.innerElements"
          :key="elementIndex"
          class="stream-grid-img-slider-dot-indicator"
          type="radio"
          :name="'stream-grid-img-slider-nav-' + componentIndex"
          :id="'block-' + componentIndex + '-dot-' + elementIndex"
          :checked="elementIndex === 0"
        />
        <div class="stream-grid-img-slider-section-wrap">
          <div class="stream-grid-img-slide-wrapper">
            <div
              v-for="(slide, elementIndex) in params.innerElements"
              :key="elementIndex"
              :class="
                'stream-grid-img-slide' +
                (activeElement === elementIndex &&
                activeComponent === componentIndex
                  ? ' stream-grid-img-slide--active'
                  : '')
              "
              @click.stop="setActiveElement({ componentIndex, elementIndex })"
            >
              <div class="stream-slide-inner">
                <div
                  v-if="slide.props.youtubeID"
                  class="stream-grid-img-slider-youtube__video"
                >
                  <iframe
                    :src="`https://www.youtube.com/embed/${slide.props.youtubeID}?enablejsapi=1&rel=0&autoplay=0`"
                    frameborder="0"
                    :style="{borderRadius:params.borderRadius+'px'}"
                  ></iframe>
                </div>
                <div v-else-if="slide.props.videoSrc">
                  <video
                    :src="domain + slide.props.videoSrc"
                    playsinline
                    muted
                    :controls="slide.props.controls == 'true' ? true : false"
                    :loop="slide.props.repeat == 'true' ? true : false"
                    :autoplay="slide.props.autoplay == 'true' ? true : false"
                    :style="{borderRadius:params.borderRadius+'px'}"
                  ></video>
                </div>
                <div v-else class="stream-grid-img-slide-img">
                  <div class="stream-grid-img-slide-img-inner">
                    <template v-if="slide.props.srcMob !== ''">
                      <img :src="domain + slide.props.srcMob" alt="" class="stream-mobile-show" :style="{borderRadius:params.borderRadius+'px'}"/>
                      <img :src="domain + slide.props.src" alt="" class="stream-desktop-show" :style="{borderRadius:params.borderRadius+'px'}"/>
                    </template>
                    <template v-else>
                      <img :src="domain + slide.props.src" alt="" :style="{borderRadius:params.borderRadius+'px'}"/>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="stream-grid-img-slider-nav">
            <div class="stream-grid-img-slider-nav-prev-wrap">
              <label
                v-for="(slide, elementIndex) in params.innerElements"
                :key="elementIndex"
                class="stream-grid-img-slider-nav-prev"
                :for="'block-' + componentIndex + '-dot-' + elementIndex"
              >
                <div
                  class="stream-grid-img-slider-custom-arrow-prev"
                  v-if="params.customArrow"
                  v-html="params.customArrow"
                ></div>
                <svg v-else width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30 0C46.5425 0 60 13.4575 60 30C60 46.5425 46.5425 60 30 60C13.4575 60 0 46.5425 0 30C0 13.4575 13.4575 0 30 0ZM20.7325 31.7675L33.2325 44.2675C33.72 44.755 34.36 45 35 45C35.64 45 36.2801 44.755 36.7676 44.2675C37.745 43.29 37.745 41.71 36.7676 40.7325L26.035 30L36.7675 19.2675C37.7449 18.29 37.7449 16.71 36.7675 15.7325C35.79 14.7551 34.21 14.7551 33.2325 15.7325L20.7325 28.2326C19.755 29.21 19.755 30.79 20.7325 31.7675Z" :fill="params.arrowsColor"/>
                </svg>
              </label>
            </div>
            <div class="stream-grid-img-slider-nav-next-wrap">
              <label
                v-for="(slide, elementIndex) in params.innerElements"
                :key="elementIndex"
                class="stream-grid-img-slider-nav-next"
                :for="'block-' + componentIndex + '-dot-' + elementIndex"
              >
                <div
                  class="stream-grid-img-slider-custom-arrow-next"
                  v-if="params.customArrow"
                  v-html="params.customArrow"
                ></div>
                <svg v-else width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30 0C13.4575 0 0 13.4575 0 30C0 46.5425 13.4575 60 30 60C46.5425 60 60 46.5425 60 30C60 13.4575 46.5425 0 30 0ZM39.2675 31.7675L26.7675 44.2675C26.28 44.755 25.64 45 25 45C24.36 45 23.7199 44.755 23.2324 44.2675C22.255 43.29 22.255 41.71 23.2324 40.7325L33.965 30L23.2325 19.2675C22.2551 18.29 22.2551 16.71 23.2325 15.7325C24.21 14.7551 25.79 14.7551 26.7675 15.7325L39.2675 28.2326C40.245 29.21 40.245 30.79 39.2675 31.7675Z" :fill="params.arrowsColor"/>
                </svg>
              </label>
            </div>
          </div>
          <div class="stream-grid-img-slider-dots">
            <div
              class="stream-grid-img-slider-dot"
              :key="elementIndex"
              v-for="(slide, elementIndex) in params.innerElements"
            >
              <label
                class="stream-grid-img-slider-dot-inner"
                :for="'block-' + componentIndex + '-dot-' + elementIndex"
                :style="{
                  background: params.dotsColor,
                  color: params.activeDotColor,
                }"
              ></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stream-grid-content">
      <div class="stream-grid-content-wrap">
        <div
          :class="['stream-grid-title', 'stream-text-' + params.titleFontSize]"
          :style="{
            textAlign: params.titleTextAlign,
            '--titletextalignmobile': params.titleTextAlignMobile,
            color: params.titleColor,
            fontFamily: params.titleFontFamily,
            fontWeight: params.titleFontWeight,
          }"
          v-html="handleNewLine(params.titleContent)"
        ></div>
        <div
          :class="[
            'stream-grid-text',
            'stream-text-' + params.descriptionFontSize,
          ]"
          :style="{
            textAlign: params.descriptionTextAlign,
            '--descriptiontextalignmobile': params.descriptionTextAlignMobile,
            color: params.descriptionColor,
            fontFamily: params.descriptionFontFamily,
            fontWeight: params.descriptionFontWeight,
          }"
          v-html="handleNewLine(params.descriptionContent)"
        ></div>
      </div>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";

export default {
  name: "Grid",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement", "removeComponent"]),
  },
};
</script>

<style lang="scss" scoped>
.stream-grid-img-slide--active {
  box-shadow: 0 0 1px 1px #f500ed;
}
.stream-grid,
.stream-grid * {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
}

.stream-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  background: #ffffff;

  &.stream-left {
    .stream-grid-img {
      order: 2;
    }
  }

  &.stream-right {
    .stream-grid-img {
      order: -1;
    }
  }

  .stream-grid-img {
    width: var(--imgcolwidth);
    // width: 50%;

    .stream-grid-img-slider {
      overflow: hidden;

      .stream-grid-img-slider-dot-indicator {
        display: none !important;

        &:nth-of-type(1):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slide-wrapper {
          transform: translateX(0%);
        }

        &:nth-of-type(2):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slide-wrapper {
          transform: translateX(-100%);
        }

        &:nth-of-type(3):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slide-wrapper {
          transform: translateX(-200%);
        }

        &:nth-of-type(4):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slide-wrapper {
          transform: translateX(-300%);
        }

        &:nth-of-type(5):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slide-wrapper {
          transform: translateX(-400%);
        }

        &:nth-of-type(6):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slide-wrapper {
          transform: translateX(-500%);
        }

        &:nth-of-type(7):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slide-wrapper {
          transform: translateX(-600%);
        }

        &:nth-of-type(8):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slide-wrapper {
          transform: translateX(-700%);
        }

        &:nth-of-type(9):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slide-wrapper {
          transform: translateX(-800%);
        }

        &:nth-of-type(10):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slide-wrapper {
          transform: translateX(-900%);
        }

        &:nth-of-type(1):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-dots .stream-grid-img-slider-dot:nth-of-type(1) .stream-grid-img-slider-dot-inner,
        &:nth-of-type(2):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-dots .stream-grid-img-slider-dot:nth-of-type(2) .stream-grid-img-slider-dot-inner,
        &:nth-of-type(3):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-dots .stream-grid-img-slider-dot:nth-of-type(3) .stream-grid-img-slider-dot-inner,
        &:nth-of-type(4):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-dots .stream-grid-img-slider-dot:nth-of-type(4) .stream-grid-img-slider-dot-inner,
        &:nth-of-type(5):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-dots .stream-grid-img-slider-dot:nth-of-type(5) .stream-grid-img-slider-dot-inner,
        &:nth-of-type(6):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-dots .stream-grid-img-slider-dot:nth-of-type(6) .stream-grid-img-slider-dot-inner,
        &:nth-of-type(7):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-dots .stream-grid-img-slider-dot:nth-of-type(7) .stream-grid-img-slider-dot-inner,
        &:nth-of-type(8):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-dots .stream-grid-img-slider-dot:nth-of-type(8) .stream-grid-img-slider-dot-inner,
        &:nth-of-type(9):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-dots .stream-grid-img-slider-dot:nth-of-type(9) .stream-grid-img-slider-dot-inner,
        &:nth-of-type(10):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-dots .stream-grid-img-slider-dot:nth-of-type(10) .stream-grid-img-slider-dot-inner {
          cursor: default;
          background: currentColor !important;
        }

        &:nth-of-type(2):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev:nth-of-type(1),
        &:nth-of-type(3):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev:nth-of-type(2),
        &:nth-of-type(4):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev:nth-of-type(3),
        &:nth-of-type(5):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev:nth-of-type(4),
        &:nth-of-type(6):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev:nth-of-type(5),
        &:nth-of-type(7):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev:nth-of-type(6),
        &:nth-of-type(8):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev:nth-of-type(7),
        &:nth-of-type(9):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev:nth-of-type(8),
        &:nth-of-type(10):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev:nth-of-type(9),
        &:nth-of-type(1):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next:nth-of-type(2),
        &:nth-of-type(2):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next:nth-of-type(3),
        &:nth-of-type(3):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next:nth-of-type(4),
        &:nth-of-type(4):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next:nth-of-type(5),
        &:nth-of-type(5):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next:nth-of-type(6),
        &:nth-of-type(6):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next:nth-of-type(7),
        &:nth-of-type(7):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next:nth-of-type(8),
        &:nth-of-type(8):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next:nth-of-type(9),
        &:nth-of-type(9):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next:nth-of-type(10),
        &:nth-of-type(10):checked ~ .stream-grid-img-slider-section-wrap .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next:nth-of-type(11) {
          display: block;
          cursor: pointer;
        }
      }

      .stream-grid-img-slider-nav {
        width: 98%;
        margin: 0 auto;
        position: absolute;
        left: 1%;
        top: calc(50% - 32px);
        transform: translateY(-50%);
        z-index: 1;
      }

      .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap,
      .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap {
        width: 60px;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
      }

      .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap {
        left: 0;
      }

      .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap {
        right: 0;
      }

      .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next {
        display: none;
      }

      .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev {
        display: none;
      }

      .stream-grid-img-slider-nav .stream-grid-img-slider-nav-next-wrap .stream-grid-img-slider-nav-next svg {
        display: block;
        width: 100%;
        height: auto;
      }

      .stream-grid-img-slider-nav .stream-grid-img-slider-nav-prev-wrap .stream-grid-img-slider-nav-prev svg {
        display: block;
        width: 100%;
        height: auto;
      }

      .stream-grid-img-slider-custom-arrow-next {
        transform: scale(-1, 1);
      }

      .stream-grid-img-slider-section-wrap {
        position: relative;
      }

      .stream-grid-img-slide-wrapper {
        display: flex;
        transform: translateX(0%);
        transition: transform 0.5s ease 0s;
        position: relative;
      }

      .stream-grid-img-slide {
        position: relative;
        width: 100%;
        flex: 0 0 100%;
      }

      .stream-grid-img-slider-dots {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
      }

      .stream-grid-img-slider-dot {
        padding: 20px 0 30px;
        &:only-child {
          display: none;
        }

        &-inner {
          padding: 8px;
          background-color: #9ea2a9;
          border-radius: 20px;
          margin: 0 10px;
          display: block;
          font-size: 16px !important;
          line-height: 1.02 !important;
          cursor: pointer;
        }
      }
    }
    img {
      width: 100%;
      max-width: none;
      display: block;
    }

    video {
      display: block;
      margin: 0 auto;
      height: auto;
      max-width: 100%;
    }
  }
  .stream-grid-img-slider-youtube__video {
    position: relative;
    padding-bottom: 54%;
    overflow: hidden;
    width: 100%;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 1;
    }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .stream-grid-content {
    padding: 0 40px;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: calc(100% - var(--imgcolwidth));

    .stream-grid-content-wrap {
      width: 100%;
    }

    .stream-grid-title {
      // font-size: 30px !important;
      margin-bottom: 20px;
      font-weight: 600;
      // line-height: 1.2;
    }

    // .stream-grid-text {
    //   font-size: 18px !important;
    //   line-height: 1.2;
    // }
  }
}

.small-pc {
  &.tablet {
    .stream-grid {
      .stream-grid-content {
        padding: 0 20px;

        .stream-grid-title {
          // font-size: 21px !important;
          margin-bottom: 8px;
        }

        // .stream-grid-text {
        // font-size: 14px !important;
        // }
      }
    }

    &.mobile {
      .stream-grid {
        flex-direction: column;

        .stream-grid-img,
        .stream-grid-content {
          width: 100%;
        }

        .stream-grid-img {
          margin: auto;
          padding: 0;
          order: -1;
        }

        .stream-grid-content {
          -webkit-order: 2;
          -ms-order: 2;
          order: 2;
          padding: 20px;

          .stream-grid-title {
            text-align: var(--titletextalignmobile) !important;
          }
          .stream-grid-text {
            text-align: var(--descriptiontextalignmobile) !important;
          }
        }
      }
    }
  }

  // .stream-grid {
  //   .stream-grid-content .stream-grid-title {
  //     font-size: 24px !important;
  //   }
  // }
}
</style>
