var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stream-icons",style:({ background: _vm.params.background, justifyContent: _vm.params.colsAlign })},[_vm._l((_vm.params.innerElements),function(icon,elementIndex){return _c('div',{key:elementIndex,class:'stream-icon' + ((_vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex) ? ' constructor-stream-icon--active' : ''),on:{"click":function($event){$event.stopPropagation();return _vm.setActiveElement({componentIndex: _vm.componentIndex, elementIndex})}}},[_c('div',{staticClass:"stream-icon-inner"},[_c('div',{staticClass:"stream-icon-img"},[_c('img',{style:({borderRadius:_vm.params.borderRadius+'px'}),attrs:{"src":_vm.domain + icon.props.src,"alt":""}})]),_c('div',{class:['stream-icon-title', 'stream-text-'+ _vm.params.titleFontSize],style:({
          textAlign: _vm.params.textAlign,
          '--textalignmobile': _vm.params.textAlignMobile,
          color: _vm.params.titleColor,
          fontFamily: _vm.params.titleFontFamily,
          fontWeight: _vm.params.titleFontWeight,
        }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(icon.props.titleContent))}}),_c('div',{class:['stream-icon-description', 'stream-text-'+ _vm.params.descriptionFontSize],style:({
          textAlign: _vm.params.textAlign,
          '--textalignmobile': _vm.params.textAlignMobile,
          color: _vm.params.descriptionColor,
          fontFamily: _vm.params.descriptionFontFamily,
          fontWeight: _vm.params.descriptionFontWeight,
        }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(icon.props.descriptionContent))}})]),(elementIndex === _vm.activeElement)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeElement({componentIndex: _vm.componentIndex, elementIndex})}}}):_vm._e()],1)}),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }