<template>
  <div class="stream-standard-description">
    <div
      class="stream-standatd-description-body"
      v-html="handleNewLine(params.bodyText)"
    ></div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "StandardProductDescriptionModule",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.stream-standard-description {
  max-width: 1000px;
  margin: auto;
  padding: 15px 0;
  margin-bottom: 12px;

  .stream-standard-text-body {
    font-family: "Amazon Ember", Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;

    br {
      content: "";
      display: block;
      margin-bottom: 14px;
    }
  }
}
.small-pc.tablet.mobile {
  .stream-standard-description {
    padding: 25px 0;
  }
}
</style>