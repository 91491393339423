<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.75 2H6.75C5.647 2 4.75 2.897 4.75 4V20C4.75 21.103 5.647 22 6.75 22H16.75C17.853 22 18.75 21.103 18.75 20V4C18.75 2.897 17.853 2 16.75 2ZM6.75 20V4H16.75L16.752 20H6.75Z" fill="#8D9CA8"/>
    <path d="M11.75 19C12.3023 19 12.75 18.5523 12.75 18C12.75 17.4477 12.3023 17 11.75 17C11.1977 17 10.75 17.4477 10.75 18C10.75 18.5523 11.1977 19 11.75 19Z" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "Mobile",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
