<template>
  <div class="stream-bubbles">
    <input
      v-for="(bubble, elementIndex) in params.innerElements"
      :key="elementIndex"
      class="stream-bubbles-dot-indicator"
      type="radio"
      :name="'stream-bubbles-' + componentIndex"
      :id="'stream-bubbles-' + componentIndex + '-dot-' + elementIndex"
      :checked="elementIndex === 0"
    />
    <input
      class="stream-bubbles-dot-indicator stream-bubbles-close"
      type="radio"
      :name="'stream-bubbles-' + componentIndex"
      :id="'stream-bubbles-' + componentIndex + '-dot-close'"
    />
    <div class="stream-bubbles-img">
      <img :src="domain + params.src" alt="" />
      <div class="stream-bubbles-dots">
        <label
          v-for="(bubble, elementIndex) in params.innerElements"
          :key="elementIndex"
          :class="'stream-bubbles-dot' + ((activeElement === elementIndex && activeComponent === componentIndex) ? ' stream-bubbles-dot--active' : '')"
          :style="{
            left: bubble.props.left + '%',
            top: bubble.props.top + '%',
            background:params.bubbleBackground,
            color: params.bubbleColor,
            '--activebg': params.bubbleActiveBg,
            '--activecolor': params.bubbleActiveColor,
          }"
          :for="'stream-bubbles-' + componentIndex + '-dot-' + elementIndex"
          @click.stop.prevent="setActiveElement({ componentIndex, elementIndex })"
        >
          <RemoveItem
            v-if="activeElement === elementIndex && activeComponent === componentIndex && params.innerElements.length > 1"
            @click.native="removeElement({ componentIndex, elementIndex })"
          />
        </label>
      </div>
      <div class="stream-bubbles-items">
        <div
          v-for="(bubble, elementIndex) in params.innerElements"
          :key="elementIndex"
          :class="['stream-bubbles-item', 'stream-'+bubble.props.bubbleContentPosition ,(activeElement === elementIndex && activeComponent === componentIndex) ? 'stream-bubbles-item--active' : '']"
          :style="{background: params.background}"
          @click.stop="setActiveElement({ componentIndex, elementIndex })"
        >
          <div
            :class="[
              'stream-bubbles-title',
              'stream-text-' + params.titleFontSize,
            ]"
            :style="{
              textAlign: bubble.props.textAlign,
              '--textalignmobile': bubble.props.textAlignMobile,
              color: params.titleColor,
              fontFamily: params.titleFontFamily,
              fontWeight: params.titleFontWeight,
            }"
            v-html="handleNewLine(bubble.props.titleContent)"
          ></div>
          <div
            :class="[
              'stream-bubbles-description',
              'stream-text-' + params.descriptionFontSize,
            ]"
            :style="{
              textAlign: bubble.props.textAlign,
              '--textalignmobile': bubble.props.textAlignMobile,
              color: params.descriptionColor,
              fontFamily: params.descriptionFontFamily,
              fontWeight: params.descriptionFontWeight,
            }"
            v-html="handleNewLine(bubble.props.descriptionContent)"
          ></div>
          <label
            class="stream-bubbles-item-close"
            :for="'stream-bubbles-' + componentIndex + '-dot-close'"
            :style="{
              '--activecolor': params.closeBubbleColor,
              '--activebg': params.closeBubbleBackground,
            }"
            @click.stop.prevent="setActiveElement({ componentIndex, elementIndex:null })"

          ></label>
        </div>
      </div>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "Bubbles",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};

</script>

<style lang="scss" scoped>

.stream-bubbles-dot--active {
  box-shadow: 0 0 1px 1px #f500ed;
  background: var(--activebg) !important;
  color: var(--activecolor) !important;
}

.stream-bubbles {
  padding: 0;
  position: relative;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;

  &-close:checked ~ .stream-bubbles-item {
    display: none;
  }

  &-item-close {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    display: block;
    font-size: 18px !important;
    padding: 0.5em;
    line-height: 1.02 !important;
    display: inline-block;
    cursor: pointer;
    transform: translate(50%, -50%) rotate(45deg);
    width: 1.5em;
    height: 1.5em;
    background: var(--activebg) !important;
    color: var(--activecolor) !important;

    &::before,
    &::after {
      content: "";
      transform: translate(-50%, -50%);
      background: currentColor;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &::before {
      height: 2px;
      width: 1em;
    }

    &::after {
      width: 2px;
      height: 1em;
    }
  }

  &-dot-indicator {
    display: none !important;

    // &:nth-of-type(1):checked ~ .stream-bubbles-img .stream-bubbles-item:nth-child(1),
    // &:nth-of-type(2):checked ~ .stream-bubbles-img .stream-bubbles-item:nth-child(2),
    // &:nth-of-type(3):checked ~ .stream-bubbles-img .stream-bubbles-item:nth-child(3),
    // &:nth-of-type(4):checked ~ .stream-bubbles-img .stream-bubbles-item:nth-child(4),
    // &:nth-of-type(5):checked ~ .stream-bubbles-img .stream-bubbles-item:nth-child(5),
    // &:nth-of-type(6):checked ~ .stream-bubbles-img .stream-bubbles-item:nth-child(6),
    // &:nth-of-type(7):checked ~ .stream-bubbles-img .stream-bubbles-item:nth-child(7),
    // &:nth-of-type(8):checked ~ .stream-bubbles-img .stream-bubbles-item:nth-child(8),
    // &:nth-of-type(9):checked ~ .stream-bubbles-img .stream-bubbles-item:nth-child(9),
    // &:nth-of-type(10):checked ~ .stream-bubbles-img .stream-bubbles-item:nth-child(10) {
    //   display: block;
    // }

    // &:nth-of-type(1):checked ~ .stream-bubbles-img .stream-bubbles-dot:nth-child(1),
    // &:nth-of-type(2):checked ~ .stream-bubbles-img .stream-bubbles-dot:nth-child(2),
    // &:nth-of-type(3):checked ~ .stream-bubbles-img .stream-bubbles-dot:nth-child(3),
    // &:nth-of-type(4):checked ~ .stream-bubbles-img .stream-bubbles-dot:nth-child(4),
    // &:nth-of-type(5):checked ~ .stream-bubbles-img .stream-bubbles-dot:nth-child(5),
    // &:nth-of-type(6):checked ~ .stream-bubbles-img .stream-bubbles-dot:nth-child(6),
    // &:nth-of-type(7):checked ~ .stream-bubbles-img .stream-bubbles-dot:nth-child(7),
    // &:nth-of-type(8):checked ~ .stream-bubbles-img .stream-bubbles-dot:nth-child(8),
    // &:nth-of-type(9):checked ~ .stream-bubbles-img .stream-bubbles-dot:nth-child(9),
    // &:nth-of-type(10):checked ~ .stream-bubbles-img .stream-bubbles-dot:nth-child(10) {
    .stream-bubbles-dot--active {
      background: var(--activebg) !important;
      color: var(--activecolor) !important;
    }
  }

  &-dot {
    color: #5198d0;
    border-radius: 50%;
    display: block;
    font-size: 18px !important;
    padding: 0.5em;
    line-height: 1.02 !important;
    cursor: pointer;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 2.5em;
    height: 2.5em;

    &::before,
    &::after {
      content: "";
      transform: translate(-50%, -50%);
      background: currentColor;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &::before {
      width: 2px;
      height: 1em;
    }

    &::after {
      height: 2px;
      width: 1em;
    }
  }

  &-img {
    img {
      display: block;
      margin: 0 auto;
      height: auto;
      max-width: 100%;
    }
  }

  &-item {
    display: none;
    position: absolute;
    padding: 30px;
    width: 50%;
    max-width: 450px !important;
    border-radius: 8px;
    z-index: 1;

    &.stream-left-bottom {
      left: 60px;
      bottom: 30px;
    }

    &.stream-right-bottom {
      right: 60px;
      bottom: 30px;
    }

    &.stream-left-top {
      left: 60px;
      top: 30px;
    }

    &.stream-right-top {
      right: 60px;
      top: 30px;
    }

    &--active {
      display: block;
    }

    &-title {
      padding: 0;
      width: 100%;
    }

    &-description {
      padding: 0;
      margin: 0 auto;
      width: 100%;
    }
  }
}

#stream-container.small-pc.tablet .stream-bubbles-item {
  width: 92%;
  bottom: 0;
  left: 4%;
  top: auto;
  bottom: 4%;
  padding: 16px;

  .stream-bubbles-title,
  .stream-bubbles-description {
    text-align: var(--textalignmobile) !important;
  }
}

</style>
