<template>
  <div
    :class="activeType === 'mobile' ? 'ra-b6' : 'ra-b7'"
    :style="{ textAlign: params.textAlign }"
  >
    <span class="ra-d9">
      <span
        :class="'stream-text-' + params.fontSize"
        v-html="handleNewLine(params.content)"
      ></span>
    </span>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "OzonDescription",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement", "removeComponent"]),
  },
};
</script>
