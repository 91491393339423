<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">  
    <path d="M14.9998 12L13.5898 10.59L16.1698 8L11.8348 8L11.8348 6L16.1698 6L13.5898 3.41L14.9998 2L19.9998 7L14.9998 12Z" fill="white"/>
    <path d="M11.9998 20C8.68605 20 5.99976 17.3137 5.99976 14C5.99976 10.6863 8.68605 8 11.9998 8V6C7.58148 6 3.99976 9.58172 3.99976 14C3.99976 18.4183 7.58148 22 11.9998 22V20Z" fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: "Redo",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
