<template>
  <div v-if="fields.length">
    <div v-for="(field, fieldIndex) in fields" :key="fieldIndex">
      <UploadImg
        v-model="fields[fieldIndex]"
        :index="fieldIndex"
        :label="label + '-' + (fieldIndex + 1)"
        name=""
        @imgUpdate="imgUpdate"
      />
    </div>
  </div>
</template>

<script>
import UploadImg from "../UploadImg/UploadImg.vue";

export default {
  name: "ImgArray",
  components: {
    UploadImg,
  },
  props: {
    label: {
      type: String,
    },
    index: {
      type: Number,
    },
    currentValue: {
      type: Array,
    },
    activeElement: {
      type: Number,
    },
    colsQn: {
      type: Number,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      fields: [],
    };
  },
  methods: {
    createFields() {
      const fields = [];
      for (let i = 0; i <= this.colsQn; i++) {
        const currentElem = this.currentValue[i] ? this.currentValue[i] : "";

        fields.push(currentElem);
      }
      this.fields = fields;
      this.$emit("imgArrayUpdate", {
        index: this.index,
        value: this.fields,
      });

    },
    imgUpdate(response) {
      this.fields[response.index] = response.src;
      this.$emit("imgArrayUpdate", {
        index: this.index,
        value: this.fields,
      });
      this.$forceUpdate();
    },
  },
  watch: {
    activeElement: function () {
      this.createFields();
    },
    colsQn: function () {
      this.createFields();
    },
  },
  mounted() {
    this.createFields();
  },
};
</script>

<style lang="scss" scoped>
</style>