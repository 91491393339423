var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.activeType === 'mobile' ? 'ra-b6' : 'ra-b7',style:({ textAlign: _vm.params.textAlign })},[_c('div',{class:['ra-c', _vm.activeType === 'mobile' ? 'ra-c0' : 'ra-c8']},_vm._l((_vm.params.innerElements),function(col,elementIndex){return _c('div',{key:elementIndex,class:[
        _vm.activeType === 'mobile' ? null : 'ra-d',
        _vm.activeType === 'mobile' ? 'ra-c1' : 'ra-d1',
        _vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex
          ? 'constructor-stream-col--active'
          : null,
      ],on:{"click":function($event){$event.stopPropagation();return _vm.setActiveElement({ componentIndex: _vm.componentIndex, elementIndex })}}},[_c('div',{staticClass:"ra-e7 ra-c2"},[_c('div',[_c('img',{staticClass:"ra-e8 ui-n6",attrs:{"loading":"lazy","src":_vm.domain +
              (_vm.activeType === 'mobile' ? col.props.srcMob : col.props.src)}})])]),_c('div',[_c('span',{class:['ra-d5', 'ra-d6', 'ra-d9', 'ra-e2']},[_c('span',{class:'stream-text-' + _vm.params.titleFontSize,domProps:{"innerHTML":_vm._s(_vm.handleNewLine(col.props.titleContent))}})]),_c('span',{class:['ra-d9']},[_c('span',{class:'stream-text-' + _vm.params.descriptionFontSize,domProps:{"innerHTML":_vm._s(_vm.handleNewLine(col.props.descriptionContent))}})])]),(
          _vm.activeElement === elementIndex &&
          _vm.activeComponent === _vm.componentIndex &&
          _vm.params.innerElements.length > 1
        )?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeElement({ componentIndex: _vm.componentIndex, elementIndex })}}}):_vm._e()],1)}),0),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }