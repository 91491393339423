var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table",style:({ background: _vm.params.background })},[_vm._l((_vm.params.innerElements),function(row,elementIndex){return _c('div',{key:elementIndex,class:[
      'table__row',
      _vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex
        ? ' table__row--active'
        : '',
    ],on:{"click":function($event){$event.stopPropagation();return _vm.setActiveElement({ componentIndex: _vm.componentIndex, elementIndex })}}},[_vm._l((_vm.addLimitToCell(row.props.rowContent)),function(cell,cellIndex){return _c('div',{key:cellIndex,staticClass:"table__cell",style:({
        background: row.props.rowBackgrounds[cellIndex] !== '' ? row.props.rowBackgrounds[cellIndex] : _vm.params.background,
        border: `${_vm.params.borderWidth}px solid ${_vm.params.borderColor}`,
        justifyContent: _vm.params.justifyContent,
      })},[(
          row.props.rowImages[cellIndex] &&
          row.props.rowImages[cellIndex] !== ''
        )?_c('div',{staticClass:"table__cell__img"},[_c('img',{attrs:{"src":_vm.domain + row.props.rowImages[cellIndex],"alt":""}})]):_vm._e(),(cell !== '')?_c('div',{staticClass:"table__cell__text",style:({
          color: row.props.rowColors[cellIndex] !== '' ? row.props.rowColors[cellIndex] : _vm.params.color,
          fontFamily: _vm.params.fontFamily,
          fontWeight: _vm.params.fontWeight,
          textAlign: _vm.params.textAlign
        }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(cell))}}):_vm._e()])}),(_vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex && _vm.params.innerElements.length > 1)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeElement({componentIndex: _vm.componentIndex, elementIndex})}}}):_vm._e()],2)}),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }