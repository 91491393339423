<template>
  <svg @click="openPreview(epackID)" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" fill="white" stroke="#D6DEE2"/>
    <rect x="10" y="19" width="20" height="2" rx="1" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "Minus",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
