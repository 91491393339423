<template>
  <div class="constructor__textarea">
    <div class="constructor__textarea__title">
      {{label}}
    </div>
    <textarea
      class="constructor__textarea__input"
      :value="startValue"
      v-debounce:500ms="updateInput"
    >
    </textarea>
  </div>
</template>

<script>

export default {
  name: "Textarea",
  props: {
    startValue: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    updateInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.constructor__textarea {
  margin-bottom: 12px;

  &__title {
    font-size: 14px;
    line-height: 1.29;
    margin-bottom: 12px;
  }

  &__input {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 1.29;
    border: 1px solid #d6dee2;
    background: #ffffff;
    border-radius: 6px;
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
}
</style>
