var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stream-button",style:({background: _vm.params.background})},[_c('a',{staticClass:"stream-button-button",class:`stream-text-${_vm.params.fontSize}`,style:({
    fontFamily: _vm.params.fontFamily,
    fontWeight: _vm.params.fontWeight,
    background: _vm.params.buttonBackground,
    color: _vm.params.color,
    borderRadius: _vm.params.borderRadius,
  }),attrs:{"href":_vm.domain + _vm.params.src,"target":"_blank","download":""}},[_vm._v(" "+_vm._s(_vm.params.content)+" ")]),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }