<template>
  <div class="constructor-wrap">
    <div v-if="synkProcess" class="constructor__synk-overlay"></div>
    <div class="font-styles"></div>

    <div class="constructor__wrap">
      <div class="constructor__panel-left">
        <StaticPanel
          v-if="currentTemplate === 'mvideo_template' && (currentLocale === 'ru' || currentLocale === 'ru_ru')"
          title="Cover"
        >
          <CoverPanel/>
        </StaticPanel>
        <StaticPanel title="Components">
          <LayersPanel />
        </StaticPanel>
        <StaticPanel title="Add component">
          <AddComponentPanel />
        </StaticPanel>
      </div>
      <div class="constructor__workarea">
        <Workarea>
          <div class="stream-container__wrapper">
            <div
              v-if="localComponents && localComponents[currentLocale] && localComponents[currentLocale][currentTemplate]"
              id="stream-container"
              :class="[containerSize, currentLang === 'ar' ? 'stream-ar' : null]"
            >
              <!-- <draggable v-model="localComponents" @start="activeComponent = null"> -->
              <component
                v-for="(component, componentIndex) in localComponents[currentLocale][currentTemplate]"
                :is="component.component"
                :key="componentIndex"
                :componentIndex="componentIndex"
                :params="component.props"
                class="constructor-item"
                :class="activeComponent === componentIndex ? ' constructor-item--active' : null"
                :handleNewLine="handleNewLine"
                @click.native="setActiveElement({componentIndex: componentIndex,elementIndex: null})"
              />
              <!-- </draggable> -->
            </div>
          </div>
        </Workarea>
      </div>
      <div class="constructor__panel-right">
        <StaticPanel
          v-if="activeComponent !== null"
          :title="'Edit component ' + localComponents[currentLocale][currentTemplate][activeComponent].component"
          :noHiddable="true"
          :headerClick="setActiveElement"
          :activeComponent="activeComponent"
        >
          <EditorPanel />
        </StaticPanel>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";

import Title from "./collections/templates/master/Title.vue";
import Description from "./collections/templates/master/Description.vue";
import Disclaimer from "./collections/templates/master/Disclaimer.vue";
import Spacer from "./collections/templates/master/Spacer.vue";
import Picture from "./collections/templates/master/Picture.vue";
import Video from "./collections/templates/master/Video.vue";
import Grid from "./collections/templates/master/Grid.vue";
import Icons from "./collections/templates/master/Icons.vue";
import Youtube from "./collections/templates/master/Youtube.vue";
import Specs from "./collections/templates/master/Specs.vue";
import Table from "./collections/templates/master/Table.vue";
import Slider from "./collections/templates/master/Slider.vue";
import Col4 from "./collections/templates/master/Col4.vue";
import Col3 from "./collections/templates/master/Col3.vue";
import Col2 from "./collections/templates/master/Col2.vue";
import Bubbles from "./collections/templates/master/Bubbles.vue";
import Accordion from "./collections/templates/master/Accordion.vue";
import ARBanner from "./collections/templates/master/ARBanner.vue";
import PictureWithText from "./collections/templates/master/PictureWithText.vue";
import VideoWithText from "./collections/templates/master/VideoWithText.vue";
import Button from "./collections/templates/master/Button.vue";
import Iframe from "./collections/templates/master/Iframe.vue";

import ImgPicture from "./collections/templates/img/ImgPicture.vue";

import EldAccordion from "./collections/templates/eld/EldAccordion.vue";
import EldTitle from "./collections/templates/eld/EldTitle.vue";
import EldDescription from "./collections/templates/eld/EldDescription.vue";
import EldDisclaimer from "./collections/templates/eld/EldDisclaimer.vue";
import EldSpacer from "./collections/templates/eld/EldSpacer.vue";
import EldPicture from "./collections/templates/eld/EldPicture.vue";
import EldVideo from "./collections/templates/eld/EldVideo.vue";
import EldGrid from "./collections/templates/eld/EldGrid.vue";
import EldIcons from "./collections/templates/eld/EldIcons.vue";
import EldYoutube from "./collections/templates/eld/EldYoutube.vue";
import EldSpecs from "./collections/templates/eld/EldSpecs.vue";
import EldTable from "./collections/templates/eld/EldTable.vue";
import EldSlider from "./collections/templates/eld/EldSlider.vue";
import EldCol4 from "./collections/templates/eld/EldCol4.vue";
import EldCol3 from "./collections/templates/eld/EldCol3.vue";
import EldCol2 from "./collections/templates/eld/EldCol2.vue";
import EldBubbles from "./collections/templates/eld/EldBubbles.vue";
import EldARBanner from "./collections/templates/eld/EldARBanner.vue";
import EldPictureWithText from "./collections/templates/eld/EldPictureWithText.vue";
import EldVideoWithText from "./collections/templates/eld/EldVideoWithText.vue";
import EldButton from "./collections/templates/eld/EldButton.vue";
import EldIframe from "./collections/templates/eld/EldIframe.vue";

import OzonTitle from "./collections/templates/ozon/OzonTitle.vue";
import OzonDescription from "./collections/templates/ozon/OzonDescription.vue";
import OzonDisclaimer from "./collections/templates/ozon/OzonDisclaimer.vue";
import OzonPicture from "./collections/templates/ozon/OzonPicture.vue";
import OzonGrid from "./collections/templates/ozon/OzonGrid.vue";
import OzonIcons from "./collections/templates/ozon/OzonIcons.vue";
import OzonCol2 from "./collections/templates/ozon/OzonCol2.vue";
import OzonCol3 from "./collections/templates/ozon/OzonCol3.vue";
import OzonCol4 from "./collections/templates/ozon/OzonCol4.vue";
import OzonYoutube from "./collections/templates/ozon/OzonYoutube.vue";
import OzonVideo from "./collections/templates/ozon/OzonVideo.vue";
import OzonSpecs from "./collections/templates/ozon/OzonSpecs.vue";
import OzonTitleWithDescription from "./collections/templates/ozon/OzonTitleWithDescription.vue";

import StandardImageSidebarModule from "./collections/templates/amazon/StandardImageSidebarModule.vue";
import StandardSingleSideImageModule from "./collections/templates/amazon/StandardSingleSideImageModule.vue";
import StandardSingleImageSpecsDetailModule from "./collections/templates/amazon/StandardSingleImageSpecsDetailModule.vue";
import StandardSingleImageHighlightsModule from "./collections/templates/amazon/StandardSingleImageHighlightsModule.vue";
import StandardThreeImageTextModule from "./collections/templates/amazon/StandardThreeImageTextModule.vue";
import StandardFourImageTextModule from "./collections/templates/amazon/StandardFourImageTextModule.vue";
import StandardFourImageTextQuadrantModule from "./collections/templates/amazon/StandardFourImageTextQuadrantModule.vue";
import StandardImageTextOverlayModule from "./collections/templates/amazon/StandardImageTextOverlayModule.vue";
import StandardTextModule from "./collections/templates/amazon/StandardTextModule.vue";
import StandardProductDescriptionModule from "./collections/templates/amazon/StandardProductDescriptionModule.vue";
import StandardTechSpecsModule from "./collections/templates/amazon/StandardTechSpecsModule.vue";
import StandardCompanyLogoModule from "./collections/templates/amazon/StandardCompanyLogoModule.vue";
import StandardHeaderImageTextModule from "./collections/templates/amazon/StandardHeaderImageTextModule.vue";

import EditorPanel from "./EditorPanel.vue";
import AddComponentPanel from "./AddComponentPanel.vue";
import CoverPanel from "./CoverPanel.vue";
import StaticPanel from "./StaticPanel.vue";

import LayersPanel from "./LayersPanel.vue";
import Workarea from "./Workarea.vue";

export default {
  name: "ConstructorData",
  components: {
    draggable,
    Title,
    Description,
    Disclaimer,
    Spacer,
    Picture,
    Grid,
    Icons,
    Youtube,
    Specs,
    Table,
    Slider,
    Video,
    Col4,
    Col3,
    Col2,
    Bubbles,
    Accordion,
    ARBanner,
    PictureWithText,
    VideoWithText,
    ImgPicture,
    Button,
    Iframe,
    EldAccordion,
    EldTitle,
    EldDescription,
    EldDisclaimer,
    EldSpacer,
    EldPicture,
    EldVideo,
    EldGrid,
    EldIcons,
    EldYoutube,
    EldSpecs,
    EldTable,
    EldSlider,
    EldCol4,
    EldCol3,
    EldCol2,
    EldBubbles,
    EldPictureWithText,
    EldVideoWithText,
    EldARBanner,
    EldButton,
    EldIframe,
    OzonTitle,
    OzonDescription,
    OzonDisclaimer,
    OzonPicture,
    OzonGrid,
    OzonIcons,
    OzonCol2,
    OzonCol3,
    OzonCol4,
    OzonYoutube,
    OzonVideo,
    OzonSpecs,
    OzonTitleWithDescription,
    StandardImageSidebarModule,
    StandardSingleSideImageModule,
    StandardSingleImageSpecsDetailModule,
    StandardSingleImageHighlightsModule,
    StandardThreeImageTextModule,
    StandardFourImageTextModule,
    StandardFourImageTextQuadrantModule,
    StandardImageTextOverlayModule,
    StandardTextModule,
    StandardProductDescriptionModule,
    StandardTechSpecsModule,
    StandardCompanyLogoModule,
    StandardHeaderImageTextModule,
    EditorPanel,
    AddComponentPanel,
    LayersPanel,
    StaticPanel,
    CoverPanel,
    Workarea,
  },
  data() {
    return {
      isAddComponentPopupShow: false,
      isLayersShow: false,
    };
  },
  computed: {
    ...mapState({
      sectionsParams: (state) => state.constructorData.sectionsParams,
      activeComponent: (state) => state.constructorData.activeComponent,
      fontsSources: (state) => state.constructorData.fontsSources,
      usedFonts: (state) => state.constructorData.epackData.usedFonts,
      currentLocale:(state) => state.constructorData.currentLocale,
      currentTemplate:(state) => state.constructorData.currentTemplate,
      localComponents: (state) => state.constructorData.epackData.components,
      activeType:(state) =>  state.constructorData.activeType,
      synkProcess:(state) => state.constructorData.synkProcess,
    }),

    containerSize: function () {
      let containerClasses = [];
      if (this.activeType === "laptop") {
        containerClasses = ["small-pc"];
      }
      if (this.activeType === "tablet") {
        containerClasses = ["small-pc", "tablet"];
      }
      if (this.activeType === "mobile") {
        containerClasses = ["small-pc", "tablet", "mobile"];
      }
      containerClasses = containerClasses.join(" ");
      return containerClasses;
    },
    currentLang() {
      const localeArr = this.currentLocale.split('_');
      const lang = localeArr.pop();
      return lang;
    }

  },

  methods: {
    ...mapActions("constructorData", ["setActiveComponent", "updateComponentsData","setActiveElement", "updateUsedFonts"]),

    componentPopupShow: function (variable, isShown) {
      this[variable] = !!isShown;
    },

    renderCustomFonts: function () {
      let style = document.createElement("style");
      style.innerHTML = ``;
      this.fontsSources.map((font) => {
        this.usedFonts.map((usedFont) => {
          if (font.fontFamily === usedFont.fontFamily && font.fontWeight === usedFont.fontWeight) {
            let htmlPart = `@font-face {
              font-family: ${font.fontFamily};
                  font-weight: ${font.fontWeight};
                  `;
            let urls = [];
            font.fonts.map((fontItem) => {
              urls.push(`url(${fontItem.url}) format('${fontItem.format}')`);
            });
            const urlString = urls.join(",");
            htmlPart += `src: ${urlString};`;
            htmlPart += `}`;
            style.innerHTML += htmlPart;
          }
        });
      });
      const parent = document.querySelector(".font-styles");
      if(parent !== null) {
        parent.innerHTML = "";
        parent.append(style);

      }
    },
     handleNewLine(str) {
      if(str) {
        return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
      } else {
        return str;
      };
    },
  },
  watch: {
    // fontsSources(){
    //   this.renderCustomFonts();
    // },
    usedFonts: function () {
      this.renderCustomFonts();
    },
    currentLocale() {
      this.updateUsedFonts();
      this.renderCustomFonts();
    },
    currentTemplate() {
      this.updateUsedFonts();
      this.renderCustomFonts();
    },
  },
  mounted() {
    this.updateUsedFonts();
    this.renderCustomFonts();
  },
};
</script>

<style lang="scss">
.constructor-wrap {
  position: relative;
  // padding: 20px 0;
  overflow: auto;
  height: 100%;
}

.constructor {
  &__synk-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: rgba(0, 0, 0, 0.8);
  }

  &__wrap {
    display: flex;
    height: 100%;
  }

  &__panel-left {
    width: 200px;
    border-right: 1px solid #e5e8ea;
    overflow: auto;
  }

  &__panel-right {
    width: 250px;
    border-left: 1px solid #e5e8ea;
    overflow: auto;
  }

  &__workarea {
    width: calc(100% - 450px);
    overflow: hidden;
    padding: 10px;
  }
}

.stream-container__wrapper {
  width: 1210px;
  padding: 5px;
  margin: auto;
  background: #ffffff;
}

#stream-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  transition: 0.3s;
  border: 1px solid #666666;

  * {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  &.stream-ar * {
    direction: rtl;
  }

  .stream-mobile-show {
    display: none !important;
  }

  &.small-pc {
    max-width: 992px;

    &.tablet {
      max-width: 768px;

      &.mobile {
        max-width: 375px;

        .stream-desktop-show {
          display: none !important;
        }

        .stream-mobile-show {
          display: block !important;
        }
      }
    }
  }

  .constructor-item {
    position: relative;
    margin-bottom: -1px;

    &--active {
      box-shadow: 0 0 1px 1px #377dff;
      z-index: 1;
    }
  }
}
</style>
