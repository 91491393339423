export default (array) => {
  const indexToDell = array.findIndex(elem => elem.id === 1);
  if(indexToDell !== -1) {
      array.splice(indexToDell,1);
  }
  array.sort((a, b) => {
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
      }
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
      }
      return 0;
  });

  array.forEach((i) => {
      if (i.id !== 1) {

          i.label = i.label ? i.label : i.name;
          i.children = array.filter((j) => j.parentId === i.id);
          if (!i.children.length) {
              delete i.children;
          }
      }
  });

  let parentIds = array.map((i) => i.id);
  const result = JSON.parse(
      JSON.stringify(array.filter((i) => !parentIds.includes(i.parentId)))
  );
  return result;
}