var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.activeType === 'mobile' ? 'ra-b6' : 'ra-b7',style:({ textAlign: _vm.params.textAlign })},[_c('div',{class:[
      'ra-c',
      _vm.activeType === 'mobile' ? 'ra-c0' : null,
      'ra-c8',
      _vm.activeType === 'mobile' ? 'ra-c9' : null,
    ]},_vm._l((_vm.params.innerElements),function(icon,elementIndex){return _c('div',{key:elementIndex,class:[
        'ra-d',
        _vm.activeType === 'mobile' ? 'ra-d4' : 'ra-d3',
        _vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex
          ? 'constructor-stream-icon--active'
          : null,
      ],on:{"click":function($event){$event.stopPropagation();return _vm.setActiveElement({ componentIndex: _vm.componentIndex, elementIndex })}}},[_c('div',{staticClass:"ra-e7 ra-c2"},[_c('div',[_c('img',{staticClass:"ra-e8 ui-n6",attrs:{"loading":"lazy","src":_vm.domain + icon.props.src}})])]),_c('div',[_c('span',{class:[
            'ra-d5',
            'ra-d6',
            'ra-d9',
            _vm.activeType === 'mobile' ? 'ra-e3' : 'ra-e3',
          ]},[_c('span',{class:'stream-text-' + _vm.params.titleFontSize,domProps:{"innerHTML":_vm._s(_vm.handleNewLine(icon.props.titleContent))}})]),_c('span',{class:['ra-d9', _vm.activeType === 'mobile' ? 'ra-e6' : 'ra-e4']},[_c('span',{class:'stream-text-' + _vm.params.descriptionFontSize,domProps:{"innerHTML":_vm._s(_vm.handleNewLine(icon.props.descriptionContent))}})])]),(
          _vm.activeElement === elementIndex &&
          _vm.activeComponent === _vm.componentIndex &&
          _vm.params.innerElements.length > 1
        )?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeElement({ componentIndex: _vm.componentIndex, elementIndex })}}}):_vm._e()],1)}),0),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }