<template>
  <div :class="'constructor-panel' + (isPanelShown ? ' constructor-panel--visible' : '')">
    <div class="constructor-panel__head" @click="headerClick !== undefined ? headerClick({componentIndex:activeComponent,elementIndex:null}) : toggleVisibility()">
      <div class="constructor-panel__title">
        {{ title }}
      </div>
      <div class="constructor-panel__nav" v-if="!noHiddable">
        <div class="constructor-panel__nav__icon">
          <va-icon name="entypo entypo-down-open"></va-icon>
        </div>
      </div>
    </div>
    <div v-if="isPanelShown" class="constructor-panel__body">
      <slot />
    </div>
  </div>
</template>


<script>
export default {
  name: "StaticPanel",
  props: {
    title: {
      type: String,
    },
    noHiddable: {
      type: Boolean,
      default: false,
    },
    activeComponent: {
      type: Number,
    },
    headerClick: {
      typeof: Function,
    },
  },
  data() {
    return {
      isPanelShown: this.noHiddable ? true : false,
    };
  },
  methods: {
    toggleVisibility: function () {
      if (!this.noHiddable) {
        this.isPanelShown = !this.isPanelShown;
      }
    },
  },
};
</script>

<style lang="scss" scouped>
.constructor-panel {
  &--visible {
    .constructor-panel__nav {
      transform: rotate(180deg);
    }
  }

  &__head {
    padding: 0.5rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: 550;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: #000000 !important;
  }

  &__nav {
    width: 10px;
    cursor: pointer;
  }

  &__body {
    padding: 0 0 1.25rem;
  }
}
</style>
