var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['ra-d8', _vm.activeType === 'mobile' ? 'ra-b6' : 'ra-b7']},[_vm._m(0),_c('div',{staticClass:"ui-b2 ui-e8"},[_c('table',{staticClass:"ui-b3"},[_vm._m(1),_c('tbody',_vm._l((_vm.params.innerElements),function(spec,elementIndex){return _c('tr',{key:elementIndex,class:[
            'ui-b7',
            _vm.activeElement === elementIndex &&
            _vm.activeComponent === _vm.componentIndex
              ? ' specs-list-item--active'
              : null,
          ],on:{"click":function($event){$event.stopPropagation();return _vm.setActiveElement({ componentIndex: _vm.componentIndex, elementIndex })}}},[(_vm.isTitle)?_c('td',{staticClass:"ui-e",domProps:{"innerHTML":_vm._s(_vm.handleNewLine(spec.props.titleContent))}}):_vm._e(),(_vm.isDescription)?_c('td',{staticClass:"ui-e",domProps:{"innerHTML":_vm._s(_vm.handleNewLine(spec.props.descriptionContent))}}):_vm._e(),(
              _vm.activeElement === elementIndex &&
              _vm.activeComponent === _vm.componentIndex &&
              _vm.params.innerElements.length > 1
            )?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeElement({ componentIndex: _vm.componentIndex, elementIndex })}}}):_vm._e()],1)}),0)])]),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ra-d7"},[_c('span',{staticClass:"ra-d9 ra-e2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"ui-e3"},[_c('tr',{staticClass:"ui-b7"},[_c('th',{staticClass:"ui-e0"}),_c('th',{staticClass:"ui-e0"})])])
}]

export { render, staticRenderFns }