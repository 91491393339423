<template>
  <div
    class="stream-slider"
    :style="{ background: params.background }"
  >
    <input
      v-for="(slide,elementIndex) in params.innerElements"
      :key="elementIndex"
      class="stream-slider-dot-indicator"
      type="radio"
      :name="'slider-nav-'+componentIndex"
      :id="'block-'+componentIndex+'-dot-'+elementIndex"
      :checked="elementIndex === 0"
    >
    <div :class="['stream-slider-section-wrap', 'stream-slider-section-wrap-'+params.contentPosition]">
      <div class="stream-slide-img-wrapper">
        <div class="stream-slide-wrapper">
          <div
            v-for="(slide,elementIndex) in params.innerElements"
            :key="elementIndex"
            :class="'stream-slide' + ((activeElement === elementIndex && activeComponent === componentIndex) ? ' stream-slide--active' : '')"
            :style="{ background: slide.props.background, alignItems: slide.props.alignItems}"
            @click.stop="setActiveElement({componentIndex, elementIndex})"
          >
            <div class="stream-slide-inner">
              <div v-if="slide.props.youtubeID" class="stream-slider-youtube__video">
                <iframe :src="`https://www.youtube.com/embed/${slide.props.youtubeID}?enablejsapi=1&rel=0&autoplay=0`" frameborder="0" :style="{borderRadius:params.borderRadius+'px'}"></iframe>
              </div>
              <div v-else-if="slide.props.videoSrc">
                  <video
                    :src="domain + slide.props.videoSrc" 
                    :poster="(params.poster !== undefined && params.poster.length) ? domain + params.poster : ''"
                    :controls="slide.props.controls == 'true' ? true : false"
                    playsinline
                    muted
                    :loop="slide.props.repeat == 'true' ? true : false"
                    :autoplay="slide.props.autoplay == 'true' ? true : false"
                    :style="{borderRadius:params.borderRadius+'px'}"
                  ></video>
              </div>
              <div v-else class="stream-slide-img">
                <div class="stream-slide-img-inner">
                  <template v-if="slide.props.srcMob !== ''">
                    <img :src="domain + slide.props.srcMob" alt="" class="stream-mobile-show" :style="{borderRadius:params.borderRadius+'px'}"/>
                    <img :src="domain + slide.props.src" alt="" class="stream-desktop-show" :style="{borderRadius:params.borderRadius+'px'}"/>
                  </template>
                  <template v-else>
                    <img :src="domain + slide.props.src" alt="" :style="{borderRadius:params.borderRadius+'px'}" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="stream-slider-nav">
          <div class="stream-slider-nav-prev-wrap">

            <label
              v-for="(slide,elementIndex) in params.innerElements"
              :key="elementIndex"
              class="stream-slider-nav-prev"
              :for="'block-'+componentIndex+'-dot-'+elementIndex"
            >
              <div
                class="stream-custom-arrow-prev"
                v-if="params.customArrow"
                v-html="params.customArrow"
              ></div>
              <svg v-else width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 0C46.5425 0 60 13.4575 60 30C60 46.5425 46.5425 60 30 60C13.4575 60 0 46.5425 0 30C0 13.4575 13.4575 0 30 0ZM20.7325 31.7675L33.2325 44.2675C33.72 44.755 34.36 45 35 45C35.64 45 36.2801 44.755 36.7676 44.2675C37.745 43.29 37.745 41.71 36.7676 40.7325L26.035 30L36.7675 19.2675C37.7449 18.29 37.7449 16.71 36.7675 15.7325C35.79 14.7551 34.21 14.7551 33.2325 15.7325L20.7325 28.2326C19.755 29.21 19.755 30.79 20.7325 31.7675Z" :fill="params.arrowsColor"/>
              </svg>
            </label>
          </div>
          <div class="stream-slider-nav-next-wrap">
            <label
              v-for="(slide,elementIndex) in params.innerElements"
              :key="elementIndex"
              class="stream-slider-nav-next"
              :for="'block-'+componentIndex+'-dot-'+elementIndex"
            >
              <div
                class="stream-custom-arrow-next"
                v-if="params.customArrow"
                v-html="params.customArrow"
              ></div>
              <svg v-else width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 0C13.4575 0 0 13.4575 0 30C0 46.5425 13.4575 60 30 60C46.5425 60 60 46.5425 60 30C60 13.4575 46.5425 0 30 0ZM39.2675 31.7675L26.7675 44.2675C26.28 44.755 25.64 45 25 45C24.36 45 23.7199 44.755 23.2324 44.2675C22.255 43.29 22.255 41.71 23.2324 40.7325L33.965 30L23.2325 19.2675C22.2551 18.29 22.2551 16.71 23.2325 15.7325C24.21 14.7551 25.79 14.7551 26.7675 15.7325L39.2675 28.2326C40.245 29.21 40.245 30.79 39.2675 31.7675Z" :fill="params.arrowsColor"/>
              </svg>
            </label>
          </div>
        </div>
      </div>
      <div class="stream-slide-wrapper stream-slide-content-wrapper">
        <div
          v-for="(slide,elementIndex) in params.innerElements"
          :key="elementIndex"
          :class="'stream-slide' + ((activeElement === elementIndex && activeComponent === componentIndex) ? ' stream-slide--active' : '')"
          :style="{ background: slide.props.background }"
          @click.stop="setActiveElement({componentIndex, elementIndex})"
        >
          <div class="stream-slide-inner">
            <div class="stream-slide-content">

              <div
                :class="['stream-slide-title', 'stream-text-'+ params.titleFontSize]"
                v-if="slide.props.titleContent"
                :style="{
                  textAlign: params.textAlign,
                  '--textalignmobile': params.textAlignMobile,
                  color: slide.props.titleColor,
                  fontFamily: params.titleFontFamily,
                  fontWeight: params.titleFontWeight,
                }"
                v-html="handleNewLine(slide.props.titleContent)"
              >
              </div>
              <div
                :class="['stream-slide-description', 'stream-text-'+ params.descriptionFontSize]"
                v-if="slide.props.descriptionContent"
                :style="{
                  textAlign: params.textAlign,
                  '--textalignmobile': params.textAlignMobile,
                  color: slide.props.descriptionColor,
                  fontFamily: params.descriptionFontFamily,
                  fontWeight: params.descriptionFontWeight,
                }"
                v-html="handleNewLine(slide.props.descriptionContent)"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stream-slide-wrapper stream-slide-disclaimer-wrapper">
        <div
          v-for="(slide,elementIndex) in params.innerElements"
          :key="elementIndex"
          :class="'stream-slide' + ((activeElement === elementIndex && activeComponent === componentIndex) ? ' stream-slide--active' : '')"
          :style="{ background: slide.props.background }"
          @click.stop="setActiveElement({componentIndex, elementIndex})"
        >
          <div :class="['stream-slide-inner', 'stream-slide-inner-'+params.contentPosition]">
            <div
              v-if="slide.props.disclaimerContent"
              :class="['stream-slide-disclaimer', 'stream-text-'+ params.disclaimerFontSize]"
              :style="{
                textAlign: params.textAlign,
                '--textalignmobile': params.textAlignMobile,
                color: slide.props.disclaimerColor,
                fontFamily: params.disclaimerFontFamily,
                fontWeight: params.disclaimerFontWeight,
              }"
              v-html="handleNewLine(slide.props.disclaimerContent)"
            >

            </div>
          </div>
          <RemoveItem
            v-if="activeElement === elementIndex && activeComponent === componentIndex && params.innerElements.length > 1"
            @click.native="removeElement({componentIndex, elementIndex})"
          />
        </div>
      </div>
    </div>
    <div class="stream-slider-dots">
      <label
        v-for="(slide,elementIndex) in params.innerElements"
        :key="elementIndex"
        class="stream-slider-dot"
        :for="'block-'+componentIndex+'-dot-'+elementIndex"
        :style="{background: params.dotsColor,color:params.activeDotColor}"
      ></label>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";

export default {
  name: "Slider",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement","removeComponent","removeElement",]),
  },
};
</script>

<style lang="scss">
.stream-slide--active {
  box-shadow: 0 0 1px 1px #f500ed;
}

.stream-slider {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;

  .stream-slider-dot-indicator {
    display: none !important;

    &:nth-of-type(1):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
      transform: translateX(0%);
    }

    &:nth-of-type(2):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
      transform: translateX(-100%);
    }

    &:nth-of-type(3):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
      transform: translateX(-200%);
    }

    &:nth-of-type(4):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
      transform: translateX(-300%);
    }

    &:nth-of-type(5):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
      transform: translateX(-400%);
    }

    &:nth-of-type(6):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
      transform: translateX(-500%);
    }

    &:nth-of-type(7):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
      transform: translateX(-600%);
    }

    &:nth-of-type(8):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
      transform: translateX(-700%);
    }

    &:nth-of-type(9):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
      transform: translateX(-800%);
    }

    &:nth-of-type(10):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
      transform: translateX(-900%);
    }

    &:nth-of-type(1):checked ~ .stream-slider-dots .stream-slider-dot:nth-of-type(1),
    &:nth-of-type(2):checked ~ .stream-slider-dots .stream-slider-dot:nth-of-type(2),
    &:nth-of-type(3):checked ~ .stream-slider-dots .stream-slider-dot:nth-of-type(3),
    &:nth-of-type(4):checked ~ .stream-slider-dots .stream-slider-dot:nth-of-type(4),
    &:nth-of-type(5):checked ~ .stream-slider-dots .stream-slider-dot:nth-of-type(5),
    &:nth-of-type(6):checked ~ .stream-slider-dots .stream-slider-dot:nth-of-type(6),
    &:nth-of-type(7):checked ~ .stream-slider-dots .stream-slider-dot:nth-of-type(7),
    &:nth-of-type(8):checked ~ .stream-slider-dots .stream-slider-dot:nth-of-type(8),
    &:nth-of-type(9):checked ~ .stream-slider-dots .stream-slider-dot:nth-of-type(9),
    &:nth-of-type(10):checked ~ .stream-slider-dots .stream-slider-dot:nth-of-type(10) {
      cursor: default;
      background: currentColor !important;
    }

    &:nth-of-type(2):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev:nth-of-type(1),
    &:nth-of-type(3):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev:nth-of-type(2),
    &:nth-of-type(4):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev:nth-of-type(3),
    &:nth-of-type(5):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev:nth-of-type(4),
    &:nth-of-type(6):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev:nth-of-type(5),
    &:nth-of-type(7):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev:nth-of-type(6),
    &:nth-of-type(8):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev:nth-of-type(7),
    &:nth-of-type(9):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev:nth-of-type(8),
    &:nth-of-type(10):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev:nth-of-type(9),
    &:nth-of-type(1):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next:nth-of-type(2),
    &:nth-of-type(2):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next:nth-of-type(3),
    &:nth-of-type(3):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next:nth-of-type(4),
    &:nth-of-type(4):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next:nth-of-type(5),
    &:nth-of-type(5):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next:nth-of-type(6),
    &:nth-of-type(6):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next:nth-of-type(7),
    &:nth-of-type(7):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next:nth-of-type(8),
    &:nth-of-type(8):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next:nth-of-type(9),
    &:nth-of-type(9):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next:nth-of-type(10),
    &:nth-of-type(10):checked ~ .stream-slider-section-wrap .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next:nth-of-type(11) {
      display: block;
      cursor: pointer;
    }
  }

  .stream-slider-nav {
    width: 98%;
    margin: 0 auto;
    position: absolute;
    left: 1%;
    top: 50%;
    transform: translateY(-50%);
  }

  .stream-slider-nav .stream-slider-nav-prev-wrap,
  .stream-slider-nav .stream-slider-nav-next-wrap {
    width: 60px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  }

  .stream-slider-nav .stream-slider-nav-prev-wrap {
    left: 0;
  }

  .stream-slider-nav .stream-slider-nav-next-wrap {
    right: 0;
  }

  .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next {
    display: none;
  }

  .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev {
    display: none;
  }

  .stream-slider-nav .stream-slider-nav-next-wrap .stream-slider-nav-next svg {
    display: block;
    width: 100%;
    height: auto;
  }

  .stream-slider-nav .stream-slider-nav-prev-wrap .stream-slider-nav-prev svg {
    display: block;
    width: 100%;
    height: auto;
  }

  .stream-custom-arrow-next {
    transform: scale(-1, 1);
  }

  .stream-slide-wrapper {
    display: flex;
    transform: translateX(0%);
    transition: transform 0.5s ease 0s;
    position: relative;
  }

  .stream-slider-section-wrap {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .stream-slide-img-wrapper {
    position: relative;
  }

  .stream-slide-img {
    video,
    img {
      display: block;
      margin: 0 auto;
      height: auto;
      max-width: 100%;
    }
  }

  .stream-slide-title {
    // font-size: 48px !important;
    // line-height: 1.2;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
  }

  .stream-slide-description {
    // font-size: 21px !important;
    // line-height: 1.2;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
  }

  .stream-slide-disclaimer-wrapper {
    order: 3;
  }

  .stream-slide-disclaimer {
    // font-size: 14px;
    // line-height: 1.2;
    padding: 20px 20px 30px;
    width: 100%;
  }

  .stream-slide-content {
    margin: 0 auto;
    width: 100%;

    & > div {
      padding-bottom: 30px;

      &:first-child {
        padding-top: 60px;
      }
    }
  }

  .stream-slider-dots {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
    padding: 20px 20px 30px;
  }

  .stream-slider-dot {
    background-color: #9ea2a9;
    border-radius: 20px;
    margin: 0 10px;
    display: block;
    font-size: 16px !important;
    line-height: 1.02 !important;
    padding: 8px;
    cursor: pointer;
  }

  .stream-slide {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    display: flex;
  }

  .stream-slide-inner {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    video {
      display: block;
      margin: 0 auto;
      height: auto;
      max-width: 100%;
    }
  }

  .stream-slider-section-wrap-top {
    .stream-slide-content-wrapper {
      order: -1;
    }
  }

  .stream-slider-section-wrap-bottom {
    .stream-slide-content-wrapper {
      order: 2;
    }
  }

  .stream-slider-youtube__video {
    position: relative;
    padding-bottom: 54%;
    overflow: hidden;
    width: 100%;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 1;
    }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.stream-ar {
  .stream-slider {
    .stream-slider-dot-indicator {
      &:nth-of-type(1):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
        transform: translateX(0%);
      }

      &:nth-of-type(2):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
        transform: translateX(100%);
      }

      &:nth-of-type(3):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
        transform: translateX(200%);
      }

      &:nth-of-type(4):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
        transform: translateX(300%);
      }

      &:nth-of-type(5):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
        transform: translateX(400%);
      }

      &:nth-of-type(6):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
        transform: translateX(500%);
      }

      &:nth-of-type(7):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
        transform: translateX(600%);
      }

      &:nth-of-type(8):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
        transform: translateX(700%);
      }

      &:nth-of-type(9):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
        transform: translateX(800%);
      }

      &:nth-of-type(10):checked ~ .stream-slider-section-wrap .stream-slide-wrapper {
        transform: translateX(900%);
      }
    }

    .stream-slider-nav .stream-slider-nav-prev-wrap {
      left: auto;
      right: 0;

      svg {
        transform: scale(-1, 1);
      }

      // .stream-custom-arrow-prev {
      //   transform: scale(-1, 1);
      // }
    }

    .stream-slider-nav .stream-slider-nav-next-wrap {
      right: auto;
      left: 0;

      svg {
        transform: scale(-1, 1);
      }

      // .stream-custom-arrow-next {
      //   transform: none;
      // }
    }
  }
}

.small-pc.tablet.mobile .stream-slider {
  width: 100%;
  font-size: 14px !important;

  .stream-slide-title {
    text-align: var(--textalignmobile) !important;
    //   font-size: 36px !important;
  }

  .stream-slide-description {
    text-align: var(--textalignmobile) !important;
    //   font-size: 21px !important;
  }

  .stream-slide-disclaimer {
    text-align: var(--textalignmobile) !important;
    //   font-size: 12px !important;
  }

  // .stream-slide-content {
  //   & > div {
  //     padding-bottom: 16px;

  //     &:first-child {
  //       padding-top: 60px;
  //     }
  //   }
}
</style>
