<template>
  <div 
    class="stream-image"
    :style="{
      background: params.background,
    }"
  >
    <template v-if="params.srcMob !== ''">
      <img :src="domain + params.srcMob" alt="" class="stream-mobile-show" :style="{borderRadius:params.borderRadius+'px'}" />
      <img :src="domain + params.src" alt="" class="stream-desktop-show" :style="{borderRadius:params.borderRadius+'px'}" />
    </template>
    <template v-else>
      <img :src="domain + params.src" alt="" :style="{borderRadius:params.borderRadius+'px'}" />
    </template>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex);"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from '../../../ui/RemoveItem.vue';

export default {
  name: "EldPicture",
  props: {
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
    }),
    
  },
   methods: {
    ...mapActions("constructorData", ["setActiveElement","removeComponent",]),
  },
};
</script>

<style lang="scss" scoped>
.stream-image {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  img {
    max-width: 100%;
    margin: 0 auto;
    height: auto;
    display: block;
  }
}
</style>
