<template>
  <div class="stream-standard-overlay-img">
    <div class="stream-standard-overlay-img-wrap">
      <img :src="domain + params.mainImage" :alt="params.mainImageAltText" />
    </div>
    <div
      class="stream-standard-overlay-content"
      :class="`stream-${params.overlayColorType}`"
    >
      <div class="stream-standard-overlay-headline">
        {{ params.headlineText }}
      </div>
      <div
        class="stream-standard-overlay-body"
        v-html="handleNewLine(params.bodyText)"
      ></div>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "StandardImageTextOverlayModule",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.stream-standard-overlay-img {
  max-width: 970px;
  margin: auto;
  margin-bottom: 12px;
  padding-bottom: 12px;

  .stream-standard-overlay-img-wrap {
    padding-bottom: 31%;
    position: relative;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .stream-standard-overlay-content {
    width: 250px;
    position: absolute;
    right: 50px;
    padding: 15px;
    top: 50%;
    transform: translateY(-50%);

    &.stream-dark {
      color: #ffffff;
      background: rgba(0, 0, 0, 0.7);
    }

    &.stream-light {
      color: #000000;
      background: rgba(255, 255, 255, 0.7);
    }

    .stream-standard-overlay-headline {
      font-family: "Amazon Ember", Arial, sans-serif;
      font-weight: bold;
      font-size: 17px;
      line-height: 1.255;
    }

    .stream-standard-overlay-body {
      margin: 0 0 14px 0;
      font-size: 14px;
      line-height: 20px;

      br {
        content: "";
        display: block;
        margin-bottom: 14px;
      }
    }
  }
}

.small-pc.tablet.mobile {
  .stream-standard-overlay-img {
    .stream-standard-overlay-img-wrap {
      margin-bottom: 4px;
    }
    .stream-standard-overlay-content {
      position: static;
      transform: none;
      padding: 0;
      background: none;
      width: 100%;
      color: #000000;
    }

    .stream-standard-overlay-headline {
      font-size: 18px;
    }
  }
}
</style>
