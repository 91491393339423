<template>
  <div
    class="stream-cols-2"
    :style="{ background: params.background, justifyContent: params.colsAlign }"
  >
    <div
      v-for="(icon, elementIndex) in params.innerElements"
      class="stream-col"
      :class="[activeElement === elementIndex && activeComponent === componentIndex ? ' stream-col--active' : null]"
      :key="elementIndex"
      @click.stop="setActiveElement({ componentIndex, elementIndex })"
    >
      <div class="stream-col-inner">
        <div class="stream-col-img">
          <div v-if="icon.props.youtubeID" class="stream-col-img-inner">
            <iframe
              :src="`https://www.youtube.com/embed/${icon.props.youtubeID}?enablejsapi=1&rel=0&autoplay=0`"
              frameborder="0"
              :style="{borderRadius:params.borderRadius+'px'}"
            ></iframe>
          </div>
          <template v-else-if="icon.props.videoSrc">
            <video
              :src="domain + icon.props.videoSrc"
              playsinline
              muted
              :loop="icon.props.repeat == 'true' ? true : false"
              :autoplay="icon.props.autoplay == 'true' ? true : false"
              :controls="icon.props.controls == 'true' ? true : false"
              :style="{borderRadius:params.borderRadius+'px'}"
            ></video>
          </template>
          <template v-else>
            <img :src="domain + icon.props.src" alt="" :style="{borderRadius:params.borderRadius+'px',width: imgWidth}"/>
          </template>
        </div>
        <div
          :class="['stream-col-title', 'stream-text-' + params.titleFontSize]"
          :style="{
            textAlign: params.textAlign,
            '--textalignmobile': params.textAlignMobile,
            color: params.titleColor,
            fontFamily: params.titleFontFamily,
            fontWeight: params.titleFontWeight,
          }"
          v-html="handleNewLine(icon.props.titleContent)"
        ></div>
        <div
          :class="[
            'stream-col-description',
            'stream-text-' + params.descriptionFontSize,
          ]"
          :style="{
            textAlign: params.textAlign,
            '--textalignmobile': params.textAlignMobile,
            color: params.descriptionColor,
            fontFamily: params.descriptionFontFamily,
            fontWeight: params.descriptionFontWeight,
          }"
          v-html="handleNewLine(icon.props.descriptionContent)"
        ></div>
      </div>
      <RemoveItem
        v-if="
          activeElement === elementIndex &&
          activeComponent === componentIndex &&
          params.innerElements.length > 1
        "
        @click.native="removeElement({ componentIndex, elementIndex })"
      />
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "Col2",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
    imgWidth() {
      return this.params.imgWidth === "" ? 'auto' : this.params.imgWidth+'px'
    },
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.stream-col--active {
  box-shadow: 0 0 1px 1px #f500ed;
}

.stream-cols-2 {
  padding: 32px 24px;
  text-align: center;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;

  .stream-col {
    width: 50%;
    position: relative;
  }

  .stream-col-inner {
    height: 100%;
    padding: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;

    .stream-col-img {
      margin-bottom: 20px;

      img {
        display: block;
        margin: 0 auto;
        height: auto;
        max-width: 100%;
      }

      video {
        display: block;
        margin: 0 auto;
        height: auto;
        max-width: 100%;
      }

      .stream-col-img-inner {
        position: relative;
        padding-bottom: 54%;
        overflow: hidden;
        width: 100%;

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          z-index: 1;
        }

        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .stream-col-title {
      text-align: center;
      // font-size: 24px !important;
      // line-height: 1.2;
      padding: 0;
      margin: 0 auto 8px;
      width: 100%;
    }

    .stream-col-description {
      text-align: center;
      // font-size: 16px !important;
      // line-height: 1.2;
      padding: 0;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.small-pc.tablet.mobile .stream-cols-2 {
  padding: 20px 4px;
}

.small-pc.tablet.mobile .stream-col {
  width: 100%;
  // font-size: 14px !important;

  .stream-col-title {
    // font-size: 18px !important;
    text-align: var(--textalignmobile) !important;
  }

  .stream-col-description {
    // font-size: 14px !important;
    text-align: var(--textalignmobile) !important;
  }
}
</style>
