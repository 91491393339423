<template>
  <div :class="['ra-d8', activeType === 'mobile' ? 'ra-b6' : 'ra-b7']">
    <div class="ra-d7">
      <span class="ra-d9 ra-e2"> </span>
    </div>
    <div class="ui-b2 ui-e8">
      <table class="ui-b3">
        <thead class="ui-e3">
          <tr class="ui-b7">
            <th class="ui-e0"></th>
            <th class="ui-e0"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="[
              'ui-b7',
              activeElement === elementIndex &&
              activeComponent === componentIndex
                ? ' specs-list-item--active'
                : null,
            ]"
            v-for="(spec, elementIndex) in params.innerElements"
            :key="elementIndex"
            @click.stop="setActiveElement({ componentIndex, elementIndex })"
          >
            <td
              class="ui-e"
              v-if="isTitle"
              v-html="handleNewLine(spec.props.titleContent)"
            ></td>
            <td
              class="ui-e"
              v-if="isDescription"
              v-html="handleNewLine(spec.props.descriptionContent)"
            ></td>
            <RemoveItem
              v-if="
                activeElement === elementIndex &&
                activeComponent === componentIndex &&
                params.innerElements.length > 1
              "
              @click.native="removeElement({ componentIndex, elementIndex })"
            />
          </tr>
        </tbody>
      </table>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";

export default {
  name: "OzonSpecs",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
    isTitle() {
      let output = false;
      this.params.innerElements.forEach((elem) => {
        if (elem.props.titleContent.trim() !== "") {
          output = true;
        }
      });
      return output;
    },
    isDescription() {
      let output = false;
      this.params.innerElements.forEach((elem) => {
        if (elem.props.descriptionContent.trim() !== "") {
          output = true;
        }
      });
      return output;
    },
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.specs-list-item--active {
  box-shadow: 0 0 1px 1px #f500ed;
}
</style>
