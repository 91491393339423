<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V6.31242C22 5.79148 21.7967 5.29109 21.4335 4.91771L19.1835 2.60529C18.807 2.21831 18.29 2 17.7501 2H4C2.89543 2 2 2.89543 2 4ZM4 4V20H6V13C6 11.8954 6.89543 11 8 11H16C17.1046 11 18 11.8954 18 13V20H20V6.31242L17.7501 4L16 4V6C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6V4H4ZM16 20V13H8V20H16ZM10 4H14V6H10V4Z" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "Save",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
