<template>
  <div class="stream-standard-four-image">
    <div class="stream-standard-four-image-headline">
      {{ params.blockHeadline }}
    </div>
    <div class="stream-standard-four-image-cols">
      <div
        v-for="(item, elementIndex) in params.innerElements"
        :key="elementIndex"
        class="stream-standard-four-image-col"
        :class="[
          activeElement === elementIndex && activeComponent === componentIndex
            ? 'stream-col--active'
            : null,
        ]"
        @click.stop="setActiveElement({ componentIndex, elementIndex })"
      >
        <div class="stream-standard-four-image-col-img">
          <img :src="domain + item.props.blockImage" alt="" />
        </div>
        <div class="stream-standard-four-image-col-headline">
          {{ item.props.blockHeadline }}
        </div>
        <div
          class="stream-standard-four-image-col-body"
          v-html="handleNewLine(item.props.blockBodyText)"
        ></div>
      </div>
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "StandardFourImageTextModule",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.stream-col--active {
  box-shadow: 0 0 1px 1px #f500ed;
}

.stream-standard-four-image {
  max-width: 970px;
  margin: 0 auto;
  margin-bottom: 12px;
  padding-bottom: 12px;

  .stream-standard-four-image-headline {
    font-weight: bold;
    font-size: 17px;
    line-height: 1.255;
    margin-bottom: 10px;
  }

  .stream-standard-four-image-cols {
    display: flex;
    justify-content: space-between;
  }

  .stream-standard-four-image-col {
    width: 300px;
  }

  .stream-standard-four-image-col-img {
    margin-bottom: 14px;
    img {
      max-width: 100%;
    }
  }

  .stream-standard-four-image-col-headline {
    font-family: "Amazon Ember", Arial, sans-serif;
    font-size: 17px;
    line-height: 1.255;
    margin-bottom: 6px;
  }

  .stream-standard-four-image-col-body {
    font-size: 14px;
    line-height: 20px;
    font-family: "Amazon Ember", Arial, sans-serif;

    br {
      content: "";
      display: block;
      margin-bottom: 14px;
    }
  }
}

.small-pc.tablet {
  .stream-standard-four-image-cols {
    flex-direction: column;
  }

  .stream-standard-four-image-col {
    width: auto;
    margin-bottom: 13px;
  }

  .stream-standard-four-image-col-img {
    text-align: center;
    margin-bottom: 13px;
  }

  .stream-standard-four-image-col-headline {
    font-size: 15px;
    line-height: 1.35;
    font-weight: bold;
    padding-bottom: 4px;
    margin-bottom: 4px;
  }

  .stream-standard-four-image-col-body {
    font-size: 13px;
    line-height: 1.4;
  }
}
</style>