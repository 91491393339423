<template>
  <div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "StandardSingleImageHighlightsModule",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>
