<template>
  <div class="workarea" @wheel.prevent="wheel">
    <component :is="currentTemplateStyles">
      <div class="workarea__nav">
        <button class="workarea__nav__home" @click="resetTransform">
          <Home />
        </button>
        <div class="workarea__nav__zoom">
          <button
            :class="'workarea__nav__zoom__item' + (currentZoom >= maxZoom ? ' workarea__nav__zoom__item--disabled' : '')"
            @click="changeZoom(1 + stepZoom)">
            <Plus />
          </button>
          <button
            :class="'workarea__nav__zoom__item' + (currentZoom >= maxZoom ? ' workarea__nav__zoom__item--disabled' : '')"
            @click="changeZoom(1 - stepZoom)">
            <Minus />
          </button>
        </div>
        <div class="workarea__zoom-value">
          {{ parseInt(currentZoom * 100) + '%' }}
        </div>
      </div>
      <div class="workarea__container">
        <div class="workarea__inner" :style="`${transformStyle} top:50%; left:50%;`">
          <slot />
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Home from "../ui/icons/constructor/Home.vue";
import Plus from "../ui/icons/constructor/Plus.vue";
import Minus from "../ui/icons/constructor/Minus.vue";
import MasterTemplateStyles from "./collections/templateStyles/MasterTemplateStyles.vue";
import OzonTemplateStyles from "./collections/templateStyles/OzonTemplateStyles.vue";
import EldTemplateStyles from "./collections/templateStyles/EldTemplateStyles.vue";

export default {
  name: "Workarea",
  components: {
    Home,
    Plus,
    Minus,
    OzonTemplateStyles,
    MasterTemplateStyles,
    EldTemplateStyles,
  },
  props: {
    minZoom: {
      type: Number,
      default: 0.3,
    },
    maxZoom: {
      type: Number,
      default: 2,
    },
    stepZoom: {
      type: Number,
      default: 0.1,
    },
    initialZoom: {
      type: Number,
    },
  },
  data() {
    return {
      currentZoom: 1,
      currnetTranslate: "-50%, -50%",
      beginCoordinates: {
        top: 0,
        left: 0,
      },
      positionCoordinates: {
        top: 0,
        left: 0,
      },
      positionCoordinatesOld: {
        top: 0,
        left: 0,
      },
      isZoomActive: false,
    };
  },
  computed: {
    ...mapState({
      currentTemplate: (state) => state.constructorData.currentTemplate,
    }),
    transformStyle: function () {
      return `
        -webkit-transform: translate(calc(-50% + ${this.positionCoordinates.left}px), calc(-50% + ${this.positionCoordinates.top}px)) scale(${this.currentZoom});
        -ms-transform: translate(calc(-50% + ${this.positionCoordinates.left}px), calc(-50% + ${this.positionCoordinates.top}px)) scale(${this.currentZoom});
        transform: translate(calc(-50% + ${this.positionCoordinates.left}px), calc(-50% + ${this.positionCoordinates.top}px)) scale(${this.currentZoom});
      `;
    },
    currentTemplateStyles: function () {
      let TemplateStylesComponent = 'MasterTemplateStyles';
      if (this.currentTemplate === 'ozon_template') {
        TemplateStylesComponent = 'OzonTemplateStyles';
      } else if (this.currentTemplate === 'eld_template') {
        TemplateStylesComponent = 'EldTemplateStyles';
      }
      return TemplateStylesComponent;
    },
  },
  methods: {
    changeZoom: function (value) {
      if ((this.currentZoom > this.minZoom && value < 1) || (this.currentZoom < this.maxZoom && value > 1)) {
        // var heightBefore = document.querySelector(".workarea__inner").offsetHeight*this.currentZoom;
        // var heightAfter = heightBefore*value;
        this.currentZoom *= value;
        this.positionCoordinates.top *= value;
        this.positionCoordinates.left *= value;
      }
    },
    resetTransform: function () {
      const workareaInner = document.querySelector(".workarea__inner");
      if (workareaInner) {
        const height = document.querySelector(".workarea__inner").offsetHeight;
        const containerHeight = document.querySelector(".workarea__container").offsetHeight;
        this.setInitialZoom();
        this.positionCoordinates = { top: ((height - containerHeight) / 2 * this.currentZoom), left: 0 };
      }
    },
    setInitialZoom: function () {
      let initialZoom = 0;
      if (!this.initialZoom) {
        const containerWidth = document.querySelector(".workarea__container").offsetWidth;
        const elemWidth = document.querySelector(".workarea__inner").offsetWidth;
        initialZoom = parseInt(containerWidth) / parseInt(elemWidth);
      } else {
        initialZoom = this.initialZoom;
      }
      this.currentZoom = initialZoom;
    },
    wheel(e) {
      if (e.ctrlKey) {
        if (e.deltaY !== 0) {
          let deltaZoom = e.deltaY;
          if (e.deltaY > 5) {
            deltaZoom = 5;
          }
          this.changeZoom(1 - deltaZoom / 20);
        }

      } else {
        if (e.deltaY > 2 || e.deltaY < -2) {
          this.positionCoordinates.top -= e.deltaY;
        }
        if (e.deltaX > 2 || e.deltaX < -2) {
          this.positionCoordinates.left -= e.deltaX;
        }
      }
    },

  },
  mounted() {
    this.resetTransform();
    this.setInitialZoom();
  },
};
</script>

<style lang="scss" scoped>
.workarea {
  position: relative;
  width: 100%;
  height: 100%;

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    // cursor: grab;

    // &:active {
    //   cursor: grabbing;
    // }
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    // transition: 0.3s;
  }

  &__zoom-value {
    font-size: 14px;
    line-height: 1.29;
    color: #000000;
    margin-left: 8px;
  }

  &__nav {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;

    &__home,
    &__zoom__item {
      padding: 0;
      border: none;
      background: none;
      margin-right: 8px;
    }

    &__home {
      margin-right: 16px;
    }
  }
}
</style>
