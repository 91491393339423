<template>
  <div :class="['ra-b8', activeType === 'mobile' ? 'ra-b6' : 'ra-b7']">
    <iframe
      :src="`https://www.youtube.com/embed/${params.youtubeID}`"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      class="ra-b9"
    >
    </iframe>
    <div class="c-overlay"></div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";

export default {
  name: "OzonYoutube",
  props: {
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement", "removeComponent"]),
  },
};
</script>

<style lang="scss" scoped>
.c-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>