<template>
  <vac-card :title="$t('Epack Data')" style="height: 100%; overflow: auto;">
    <div class="row">
      <div
        v-for="(input, index) in inputs"
        :key="index"
        class="flex xs12 md3"
      >
        <va-select
          v-if="input.name === 'brandName'"
          class="input--epack-data"
          :label="$t(input.placeholder)"
          v-model="input.value"
          :clearable="false"
          :options="brandsList"
          noClear
          searchable
        >
        </va-select>
        <div
          v-else-if="input.name === 'categoriesList'"
        >
          <treeselect
            
            v-model="input.value"
            clearable
            :close-on-select="false"
            async
            multiple
            isDefaultExpanded
            :load-options="loadSearchCategories"
            searchable
            value-consists-of="LEAF_PRIORITY"
            placeholder="Category"
            valueFormat="object"
          >
          <label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName" :title="node.label">
            {{ node.label }}
          </label>
          </treeselect>
          <!-- <div
           v-else
           class="input--epack-data"
           style="display: flex; justify-content: center; height: 47px; align-items: center;"
           >
            <spring-spinner
              :animation-duration="2000"
              :size="30"
              :color="$themes.primary"
            />
          </div> -->
        </div>
        <!-- <SelectAutocomplete
          v-else-if="input.name === 'category'"
          class="input--epack-data"
          :label="$t(input.placeholder)"
          v-model="input.value"
          :limit="10"
          :options="categoriesList"
          searchable
          multiple
        /> -->

        <va-input
          v-else
          :label="$t(input.placeholder)"
          v-model="input.value"
          class="input--epack-data"
        >
        </va-input>
      </div>
      <div
        v-if="isAdminUser"
        class="flex xs12 md3"
      >
        <SelectAutocomplete
          class="input--epack-data"
          label="Owner"
          v-model="epackOwner"
          :limit="10"
          noClear
          :options="usersListFormatted"
          searchable
        />
      </div>
    </div>
    <div v-if="images && images.length" class="row">
      <div class="images__title xs12 flex">USED IMAGES</div>
      <table class="images__list">
        <tr class="images__list__item">
          <th>#</th>
          <th>Locale</th>
          <th>Template</th>
          <th>Name</th>
          <th>Size</th>
        </tr>
        <tr v-for="(image, index) in images" :key="index" class="images__list__item">
        <td><b>{{ index + 1 }}.</b></td>
        <td>{{ getPictureData(image.url, 'locale') }}</td>
        <td> {{ getPictureData(image.url, 'template') }}</td>
        <td>
          <a :href="domain + image.url" target="_blank" class="images__list__item__link">
            {{ getPictureData(image.url, 'name') }}
          </a>
        </td>
        <td>
          {{ Math.round(image.size / 1024) }} kb
        </td>
        </tr>
      </table>
    </div>
  </vac-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import API from "../../services/API/API";
import listToTreeHelper from "../../services/Helpers/HelperListToTree";
import SelectAutocomplete from "../ui/SelectAutocomplete/SelectAutocomplete";
import treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {debounce} from 'lodash';
// import {listToTree, listToTreeLabeled} from "@/tree";
// import { SpringSpinner } from 'epic-spinners';

// import APIConstructor from "../../../API/APIConstructor";
// import { showToastSuccess, showToastError } from "../../../Helpers/HelperToast";


export default {
  components:{
    SelectAutocomplete,
    treeselect,
    // SpringSpinner
  },
  data() {
    return {
      inputs: [],
      epackOwner: '',
      epackOwnerLoaded: false,
      categoriesOptions: [],
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.app.categories,
      epackData: (state) => state.constructorData.epackData.manifest,
      availableCategoriesList: (state) => state.constructorData.availableCategoriesList,
      usersList: (state) => state.constructorData.usersList,
      images: (state) => state.constructorData.epackData.images,
    }),
    getCategories() {
      return this.categories;
    },
    usersListFormatted() {
      const usersListFormatted = [];
        this.usersList.forEach(elem => {
          usersListFormatted.push(elem.username);
        });
      return usersListFormatted;
    },
    brandsList: function () {
      const brands = JSON.parse(localStorage.getItem('ttlUserInfo')).brands;
      const brandlist = brands.map(elem =>(elem.name));
      brandlist.sort((a,b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
      return brandlist;
    },
    isAdminUser: function () {
      const userData = JSON.parse(localStorage.getItem("ttlUserInfo"));
      if (!userData.roles) {
        return false;
      } else {
        if (userData.roles.includes("ROLE_ADMIN")) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    ...mapActions("constructorData", ["updateManifestData"]),
    createManifest() {
      const manifest = {
        creationDate: this.epackData.creationDate,
        version: this.inputs[7].value,
        description: this.inputs[6].value,
        packageID: this.epackData.packageID,
        emsId: "constructor",
        author: this.epackOwner,
        tags: this.inputs[5].value,
        productData: {
          brandName: this.inputs[1].value,
          productName: this.inputs[0].value,
          categoriesList: this.inputs[4].value,
          MPN: this.inputs[2].value,
          EAN: this.inputs[3].value,
        },
        data: this.epackData.data,
      };
      this.updateManifestData(manifest);
    },
    loadSearchCategories({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleLoadSearchCategories(searchQuery, callback, this.categoriesOptions);
      }
    },

    dFn: debounce((searchQuery, callback, categoriesOptions) => {

      API.APIGet(`${process.env.VUE_APP_API_URL}/category/tree-for-epackages?search=${searchQuery}`, {}, (response) => {
          const treeList = listToTreeHelper(response);
          callback(null, treeList);
        })
    },1500),

    handleLoadSearchCategories(searchQuery, callback, categoriesOptions) {
      this.dFn(searchQuery, callback, categoriesOptions);
    },
    getPictureData(path, type) {
      const pathArr = path.split('/');
      const namePosition = pathArr.length - 1;
      const templatePosition = pathArr.length - 4;
      const localePosition = pathArr.length - 6;
      let result = '';
      if (type === 'name') {
        result = pathArr[namePosition];
      } else if (type === 'locale') {
        result = pathArr[localePosition];
      } else if (type === 'template') {
        result = pathArr[templatePosition];
      }
      return result;
    },
  },
  watch: {
    inputs: {
      handler: function () {
        this.createManifest();
      },
      deep: true,
    },
    epackOwner() {
      this.createManifest();
      // if(this.epackOwnerLoaded) {
      //   this.ownerChange(this.epackOwner);
      // }
    },
  },
  // created() {
  //   const {LEAF_PRIORITY} = require("@riophae/vue-treeselect/src/constants");
  //   this.LEAF_PRIORITY = LEAF_PRIORITY
  // },
  mounted() {
    this.epackOwner = this.epackData.author;
    this.inputs = [
      {
        name: "productName",
        value: this.epackData.productData.productName,
        placeholder: "Name",
      },
      {
        name: "brandName",
        value: this.epackData.productData.brandName,
        placeholder: "Brand",
      },
      {
        name: "MPN",
        value: this.epackData.productData.MPN,
        placeholder: "MPN",
      },
      {
        name: "EAN",
        value: this.epackData.productData.EAN,
        placeholder: "EAN",
      },
      {
        name: "categoriesList",
        // value: [],
        value: Array.isArray(this.epackData.productData.categoriesList) ? this.epackData.productData.categoriesList : [this.epackData.productData.categoriesList],
        placeholder: "Category",
      },
      {
        name: "tags",
        value: this.epackData.tags,
        placeholder: "Tags",
      },
      {
        name: "description",
        value: this.epackData.description,
        placeholder: "Description",
      },
      {
        name: "version",
        value: this.epackData.version,
        placeholder: "Version",
      },
    ];
  },
};
</script>
<style lang="scss">
.constructor-container .vac-card__body {
  overflow: unset;
}
body .input--epack-data {
  border: 1px solid #d6dee2 !important;
  border-radius: 6px;
  padding: 5px 16px 0;
  margin-bottom: 0;

  &.va-select__dropdown {
    padding: 0;
  }

  .va-input__container__label,
  .va-select__label {
    font-size: 12px !important;
  }

  .va-input__container {
    padding-top: 5px;
    height: 40px;
  }

  .va-input__container__label {
    left: 0;
    color: #8d9ca8 !important;
    font-weight: normal;
    text-transform: none;
  }

  .vs__dropdown-toggle {
    border: none;
  }

  .vs__search {
    margin-top: 0;
  }
}

</style>
<style lang="scss">
.vue-treeselect {
  &__menu {
    width: 100%;
  }

  &__control {
    border: 1px solid #d6dee2 !important;
    border-radius: 6px !important;
    height: 48px;
  }

  &__input-container {
    font-size: 16px !important;
  }

  // &__menu {
  //   border-radius: 0.5rem;
  //   top: 4px !important;
  //   padding: 0;
  //   box-shadow: 0 2px 8px #0000001a;
  //   border: 0;
  // }

  // &__option {
  //   height: 45px;
  //   display: flex;
  //   align-items: center;
  // }

  &__option--selected .va-icon {
    visibility: visible !important;
  }

  &__option--highlight {
    background-color: #ffffff;
  }

  &__label-container > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  &__checkbox-container {
    display: none !important;
  }

  .va-icon {
    visibility: hidden;
  }

  &__value-remove {
    display: none;
  }

  &__multi-value-item {
    background-color: white;
  }

  &__multi-value-label {
    padding: 0;
    color: #34495e;
    font-size: 16px;
    font-family: "Source Sans Pro", Arial, sans-serif;
  }

  &__x-container {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: #babfc2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2rem;
    margin: auto;

    svg {
      display: none;
    }

    &::after {
      content: "";
    }
  }

  &__control-arrow-container {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    right: 0.5rem;
    color: #babfc2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    svg {
      display: none;
    }

    &::after {
      content: "";
    }
  }

  &__placeholder {
    display: block !important;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    top: 4px;
    left: 9px;
    color: #8d9ca8 !important;
  }
}

.images__list {
  padding: 0.75rem;
  &__item {
    th {
      font-weight: bold;
    }

    td,
    th {
      padding: 4px 8px;
    }
    &__link {
      color: #000000;
      border-bottom: 1px solid #000000;
    }
  }
}

.vue-treeselect .vue-treeselect__multi-value {
  margin-top: 11px;
  padding-left: 11px;
  margin-bottom: 0;
}

.vue-treeselect.vue-treeselect--open .vue-treeselect__control-arrow-container::after {
  transform: rotate(180deg);
}

.vue-treeselect {
  span {
    line-height: 18px;
  }
}
</style>
