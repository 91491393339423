<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.0002 12L10.4102 10.59L7.8302 8L12.1652 8L12.1652 6L7.8302 6L10.4102 3.41L9.0002 2L4.00021 7L9.0002 12Z" fill="white"/>
    <path d="M12.0002 20C15.3139 20 18.0002 17.3137 18.0002 14C18.0002 10.6863 15.3139 8 12.0002 8V6C16.4185 6 20.0002 9.58172 20.0002 14C20.0002 18.4183 16.4185 22 12.0002 22V20Z" fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: "Undo",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
