<template>
  <div class="stream-standard-single-image-sidebar">
    <div class="stream-standard-single-image-sidebar-image">
      <div class="stream-standard-single-image-sidebar-image-img">
        <img :src="domain + params.mainImage" :alt="params.mainImageAltText" />
      </div>
      <div class="stream-standard-single-image-sidebar-image-caption">
        {{ params.mainImageCaptionText }}
      </div>
    </div>
    <div class="stream-standard-single-image-sidebar-main">
      <div class="stream-standard-single-image-sidebar-main-headline">
        {{ params.mainHeadlineText }}
      </div>
      <div class="stream-standard-single-image-sidebar-main-subheadline">
        {{ params.mainSubHeadlineText }}
      </div>
      <div
        class="stream-standard-single-image-sidebar-main-body"
        v-html="handleNewLine(params.mainBodyText)"
      ></div>
      <div
        v-if="mainBuletPoints.length"
        class="stream-standard-single-image-sidebar-main-bullets"
      >
        <div
          v-for="(bulletPoint, index) in mainBuletPoints"
          :key="index"
          class="stream-standard-single-image-sidebar-main-bullet"
        >
          {{ bulletPoint }}
        </div>
      </div>
    </div>
    <div class="stream-standard-single-image-sidebar-sidebar">
      <div class="stream-standard-single-image-sidebar-sidebar-img">
        <img
          :src="domain + params.sidebarImage"
          :alt="params.sidebarImageAltText"
        />
      </div>
      <div class="stream-standard-single-image-sidebar-sidebar-headline">
        {{ params.sidebarHeadlineText }}
      </div>
      <div
        class="stream-standard-single-image-sidebar-sidebar-body"
        v-html="handleNewLine(params.sidebarBodyText)"
      ></div>
      <div
        v-if="sideBuletPoints.length"
        class="stream-standard-single-image-sidebar-sidebar-bullets"
      >
        <div
          v-for="(bulletPoint, index) in sideBuletPoints"
          :key="index"
          class="stream-standard-single-image-sidebar-sidebar-bullet"
        >
          {{ bulletPoint }}
        </div>
      </div>
    </div>

    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "StandardImageSidebarModule",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
      activeType: (state) => state.constructorData.activeType,
    }),
    mainBuletPoints() {
      return this.params.mainBulletPoint
        .replace(/(?:\r\n|\r|\n)/g, "|||")
        .split("|||");
    },
    sideBuletPoints() {
      return this.params.sideBulletPoint
        .replace(/(?:\r\n|\r|\n)/g, "|||")
        .split("|||");
    },
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.stream-standard-single-image-sidebar {
  display: flex;
  max-width: 970px;
  margin: 0 auto;
  margin-top: 12px;

  .stream-standard-single-image-sidebar-image {
    width: 300px;
    min-width: 300px;
    margin-right: 20px;
  }

  .stream-standard-single-image-sidebar-image-img {
    height: 400px;
    width: 100%;
    margin-bottom: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .stream-standard-single-image-sidebar-image-caption {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 14px;
  }

  .stream-standard-single-image-sidebar-main {
    margin-right: 30px;
    width: 100%;

    .stream-standard-single-image-sidebar-main-headline {
      font-weight: bold;
      font-size: 17px;
      line-height: 1.255;
      margin-bottom: 6px;
    }

    .stream-standard-single-image-sidebar-main-subheadline {
      font-family: "Amazon Ember", Arial, sans-serif;
      font-size: 17px;
      line-height: 1.255;
      font-style: italic;
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 20px;
    }

    .stream-standard-single-image-sidebar-main-body {
      font-family: "Amazon Ember", Arial, sans-serif;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    .stream-standard-single-image-sidebar-main-bullets {
      border-radius: 8px;
      position: relative;
      padding: 14px 18px;
      background: #f3f3f3;
      border: 1px #dddddd solid;
    }

    .stream-standard-single-image-sidebar-main-bullet {
      font-family: "Amazon Ember", Arial, sans-serif;
      margin-left: 18px;
      position: relative;
      display: list-item;
      font-size: 14px;
      line-height: 20px;
      // &:marker {
      //   content: ;
      // }
    }
  }

  .stream-standard-single-image-sidebar-sidebar {
    width: 230px;
    min-width: 230px;
    padding-left: 30px;
    border-left: 1px solid #dddddd;

    .stream-standard-single-image-sidebar-sidebar-img {
      margin-bottom: 6px;

      img {
        max-width: 100%;
      }
    }

    .stream-standard-single-image-sidebar-sidebar-headline {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #565959;
    }

    .stream-standard-single-image-sidebar-sidebar-body {
      font-family: "Amazon Ember", Arial, sans-serif;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 14px;
      color: #565959;
    }

    .stream-standard-single-image-sidebar-sidebar-bullets {
      border-radius: 8px;
      position: relative;
      padding: 14px 18px;
      background: #f3f3f3;
      border: 1px #dddddd solid;
    }

    .stream-standard-single-image-sidebar-sidebar-bullet {
      font-family: "Amazon Ember", Arial, sans-serif;
      margin-left: 18px;
      position: relative;
      display: list-item;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.small-pc.tablet.mobile {
  .stream-standard-single-image-sidebar {
    flex-direction: column;

    .stream-standard-single-image-sidebar-image,
    .stream-standard-single-image-sidebar-main,
    .stream-standard-single-image-sidebar-sidebar {
      width: 100%;
      margin: 0;
      margin-bottom: 13px;
      padding: 0;
    }

    .stream-standard-single-image-sidebar-image-img,
    .stream-standard-single-image-sidebar-sidebar-img {
      text-align: center;
    }

    .stream-standard-single-image-sidebar-image-img {
      img {
        height: auto;
        max-width: 100%;
        width: auto;
      }
    }
  }
}
</style>
