<template>
  <div>
    <slot />
  </div>
</template>
<script>
export default {
  name: "OzonTemplateStyles",
};
</script>

<style lang="scss">
.ra-b7 {
  .stream-text-1 {
    font-size: 12px;
    line-height: 1.2;
  }

  .stream-text-2 {
    font-size: 14px;
    line-height: 1.2;
  }

  .stream-text-3 {
    font-size: 16px;
    line-height: 1.2;
  }

  .stream-text-4 {
    font-size: 20px;
    line-height: 1.2;
  }

  .stream-text-5 {
    font-size: 24px;
    line-height: 1.2;
  }

  .stream-text-6 {
    font-size: 30px;
    line-height: 1.2;
  }

  .stream-text-7 {
    font-size: 38px;
    line-height: 1.2;
  }
}

.ra-main {
  margin-top: 48px;
  margin-bottom: 48px;
  font-family: GTEestiPro, arial, sans-serif;
}

.ra-main .ra-main__img-wrapper {
  margin-bottom: 24px;
  width: 100%;
  position: relative;
  padding-top: 50%;
  overflow: hidden;
}

.ra-main .ra-main__img {
  top: 0;
  height: auto;
}

.ra-main .ra-main__img,
.ra-main .ra-main__video {
  position: absolute;
  left: 0;
  width: 100%;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ra-main .ra-main__video {
  bottom: 0;
  height: 100%;
}

.ra-main .ra-main__title {
  margin-bottom: 16px;
  font-size: 30px;
  // font-weight: 700;
  line-height: 38px;
}

.ra-main .ra-main__text,
.ra-main .ra-main__title {
  font-family: GTEestiPro, arial, sans-serif;
  color: #001a34;
}

.ra-main .ra-main__text {
  font-size: 16px;
  line-height: 1.25;
}

.ra-main .ra-main__text_gray {
  color: #4d5f71;
}

@media (max-width: 600px) {
  .ra-main {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .ra-main .ra-main__img-wrapper {
    margin-bottom: 16px;
  }

  .ra-main .ra-main__title {
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 8px;
  }
}

.ra-chess .ra-chess__advantage {
  margin-top: 48px;
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ra-chess .ra-chess__advantage_reverse {
  flex-direction: row-reverse;
}

.ra-chess .ra-chess__img-wrapper {
  flex: 0 0 calc(50% - 12px);
  padding-top: calc(50% - 12px);
  position: relative;
}

.ra-chess .ra-chess__img {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ra-chess .ra-chess__description {
  flex: 0 0 calc(50% - 12px);
}

.ra-chess .ra-chess__title {
  margin-bottom: 16px;
  font-size: 30px;
  // font-weight: 700;
  line-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
}

.ra-chess .ra-chess__text,
.ra-chess .ra-chess__title {
  font-family: GTEestiPro, arial, sans-serif;
  color: #001a34;
}

.ra-chess .ra-chess__text {
  font-size: 16px;
  line-height: 1.25;
}

.ra-chess .ra-chess__text_gray {
  color: #4d5f71;
}

@media (max-width: 600px) {
  .ra-chess .ra-chess__advantage {
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: normal;
  }

  .ra-chess .ra-chess__img-wrapper {
    flex: auto;
    padding-top: 100%;
    margin-bottom: 16px;
  }

  .ra-chess .ra-chess__img {
    width: 100%;
  }

  .ra-chess .ra-chess__description {
    flex: 0 0 calc(50% - 12px);
  }

  .ra-chess .ra-chess__title {
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 8px;
  }
}

.ra-triple {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  margin-bottom: 48px;
}

.ra-triple .ra-triple__advantage {
  flex: 0 0 calc(33.33333% - 10.66667px);
}

.ra-triple .ra-triple__img-wrapper {
  padding-top: 100%;
  margin-bottom: 16px;
  position: relative;
}

.ra-triple .ra-triple__img {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ra-triple .ra-triple__subtitle {
  margin-bottom: 8px;
  // font-weight: 700;
}

.ra-triple .ra-triple__subtitle,
.ra-triple .ra-triple__text {
  font-family: GTEestiPro, arial, sans-serif;
  font-size: 16px;
  line-height: 1.25;
  color: #001a34;
}

.ra-triple .ra-triple__text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  line-clamp: 8;
  // box-orient: vertical;
}

.ra-triple .ra-triple__text_gray {
  color: #4d5f71;
}

@media (max-width: 600px) {
  .ra-triple {
    flex-direction: column;
    margin-top: 32px;
  }

  .ra-triple,
  .ra-triple .ra-triple__advantage {
    margin-bottom: 32px;
  }

  .ra-triple .ra-triple__advantage:last-child {
    margin-bottom: 0;
  }
}

.ra-multiple {
  display: flex;
  margin-top: 48px;
  margin-bottom: 48px;
}

.ra-multiple .ra-multiple__advantage {
  flex: 1 1 25%;
  margin-right: 16px;
}

.ra-multiple .ra-multiple__advantage:last-child {
  margin-right: 0;
}

.ra-multiple .ra-multiple__img-wrapper {
  padding-top: 100%;
  margin-bottom: 16px;
  position: relative;
}

.ra-multiple .ra-multiple__img {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ra-multiple .ra-multiple__subtitle {
  margin-bottom: 8px;
  // font-weight: 700;
}

.ra-multiple .ra-multiple__subtitle,
.ra-multiple .ra-multiple__text {
  font-family: GTEestiPro, arial, sans-serif;
  font-size: 16px;
  line-height: 1.25;
  color: #001a34;
}

.ra-multiple .ra-multiple__text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  line-clamp: 8;
  // box-orient: vertical;
}

.ra-multiple .ra-multiple__text_gray {
  color: #4d5f71;
}

@media (max-width: 600px) {
  .ra-multiple {
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .ra-multiple .ra-multiple__advantage {
    margin-bottom: 32px;
    margin-right: 0;
  }

  .ra-multiple .ra-multiple__advantage:last-child {
    margin-bottom: 0;
  }
}

.ra-video {
  margin-top: 48px;
  margin-bottom: 48px;
  font-family: GTEestiPro, arial, sans-serif;
}

.ra-video .ra-video__wrapper {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
}

.ra-video .ra-video__frame {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 600px) {
  .ra-main {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.ra-icons {
  display: flex;
  margin-top: 48px;
  margin-bottom: 48px;
}

.ra-icons .ra-icons__advantage {
  flex: 1 1 100%;
  margin-right: 16px;
  text-align: center;
  background: #f2f3f5;
  padding: 24px 16px;
}

.ra-icons .ra-icons__advantage:last-child {
  margin-right: 0;
}

.ra-icons .ra-icons__advantage_white {
  background: #ffffff;
}

.ra-icons .ra-icons__advantage_left-align {
  text-align: left;
}

.ra-icons .ra-icons__img {
  width: auto;
  max-width: 50px;
  max-height: 50px;
  margin-bottom: 16px;
}

.ra-icons .ra-icons__title {
  // font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  // box-orient: vertical;
}

.ra-icons .ra-icons__title_black {
  color: #001a34;
}

.ra-icons .ra-icons__text-grey,
.ra-icons .ra-icons__title {
  font-family: GTEestiPro, arial, sans-serif;
  font-size: 16px;
  line-height: 1.25;
  color: #4d5f71;
}

.ra-icons .ra-icons__text-grey_black {
  color: #001a34;
}

@media (max-width: 600px) {
  .ra-icons {
    margin-top: 32px;
    margin-bottom: 32px;
    flex-wrap: wrap;
  }

  .ra-icons .ra-icons__advantage {
    flex: 1 1 35%;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 16px;
  }

  .ra-icons .ra-icons__advantage:nth-child(2n) {
    margin-right: 0;
  }

  .ra-icons .ra-icons__img {
    max-width: 40px;
    max-height: 40px;
    margin-bottom: 8px;
  }

  .ra-icons .ra-icons__text-grey,
  .ra-icons .ra-icons__title {
    font-size: 14px;
    line-height: 1.29;
  }
}

.ra-table {
  border-collapse: collapse;
  margin-top: 48px;
  margin-bottom: 48px;
  font-family: GTEestiPro, arial, sans-serif;
}

.ra-table .ra-table__subtitle {
  margin-bottom: 16px;
  font-family: GTEestiPro, arial, sans-serif;
  font-size: 16px;
  // font-weight: 700;
  line-height: 1.25;
  color: #001a34;
}

.ra-table .ra-table__wrapper {
  overflow: auto;
  font-family: GTEestiPro, arial, sans-serif;
}

.ra-table table {
  width: 100%;
}

.ra-table th {
  background-color: #e5efff;
}

.ra-table tr:nth-child(n) {
  background-color: #ffffff;
}

.ra-table tr:nth-child(odd) {
  background-color: #f2f3f5;
}

.ra-table td,
.ra-table th {
  font-size: 14px;
  line-height: 1.29;
  text-align: left;
  vertical-align: middle;
  padding: 12px 8px;
}

.ra-table td:first-child,
.ra-table th:first-child {
  padding-left: 12px;
}

.ra-table td:last-child,
.ra-table th:last-child {
  padding-right: 12px;
}

@media (max-width: 600px) {
  .ra-table {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.ra-caption {
  margin-top: 48px;
  margin-bottom: 48px;
  font-family: GTEestiPro, arial, sans-serif;
}

.ra-caption .ra-caption__text {
  font-family: GTEestiPro, arial, sans-serif;
  font-size: 12px;
  line-height: 1.33;
  color: #001a34;
}

.ra-caption .ra-caption__text_gray {
  color: #808d9a;
}

@media (max-width: 600px) {
  .ra-caption {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.ra-b8 {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  border-radius: 12px;
  overflow: hidden;
}

.ra-b8 .ra-b9 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.ra-d9 {
  font-family: GTEestiPro, arial, sans-serif;
  color: #001a34;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.ra-d9.ra-e {
  font-size: 12px;
  line-height: 1.33;
  color: #808d9a;
}

.ra-d9.ra-e0 {
  // font-weight: 700;
  font-size: 30px;
  line-height: 1.27;
}

.ra-d9.ra-e1 {
  // font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
}

.ra-d9.ra-e2,
.ra-d9.ra-e3 {
  // font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
}

.ra-d9.ra-e3,
.ra-d9.ra-e4 {
  color: #4d5f71;
}

.ra-d9.ra-e4 {
  font-size: 16px;
  line-height: 1.25;
}

// .ra-d9.ra-e5 {
//   font-weight: 700;
// }

.ra-d9.ra-e5,
.ra-d9.ra-e6 {
  font-size: 14px;
  line-height: 1.29;
  color: #4d5f71;
}

.ra-d5 {
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ra-d5.ra-d6 {
  margin-bottom: 8px;
}

.ra-e7 {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

.ra-e7 .ra-e8 {
  // position: absolute;
  // top: 0;
  // left: 0;
  // height: 100%;
  // width: 100%;
  height: auto;
  max-width: 100%;
  margin: auto;
  display: block;
  // object-fit: contain;
}

.ra-c {
  font-family: GTEestiPro, arial, sans-serif;
}

.ra-c.ra-c0 .ra-c1 + .ra-c1 {
  margin-top: 32px;
}

.ra-c .ra-c1 {
  font-family: GTEestiPro, arial, sans-serif;
}

.ra-c .ra-c1 + .ra-c1 {
  margin-top: 48px;
}

.ra-c .ra-c1 .ra-c2 {
  margin-bottom: 24px;
}

.ra-c .ra-c1 .ra-c3 {
  margin-bottom: 16px;
}

// .ra-c .ra-c1 .ra-c2 img,
// .ra-c .ra-c1 .ra-c3 img {
//   width: 100%;
//   display: block;
//   height: auto;
// }

.ra-c .ra-c4 {
  display: flex;
  font-family: GTEestiPro, arial, sans-serif;
}

.ra-c .ra-c4 + .ra-c4 {
  margin-top: 48px;
}

.ra-c .ra-c4.ra-c5 {
  flex-direction: row-reverse;
}

.ra-c .ra-c4.ra-c5 .ra-c6 {
  padding-left: 0;
  padding-right: 16px;
}

.ra-c .ra-c4 .ra-c6,
.ra-c .ra-c4 .ra-c7 {
  flex: 0 0 50%;
  max-width: 50%;
}

.ra-c .ra-c4 .ra-c6 {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 16px;
  box-sizing: border-box;
}

.ra-c.ra-c8 {
  display: flex;
  margin-left: -16px;
  // margin-top: -48px;
  flex-wrap: wrap;
}

.ra-c.ra-c8.ra-c9 {
  margin-left: -8px;
  margin-top: -8px;
}

.ra-c.ra-c8 .ra-d {
  // margin-top: 48px;
  margin-left: 16px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.ra-c.ra-c8 .ra-d .ra-c2 {
  margin-bottom: 16px;
}

.ra-c.ra-c8 .ra-d.ra-d0 {
  flex: 0 0 calc(25% - 16px);
}

.ra-c.ra-c8 .ra-d.ra-d1 {
  flex: 0 0 calc(33.33333% - 16px);
}

.ra-c.ra-c8 .ra-d.ra-d2 {
  flex: 0 0 calc(50% - 16px);
}

.ra-c.ra-c8 .ra-d.ra-d3 {
  flex: 0 0 calc(25% - 16px);
  max-width: calc(25% - 16px);
  align-items: center;
  background: #f2f3f5;
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
}

.ra-c.ra-c8 .ra-d.ra-d3 .ra-c2 {
  width: 50px;
  margin-bottom: 16px;
}

.ra-c.ra-c8 .ra-d.ra-d4 {
  flex: 0 0 calc(50% - 8px);
  max-width: calc(50% - 8px);
  align-items: center;
  background: #f2f3f5;
  padding: 8px;
  box-sizing: border-box;
  text-align: center;
  margin-left: 8px;
  margin-top: 8px;
}

.ra-c.ra-c8 .ra-d.ra-d4 .ra-c2 {
  width: 40px;
  margin-bottom: 8px;
}

.ui-k4 {
  display: inline-flex;
}

.ui-k4 > .ui-k6 {
  display: block;
  line-height: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  position: relative;
  margin: 0;
  -webkit-touch-callout: none;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: none;
  white-space: normal;
  color: inherit;
}

.ui-k4 > .ui-k6 > .ui-k8 {
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  display: inline-flex;
  text-align: center;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, opacity 0.3s ease-in-out;
  min-width: 96px;
  min-height: 32px;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 1.29;
  color: #ffffff;
  background-color: #005bff;
  font-family: GTEestiPro, arial, sans-serif;
  // font-weight: 700;
}

.ui-k4 > .ui-k6 > .ui-k8:hover {
  background-color: #004ed6;
}

.ui-k4 > .ui-k6 > .ui-k8:focus {
  outline: none;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l4 {
  font-weight: 400;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l5 {
  min-width: 120px;
  min-height: 40px;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 1.25;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l6 {
  min-width: 192px;
  min-height: 48px;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 1.25;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l7 {
  min-width: 224px;
  min-height: 56px;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 1.25;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l8 {
  color: #ffffff;
  background-color: #3ac267;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l8:hover {
  background-color: #33b062;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l9 {
  color: #005bff;
  background-color: #f2f3f5;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l9:hover {
  color: #004ed6;
  background-color: #eaedef;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m {
  color: #001a34;
  background-color: #f2f3f5;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m0,
.ui-k4 > .ui-k6 > .ui-k8.ui-m:hover {
  color: #001a34;
  background-color: #eaedef;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m0:hover {
  color: #001a34;
  background-color: #d6dbe0;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l3 {
  color: #005bff;
  background-color: hsla(0, 0%, 100%, 0);
  padding: 0 !important;
  min-width: auto;
  min-height: auto;
  font-size: inherit;
  font-weight: inherit;
}

// .ui-k4 > .ui-k6 > .ui-k8.ui-l3.ui-l5 {
//   font-weight: 700;
// }

.ui-k4 > .ui-k6 > .ui-k8.ui-l3.ui-m1 {
  text-align: left;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l3.ui-m2 {
  text-align: right;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-l3:hover {
  color: #004ed6;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-b0,
.ui-k4 > .ui-k6 > .ui-k8.ui-l3.ui-k9,
.ui-k4 > .ui-k6 > .ui-k8.ui-l3:hover {
  background-color: hsla(0, 0%, 100%, 0);
}

.ui-k4 > .ui-k6 > .ui-k8.ui-b0 {
  color: #f91155;
  padding: 0 !important;
  min-width: auto;
  min-height: auto;
  font-size: inherit;
  font-weight: inherit;
}

// .ui-k4 > .ui-k6 > .ui-k8.ui-b0.ui-l5 {
//   font-weight: 700;
// }

.ui-k4 > .ui-k6 > .ui-k8.ui-b0.ui-m1 {
  text-align: left;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-b0.ui-m2 {
  text-align: right;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-b0:hover {
  color: #bb1747;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-b0.ui-k9,
.ui-k4 > .ui-k6 > .ui-k8.ui-b0:hover {
  background-color: hsla(0, 0%, 100%, 0);
}

.ui-k4 > .ui-k6 > .ui-k8.ui-k5 {
  background-color: #ffffff;
  color: #001a34;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.ui-k4 > .ui-k6 > .ui-k8.ui-k5:hover {
  background-color: #005bff;
  color: #ffffff;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m3 {
  background-color: rgba(0, 26, 52, 0.05);
  color: #ffffff;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m3:hover {
  background-color: rgba(0, 26, 52, 0.09);
}

.ui-k4 > .ui-k6 > .ui-k8.ui-h5 {
  background-color: #f2f3f5;
  color: #f91155;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-h5:hover {
  background-color: #eaedef;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-i2 {
  background-color: #f2f3f5;
  color: #3ac267;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-i2:hover {
  background-color: #eaedef;
  color: #33b062;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m4 {
  color: inherit;
  padding: 0 !important;
  min-height: auto;
  min-width: auto !important;
  width: auto;
  font-size: inherit;
  font-weight: inherit;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m4,
.ui-k4 > .ui-k6 > .ui-k8.ui-m4.ui-k9 {
  background: hsla(0, 0%, 100%, 0);
}

.ui-k4 > .ui-k6 > .ui-k8.ui-k9 {
  color: #808d9a;
  background-color: #d6dbe0;
  pointer-events: none;
  cursor: default;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m5 {
  min-width: auto !important;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m7 {
  color: #001a34;
  border-bottom-color: #005bff;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m6 {
  color: #808d9a;
  border-bottom-color: #eaedef;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m6,
.ui-k4 > .ui-k6 > .ui-k8.ui-m7 {
  background: #ffffff;
  font-size: 12px;
  // font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.68px;
  text-align: center;
  text-transform: uppercase;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-radius: 0;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m6:hover,
.ui-k4 > .ui-k6 > .ui-k8.ui-m7:hover {
  color: #004ed6;
  border-bottom-color: #e5efff;
}

.ui-k4 > .ui-k6 > .ui-k8 .ui-m9 {
  margin-right: 6px;
  margin-left: 0;
  flex-shrink: 0;
}

.ui-k4 > .ui-k6 > .ui-k8 .ui-m9.ui-l6,
.ui-k4 > .ui-k6 > .ui-k8 .ui-m9.ui-l7 {
  margin-right: 8px;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m8 {
  min-width: 0;
  padding: 4px;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m8.ui-l5 {
  padding: 8px;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m8.ui-l6 {
  padding: 12px;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m8.ui-l7 {
  padding: 16px;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-m8 .ui-m9 {
  margin: 0;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-n {
  flex-direction: row-reverse;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-n .ui-m9 {
  margin-left: 6px;
  margin-right: 0;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-n.ui-l6 .ui-m9,
.ui-k4 > .ui-k6 > .ui-k8.ui-n.ui-l7 .ui-m9 {
  margin-left: 8px;
  margin-right: 0;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-n0 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.ui-k4 > .ui-k6 > .ui-k8.ui-n0.ui-l6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.ui-k4 > .ui-k6 > .ui-k8 .ui-n1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ui-k4 > .ui-k6 > .ui-k8 .ui-n1 .ui-n2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.29;
}

.ui-k4 > .ui-k6.ui-k9 {
  cursor: default;
}

.ui-k4 > .ui-k6.ui-b {
  pointer-events: none;
  cursor: default;
}

.ui-k4 > .ui-k6.ui-l {
  -webkit-animation: ui-l0 2s ease-in-out infinite;
  animation: ui-l0 2s ease-in-out infinite;
  pointer-events: none;
}

.ui-k4 > .ui-k6.ui-l1 {
  white-space: nowrap;
}

.ui-k4 > .ui-k6.ui-l2 {
  flex-flow: column nowrap;
}

.ui-k4 > .ui-k6:focus {
  outline: none;
}

.ui-k4 > .ui-k6:focus > .ui-k8 {
  outline: none;
  box-shadow: inset 0 0 0 2px #99bdff;
}

.ui-k4 > .ui-k6:focus > .ui-k8.ui-l3 {
  border-radius: 1px;
  box-shadow: 0 0 0 2px #99bdff;
}

.ui-k4.ui-k5 > .ui-k6 {
  padding: 8px;
  margin: -8px;
  box-sizing: content-box;
}

.ui-k4.ui-k7,
.ui-k4.ui-k7 > .ui-k6 {
  height: 100%;
}

.ui-k4.ui-k7 > .ui-k6 > .ui-k8 {
  height: 100%;
  flex: 1;
}

.ui-k4 > .ui-k6:not(:-moz-focusring):focus > .ui-k8 {
  box-shadow: none;
}

.ui-n3 {
  min-width: auto;
  width: 100% !important;
}

@-webkit-keyframes ui-l0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
  }
}

@keyframes ui-l0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
  }
}

.ui-a2b7 {
  z-index: 1;
}

.ui-a2b7,
.ui-a2b7 .ui-a2b9 {
  position: absolute;
}

.ui-a2b7 .ui-a2b9.ui-a2a1 {
  visibility: hidden;
}

.ui-a2b7.ui-a2a9 {
  left: 0;
  right: 0;
  top: 50%;
}

.ui-a2b7.ui-a2a9 .ui-a2b9.ui-a2b6 {
  left: 0;
  transform: translateY(-50%);
}

.ui-a2b7.ui-a2a9 .ui-a2b9.ui-a2c {
  right: 0;
  transform: translateY(-50%);
}

.ui-a2b7.ui-a2b8 {
  top: 0;
  bottom: 0;
  left: 50%;
}

.ui-a2b7.ui-a2b8 .ui-a2b9 {
  transform: translateX(-50%);
}

.ui-a2b7.ui-a2b8 .ui-a2b9.ui-a2b6 {
  top: 0;
}

.ui-a2b7.ui-a2b8 .ui-a2b9.ui-a2c {
  bottom: 0;
}

.ui-a2b7.ui-a8 {
  position: static;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
}

.ui-a2b7.ui-a8 .ui-a2b9 {
  border-radius: 3px;
}

.ui-a2b7.ui-a8.ui-a2a9 .ui-a2b9 {
  top: 0;
  height: calc(100% + 8px);
  margin-top: -4px;
}

.ui-a2b7.ui-a8.ui-a2a9 .ui-a2b9.ui-a2b6,
.ui-a2b7.ui-a8.ui-a2a9 .ui-a2b9.ui-a2c {
  transform: translateY(0);
}

.ui-a2b7.ui-a8.ui-a2b8 .ui-a2b9 {
  width: calc(100% + 8px);
  top: 0;
  left: 0;
  margin-left: -4px;
  transform: translateY(0);
}

.ui-a2b7.ui-a8.ui-a2b8 .ui-a2b9.ui-a2c {
  bottom: 0;
  top: auto;
}

.ui-a2b7.ui-a8.ui-a2b8 .ui-a2b9.ui-a2b6 {
  transform: translateY(0);
}

.ui-a2b7.ui-a3.ui-a2a9 .ui-a2b9.ui-a2c {
  right: -24px;
}

.ui-a2b7.ui-a3.ui-a2a9 .ui-a2b9.ui-a2b6 {
  left: -24px;
}

.ui-a2b7.ui-a3.ui-a2b8 .ui-a2b9.ui-a2c {
  bottom: -24px;
}

.ui-a2b7.ui-a3.ui-a2b8 .ui-a2b9.ui-a2b6 {
  top: -24px;
}

.ui-a2c0 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  height: 0;
  display: flex;
  justify-content: center;
}

.ui-a2c1 {
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 5px;
  background-color: hsla(0, 0%, 100%, 0.5);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.4s;
  opacity: 0.4;
}

.ui-a2c2 {
  opacity: 1;
}

.ui-f0 {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: translateZ(0);
}

.ui-f0 .ui-p {
  overflow: hidden;
  height: 100%;
}

.ui-f0 .ui-p0 {
  position: relative;
  white-space: nowrap;
  font-size: 0;
  transition: all;
  display: flex;
  will-change: transform;
}

.ui-f0.ui-p1 {
  max-height: 100%;
  display: inline-flex;
}

.ui-f0.ui-p1 .ui-p {
  height: auto;
}

.ui-f0.ui-p1 .ui-p .ui-p0 {
  flex-direction: column;
}

.ui-aa {
  flex-shrink: 0;
  position: relative;
  height: 100%;
  font-size: medium;
  white-space: normal;
  vertical-align: top;
}

.ui-a0 {
  display: inline-flex;
  flex-flow: row nowrap;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: visible;
}

.ui-a0.ui-a1 {
  flex-direction: column;
  overflow-x: visible;
  overflow-y: auto;
  max-height: 100%;
}

.ui-at5 {
  font-family: GTEestiPro, arial, sans-serif;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
}

.ui-at5 .ui-at8 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui-at5.ui-ai7 {
  flex-direction: row-reverse;
  width: 100%;
}

.ui-at5.ui-ai7 .ui-at8 {
  padding-right: 8px;
  padding-left: 0;
}

.ui-at5 .ui-aj {
  position: absolute;
  visibility: hidden;
}

.ui-at5.ui-ao0 .ui-at4 {
  display: flex;
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  border: 2px solid #d6dbe0;
  background-color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s;
  flex: 0 0 auto;
}

@media (hover: hover) {
  .ui-at5.ui-ao0 .ui-at4:focus,
  .ui-at5.ui-ao0 .ui-at4:hover {
    background-color: #99bdff;
  }
}

.ui-at5.ui-ao0 .ui-at4 .ui-ak7 {
  position: absolute;
  left: -2px;
  top: -2px;
}

@media (max-width: 767px) {
  .ui-at5.ui-ao0 .ui-at4 .ui-ak7 {
    display: none;
  }
}

.ui-at5.ui-ao0 .ui-at4 .ui-al4 {
  display: none;
  position: absolute;
  left: -2px;
  top: -2px;
}

@media (max-width: 767px) {
  .ui-at5.ui-ao0 .ui-at4 .ui-al4 {
    display: block;
  }
}

@media (max-width: 767px) {
  .ui-at5.ui-ao0 .ui-at4 {
    width: 20px;
    height: 20px;
  }
}

.ui-at5.ui-ao0.ui-as2 .ui-at4 {
  border-color: #005bff;
  background-color: #005bff;
  color: #ffffff;
}

.ui-at5.ui-ao0.ui-as2 .ui-at4:focus,
.ui-at5.ui-ao0.ui-as2 .ui-at4:hover {
  border-color: #004ed6;
  background-color: #004ed6;
}

.ui-at5.ui-ao0.ui-at7.ui-at7 {
  cursor: default;
}

.ui-at5.ui-ao0.ui-at7.ui-at7 .ui-at4 {
  border-color: #d6dbe0;
}

.ui-at5.ui-ao0.ui-at7.ui-at7 .ui-at4:focus,
.ui-at5.ui-ao0.ui-at7.ui-at7 .ui-at4:hover {
  border-color: #d6dbe0;
  background-color: #ffffff;
}

.ui-at5.ui-ao0.ui-at7.ui-at7.ui-as2 .ui-at4,
.ui-at5.ui-ao0.ui-at7.ui-at7.ui-as2 .ui-at4:focus,
.ui-at5.ui-ao0.ui-at7.ui-at7.ui-as2 .ui-at4:hover {
  border-color: #d6dbe0;
  background-color: #d6dbe0;
}

.ui-at5.ui-ao0.ui-at7.ui-at7 .ui-at8 {
  color: #b1b9c2;
}

.ui-at5.ui-at6 {
  font-family: GTEestiPro, arial, sans-serif;
}

.ui-at5.ui-at6 .ui-at4.ui-at4 {
  height: 20px;
  width: 36px;
  border-radius: 20px;
  background-color: #d6dbe0;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 0 0 auto;
}

.ui-at5.ui-at6 .ui-at4.ui-at4::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transition: transform 0.3s;
}

@media (hover: hover) {
  .ui-at5.ui-at6 .ui-at4.ui-at4:focus,
  .ui-at5.ui-at6 .ui-at4.ui-at4:hover {
    background-color: #99bdff;
  }
}

@media (max-width: 767px) {
  .ui-at5.ui-at6 .ui-at4.ui-at4 {
    height: 24px;
    width: 44px;
  }

  .ui-at5.ui-at6 .ui-at4.ui-at4::before {
    width: 20px;
    height: 20px;
  }
}

.ui-at5.ui-at6.ui-as2 .ui-at4.ui-at4 {
  background-color: #005bff;
}

.ui-at5.ui-at6.ui-as2 .ui-at4.ui-at4::before {
  transform: translateX(100%);
}

.ui-at5.ui-at6.ui-as2 .ui-at4.ui-at4:focus,
.ui-at5.ui-at6.ui-as2 .ui-at4.ui-at4:hover {
  background-color: #004ed6;
}

.ui-at5.ui-at6.ui-as2.ui-at7 .ui-at4.ui-at4 {
  background-color: #99bdff;
}

.ui-at5.ui-at6.ui-as2.ui-at7 .ui-at4.ui-at4::before {
  background-color: #ffffff;
}

.ui-at5.ui-at6.ui-at7.ui-at7 {
  cursor: default;
}

.ui-at5.ui-at6.ui-at7.ui-at7 .ui-at4.ui-at4 {
  background-color: #d6dbe0;
  cursor: default;
}

.ui-at5.ui-at6.ui-at7.ui-at7 .ui-at4.ui-at4::before {
  background-color: #eaedef;
}

.ui-at5.ui-at6.ui-at7.ui-at7 .ui-at8 {
  color: #b1b9c2;
}

.ui-at5.ui-a4 {
  align-items: flex-start;
}

.ui-at5.ui-a5 {
  align-items: flex-end;
}

.ui-a1y9 {
  position: relative;
  display: inline-block;
  border: none;
  background: transparent;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  margin: 0 2px;
}

.ui-a1y9:hover {
  background-color: #e5efff;
}

.ui-a1y9.ui-a1z5 {
  background: #e5efff;
}

.ui-a1z1 {
  padding: 0 0 100%;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
}

.ui-a1z1 .ui-a1z2 {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
}

.ui-a1z6 {
  color: #808d9a;
  cursor: default;
}

.ui-a1z6.ui-a1z5,
.ui-a1z6:hover {
  background: #eaedef;
}

.ui-a1l {
  display: flex;
  flex-wrap: wrap;
}

.ui-a1l0 {
  width: calc(14.28571% - 4px);
}

.ui-a1l1 {
  margin: 20px 0 12px;
  padding: 0;
}

.ui-a1l2 {
  display: inline-block;
  width: 14.28571%;
  font-size: 12px;
  // font-weight: 700;
  line-height: 1.33;
  text-align: center;
  color: #001a34;
  text-transform: uppercase;
}

.ui-a1l3 {
  // font-weight: 700;
  line-height: 1;
}

.ui-a1l9 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ui-a1l8 .ui-a1l9 .ui-a1m {
  transform: rotate(180deg);
  padding-bottom: 2px;
}

.ui-a1l8 .ui-a1l9 .ui-a1m:active {
  transform: rotate(180deg);
}

.ui-at9 {
  display: inline-block;
  width: 224px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 20px 26px;
  // font-weight: 700;
}

.ui-a1q7,
.ui-at9 {
  font-family: GTEestiPro, arial, sans-serif;
}

.ui-a1q7 {
  display: block;
  overflow: hidden;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  color: #001a34;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: normal;
}

.ui-a1q7.ui-a1q8,
.ui-a1q7.ui-q1 {
  white-space: normal;
}

.ui-a1q7.ui-q1 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: clip;
}

.ui-a1q4 {
  background: transparent;
  position: relative;
  cursor: pointer;
}

.ui-a1q4 + .ui-a1q4 {
  border-top: 1px solid #eaedef;
}

.ui-a1q4.ui-a1o0 {
  background-color: #e5efff;
}

.ui-a1q4.ui-a1o2 {
  display: none;
}

.ui-a1q4.ui-a1o6 {
  font-size: 14px;
  line-height: 1.29;
  padding: 6px 44px 6px 16px;
}

.ui-a1q4.ui-a1o6.ui-a1q2 {
  padding: 6px 40px 6px 12px;
}

.ui-a1q4.ui-a1o6 .ui-a1q7.ui-q1 {
  max-height: 36px;
}

.ui-a1q4.ui-a1p4,
.ui-a1q4.ui-a1p7,
.ui-a1q4.ui-a1q {
  font-size: 16px;
  line-height: 1.25;
  padding: 12px 44px 12px 16px;
}

.ui-a1q4.ui-a1p4.ui-a1q2,
.ui-a1q4.ui-a1p7.ui-a1q2,
.ui-a1q4.ui-a1q.ui-a1q2 {
  padding: 12px 40px 12px 12px;
}

.ui-a1q4.ui-a1p4 .ui-a1q7.ui-q1,
.ui-a1q4.ui-a1p7 .ui-a1q7.ui-q1,
.ui-a1q4.ui-a1q .ui-a1q7.ui-q1 {
  max-height: 40px;
}

.ui-a1q4 .ui-a1q5 {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #d6dbe0;
}

.ui-a1q4 .ui-a1q5.ui-i1 {
  color: #005bff;
}

.ui-a1q4.ui-a1q2 .ui-a1q5 {
  right: 8px;
}

.ui-au0 {
  position: relative;
  display: block;
  font-family: GTEestiPro, arial, sans-serif;
  overflow-y: auto;
  max-height: 300px;
  box-sizing: border-box;
}

.ui-au0 .ui-au1 {
  padding: 5px 10px;
  color: #808d9a;
}

.ui-h9 {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 26, 52, 0.05), 0 0 0 1px rgba(0, 26, 52, 0.05);
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .ui-h9 {
    position: fixed !important;
  }
}

.ui-i {
  margin-right: 16px;
}

.ui-i:last-child {
  margin-right: 0;
}

.ui-au2,
.ui-au3 {
  display: block;
}

.ui-au4 {
  margin: 0 0 5px;
  font-size: 14px;
  line-height: 18px;
  color: #808d9a;
}

.ui-au4,
.ui-au5 {
  font-family: GTEestiPro, arial, sans-serif;
}

.ui-au5 {
  margin: 6px 0 0;
  font-size: 12px;
  line-height: 1.33;
  color: #f91155;
}

.ui-a0l0 {
  box-sizing: border-box;
  position: relative;
  background-color: #ffffff;
  border: 2px solid #d6dbe0;
  border-radius: 3px;
  color: #001a34;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: border-color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  will-change: transform, border-color, top, background-color;
  display: flex;
}

.ui-a0l0.ui-b4 {
  padding-right: 26px;
}

.ui-a0l0.ui-a0i9,
.ui-a0l0:hover {
  border-color: #99bdff;
}

.ui-a0l0.ui-a0i2 {
  border-color: #d6dbe0;
  background-color: #f2f3f5;
}

.ui-a0l0.ui-a0i2 .ui-a0l2 .ui-a0l:hover {
  color: #808d9a;
}

.ui-a0l0.ui-b8 {
  align-items: center;
  flex-direction: row-reverse;
}

.ui-a0l0.ui-a0g5 .ui-a0l4 {
  top: 5px;
  font-size: 14px;
  line-height: 18px;
}

.ui-a0l0.ui-a0g8 .ui-a0l4 {
  top: 8px;
  font-size: 16px;
  line-height: 20px;
}

.ui-a0l0.ui-a0i5.ui-a0i9 .ui-a0l4,
.ui-a0l0.ui-a0i5.ui-a0j2 .ui-a0l4 {
  transform: translateY(-10px) scale(0.8);
  background-color: hsla(0, 0%, 100%, 0);
}

.ui-a0l0.ui-a0i5.ui-a0i2 .ui-a0l4 {
  background-color: #f2f3f5;
}

.ui-a0l0.ui-a0i5 .ui-a0l4 {
  top: 12px;
  font-size: 16px;
  line-height: 20px;
}

.ui-a0l0.ui-a0j5.ui-a0i9 .ui-a0l4,
.ui-a0l0.ui-a0j5.ui-a0j2 .ui-a0l4 {
  transform: translateY(-10px) scale(0.4);
  background-color: hsla(0, 0%, 100%, 0);
  top: 11px;
}

.ui-a0l0.ui-a0j5 .ui-a0l4 {
  top: 12px;
  line-height: 1.21;
}

.ui-a0l0.ui-a0j8 {
  border-color: #fdb8cc;
  -webkit-animation: ui-a0l6 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: ui-a0l6 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.ui-a0l0 .ui-a0l2 {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
}

.ui-a0l0 .ui-a0l2.ui-a0g5 {
  height: 28px;
}

.ui-a0l0 .ui-a0l2.ui-a0g8 {
  height: 36px;
}

.ui-a0l0 .ui-a0l2.ui-a0i5 {
  height: 44px;
}

.ui-a0l0 .ui-a0l2 .ui-a0l3 {
  margin-right: 12px;
  pointer-events: none;
  color: #005bff;
}

.ui-a0l0 .ui-a0l2 .ui-a0l {
  color: #808d9a;
  margin-right: 8px;
  pointer-events: auto;
}

.ui-a0l0 .ui-a0l2 .ui-a0l:hover {
  color: #001a34;
}

.ui-a0l0.ui-a0l1 .ui-a0l2 .ui-a0l3 {
  margin-right: 28px;
}

.ui-a0l4 {
  position: absolute;
  left: 10px;
  display: block;
  text-align: left;
  white-space: pre-wrap;
  transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform-origin: 0 0;
  will-change: transform;
  pointer-events: none;
}

.ui-a0l4,
.ui-b9 {
  margin: 0;
  font-family: GTEestiPro, arial, sans-serif;
  color: #b1b9c2;
}

.ui-b9 {
  padding-left: 12px;
  font-size: 14px;
  line-height: 1.29;
}

@-webkit-keyframes ui-a0l6 {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes ui-a0l6 {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.ui-av9 {
  box-sizing: border-box;
  vertical-align: top;
  width: 100%;
  padding: 0 10px;
  border: 0;
  outline: none;
  font-family: GTEestiPro, arial, sans-serif;
  -webkit-text-fill-color: #001a34;
  color: inherit;
  text-align: inherit;
  caret-color: #005bff;
  background-color: hsla(0, 0%, 100%, 0);
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.ui-av9[type="number"]::-webkit-inner-spin-button,
.ui-av9[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

.ui-av9[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.ui-av9[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.ui-av9.ui-av4 {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 18px;
}

.ui-av9.ui-av6 {
  padding: 8px 10px;
}

.ui-av9.ui-av6,
.ui-av9.ui-av8 {
  font-size: 16px;
  line-height: 20px;
}

.ui-av9.ui-av8 {
  padding: 18px 10px 6px;
}

.ui-av9.ui-aw {
  padding: 2px 12px;
  font-size: 30px;
  line-height: 1.27;
  // font-weight: 700;
}

.ui-a0s0 {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  bottom: 0;
  overflow: hidden;
  outline: none;
}

.ui-a0n0 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(3, 8, 13, 0.6);
}

.ui-a0n7 .ui-a0n0 {
  background-color: #ffffff;
}

.ui-a0o0 {
  position: relative;
  height: 100%;
  overflow-y: auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

.ui-a0o3 {
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.ui-a0o3.ui-a0n4 {
  align-items: center;
}

.ui-a0o3::after {
  content: "";
  display: block;
  min-height: inherit;
  font-size: 0;
}

.ui-a0q4 {
  position: relative;
  background-color: #ffffff;
  padding: 40px 48px 48px;
  box-sizing: border-box;
  min-width: 392px;
  max-width: 960px;
  margin: 50px 0;
}

.ui-a0n7 .ui-a0q4 {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.ui-a0o5 .ui-a0o0 {
  overflow-y: hidden;
}

.ui-a0o5 .ui-a0o3 {
  min-height: 0;
  height: 100%;
}

.ui-a0o5 .ui-a0q4 {
  max-height: calc(100% - 100px);
  overflow: auto;
}

.ui-a0s4 {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  transition: background-color 0.3s ease-in-out;
  z-index: 5;
}

.ui-a0s4:hover {
  background-color: #eaedef;
}

.ui-a0p0 .ui-a0q4 {
  padding-left: 64px;
  padding-right: 64px;
}

.ui-a0p3 .ui-a0q4 {
  padding: 0;
  background-color: transparent;
  min-width: 0;
  max-width: none;
}

.ui-a0p9 .ui-a0q4 {
  width: 100%;
  max-width: none;
  min-height: 100vh;
  margin: 0;
}

.ui-a0p9 .ui-a0s4 {
  position: fixed;
}

.ui-a0s1.ui-a0s0 {
  width: auto;
  right: 0;
}

.ui-a0s1 .ui-a0q4 {
  margin: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  padding: 16px;
}

.ui-a0s1 .ui-a0s4 {
  position: fixed;
  z-index: 1;
}

.ui-a0s1.ui-a0p3 .ui-a0q4 {
  padding: 0;
  background-color: #ffffff;
}

.ui-a0s1.ui-a0p9 .ui-a0o3,
.ui-a0s1.ui-a0p9 .ui-a0q4 {
  height: 100%;
  min-height: 100%;
}

.ui-a0s1.ui-a0p9 .ui-a0q4 {
  min-width: 100%;
}

.ui-a0s1.ui-b1 .ui-a0q4 {
  min-width: 100%;
  padding: 16px 0;
  margin-top: 16px;
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: auto;
}

.ui-a0s1.ui-b1 .ui-a0o3 {
  align-items: flex-end;
}

.ui-a0s1.ui-b1 .ui-a0s4 {
  position: absolute;
  z-index: 1;
}

.ui-a0s0:not(.ui-a0s1) .ui-a0s2.ui-a0s3 {
  position: absolute;
  right: -16px;
  top: -8px;
}

.ui-a0s0:not(.ui-a0s1) .ui-a0s2.ui-a0s3 .ui-a0s4 {
  position: fixed;
  top: auto;
  right: auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(3, 8, 13, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.ui-a0s0:not(.ui-a0s1) .ui-a0s2.ui-a0s3 .ui-a0s4:hover {
  background-color: #004ed6;
}

.ui-a0s5,
.ui-a0s8 {
  opacity: 0;
}

.ui-a0s7,
.ui-a0s8 {
  transition: opacity 0.3s ease-out;
}

.ui-n9 {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.ui-n9,
.ui-o {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui-o {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0, 0%, 100%, 0.6);
  z-index: 1;
  flex-direction: column;
  color: #001a34;
  border-radius: 3px;
}

.ui-o0 {
  margin-top: 8px;
  text-align: center;
}

.ui-n6 {
  max-height: 100%;
  max-width: 100%;
}

.ui-n7 {
  height: 100%;
  overflow: hidden;
}

.ui-n6.ui-n8 {
  visibility: hidden;
}

@supports (filter: blur(8px)) {
  .ui-n6.ui-n8 {
    visibility: visible;
    filter: blur(8px);
  }
}

.ui-aw5 {
  padding: 16px 24px 16px 16px;
  border-radius: 3px;
  font-family: GTEestiPro, arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  position: relative;
}

.ui-aw5 .ui-aw2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.ui-aw5 .ui-aw2 .ui-i3 {
  margin-right: 8px;
  min-width: 40px;
  min-height: 40px;
  max-height: 40px;
  max-width: 40px;
  width: 40px;
  overflow: hidden;
  flex: 0 0 40px;
}

.ui-aw5 .ui-aw2 .ui-aw7 {
  margin-right: 8px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  flex: 0 0 24px;
}

.ui-aw5 .ui-aw2 .ui-aw8 {
  padding: 3px 0;
  align-self: center;
}

// .ui-aw5 .ui-aw2 .ui-aw8 .ui-n4 {
//   font-weight: 700;
// }

.ui-aw5 .ui-aw2 .ui-aw8 .ui-n5 {
  margin-top: 12px;
  margin-right: 16px;
}

.ui-aw5 .ui-aw4 {
  margin-top: 8px;
}

.ui-aw5 .ui-c8 {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #808d9a;
  width: 16px;
  height: 16px;
  max-width: 16px;
  max-height: 16px;
  min-width: auto;
}

.ui-aw9 {
  background-color: #f2f3f5;
  color: #4d5f71;
}

.ui-ax {
  background-color: #001a34;
  color: #ffffff;
}

.ui-a0s9 {
  display: inline-flex;
}

.ui-a1e5 {
  position: absolute;
  top: 0;
  left: -99999px;
  margin: 0;
  padding: 0;
  display: inline-block;
  opacity: 0;
  transition: opacity 0.5s;
  will-change: transform;
}

.ui-a1e5.ui-a0t1 {
  opacity: 1;
}

.ui-a1e5 .ui-a1e7 {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-radius: 3px;
  position: relative;
}

.ui-a1e5.ui-a0y6.ui-a0z .ui-a1e7 {
  padding: 12px 16px;
}

.ui-a1e5.ui-a0y6 .ui-a1e7 {
  padding: 20px 16px;
}

.ui-a1e5.ui-a0y6 .ui-a1e7 .ui-d {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #d6dbe0;
  width: 16px;
  height: 16px;
  max-width: 16px;
  max-height: 16px;
  min-width: auto;
  transition: color 0.3s;
}

.ui-a1e5.ui-a0y6 .ui-a1e7 .ui-d:hover {
  color: #808d9a;
}

.ui-a1e5.ui-a0y6[data-popper-placement="bottom"] .ui-a {
  top: -12px;
}

.ui-a1e5.ui-a0y6[data-popper-placement="bottom"] .ui-a::after,
.ui-a1e5.ui-a0y6[data-popper-placement="bottom"] .ui-a::before {
  display: block;
  content: "";
  position: absolute;
  top: 1px;
  transform: translateX(-50%);
  background: hsla(0, 0%, 100%, 0);
  border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) #ffffff;
  border-style: solid;
  border-width: 0 12px 12px;
}

.ui-a1e5.ui-a0y6[data-popper-placement="bottom"] .ui-a::before {
  top: 0;
  border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) #eaedef;
}

.ui-a1e5.ui-a0y6[data-popper-placement="top"] .ui-a {
  bottom: 0;
}

.ui-a1e5.ui-a0y6[data-popper-placement="top"] .ui-a::after,
.ui-a1e5.ui-a0y6[data-popper-placement="top"] .ui-a::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  background: hsla(0, 0%, 100%, 0);
  border-color: #ffffff hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0);
  border-style: solid;
  border-width: 12px 12px 0;
}

.ui-a1e5.ui-a0y6[data-popper-placement="top"] .ui-a::before {
  top: 1px;
  border-color: #eaedef hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0);
}

.ui-a1e5.ui-a0y6[data-popper-placement="right"] .ui-a1e7::after,
.ui-a1e5.ui-a0y6[data-popper-placement="right"] .ui-a1e7::before {
  display: block;
  content: "";
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  background: hsla(0, 0%, 100%, 0);
  border-color: hsla(0, 0%, 100%, 0) #ffffff hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0);
  border-style: solid;
  border-width: 12px 12px 12px 0;
}

.ui-a1e5.ui-a0y6[data-popper-placement="right"] .ui-a1e7::before {
  left: -13px;
  border-color: hsla(0, 0%, 100%, 0) #eaedef hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0);
}

.ui-a1e5.ui-a0y6[data-popper-placement="left"] .ui-a1e7::after,
.ui-a1e5.ui-a0y6[data-popper-placement="left"] .ui-a1e7::before {
  display: block;
  content: "";
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  background: hsla(0, 0%, 100%, 0);
  border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) #ffffff;
  border-style: solid;
  border-width: 12px 0 12px 12px;
}

.ui-a1e5.ui-a0y6[data-popper-placement="left"] .ui-a1e7::before {
  right: -13px;
  border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) #eaedef;
}

.ui-a1e5.ui-a1d2.ui-a0z .ui-a1e7 {
  padding: 12px 0;
}

.ui-a1e5.ui-a1d2 .ui-a1e7 {
  padding: 20px 0;
}

.ui-a1e5.ui-a1d2[data-popper-placement="bottom"] .ui-a {
  top: -12px;
}

.ui-a1e5.ui-a1d2[data-popper-placement="bottom"] .ui-a::after,
.ui-a1e5.ui-a1d2[data-popper-placement="bottom"] .ui-a::before {
  display: block;
  content: "";
  position: absolute;
  top: 1px;
  transform: translateX(-50%);
  background: hsla(0, 0%, 100%, 0);
  border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) #ffffff;
  border-style: solid;
  border-width: 0 12px 12px;
}

.ui-a1e5.ui-a1d2[data-popper-placement="bottom"] .ui-a::before {
  top: 0;
  border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) #eaedef;
}

.ui-a1e5.ui-a1d2[data-popper-placement="top"] .ui-a {
  bottom: 0;
}

.ui-a1e5.ui-a1d2[data-popper-placement="top"] .ui-a::after,
.ui-a1e5.ui-a1d2[data-popper-placement="top"] .ui-a::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  background: hsla(0, 0%, 100%, 0);
  border-color: #ffffff hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0);
  border-style: solid;
  border-width: 12px 12px 0;
}

.ui-a1e5.ui-a1d2[data-popper-placement="top"] .ui-a::before {
  top: 1px;
  border-color: #eaedef hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0);
}

.ui-a1e5.ui-a1d2[data-popper-placement="right"] .ui-a1e7::after,
.ui-a1e5.ui-a1d2[data-popper-placement="right"] .ui-a1e7::before {
  display: block;
  content: "";
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  background: hsla(0, 0%, 100%, 0);
  border-color: hsla(0, 0%, 100%, 0) #ffffff hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0);
  border-style: solid;
  border-width: 12px 12px 12px 0;
}

.ui-a1e5.ui-a1d2[data-popper-placement="right"] .ui-a1e7::before {
  left: -13px;
  border-color: hsla(0, 0%, 100%, 0) #eaedef hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0);
}

.ui-a1e5.ui-a1d2[data-popper-placement="left"] .ui-a1e7::after,
.ui-a1e5.ui-a1d2[data-popper-placement="left"] .ui-a1e7::before {
  display: block;
  content: "";
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  background: hsla(0, 0%, 100%, 0);
  border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) #ffffff;
  border-style: solid;
  border-width: 12px 0 12px 12px;
}

.ui-a1e5.ui-a1d2[data-popper-placement="left"] .ui-a1e7::before {
  right: -13px;
  border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) #eaedef;
}

.ui-a1e5.ui-a1e6 {
  width: 100%;
  transition: opacity 0.2s;
}

.ui-a1e5.ui-a1e6[data-popper-placement="bottom-start"] {
  padding-top: 5px;
}

.ui-a1e5.ui-a1e6[data-popper-placement="top-start"] {
  padding-bottom: 5px;
}

.ui-a1e5.ui-a1e6 .ui-a1e7 {
  padding: 0;
}

.ui-a1e5.ui-a1e6 .ui-a1e7::after,
.ui-a1e5.ui-a1e6 .ui-a1e7::before {
  content: none;
}

.ui-az7 {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  min-height: 16px;
  line-height: 15px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: GTEestiPro, arial, sans-serif;
}

.ui-az7.ui-ay0 {
  pointer-events: none;
}

.ui-az7.ui-ay0 .ui-ay9 + .ui-az8 {
  background-color: #d6dbe0;
  border-color: #d6dbe0;
}

.ui-az7.ui-ay0 .ui-az8::after {
  content: none;
}

.ui-az7:not(.ui-ay0) .ui-ay9:checked + .ui-az8 {
  background-color: #005bff;
  border-color: #005bff;
}

.ui-az7 .ui-ay9 {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}

.ui-az7:hover .ui-ay9:not(:checked) + .ui-az8 {
  border-color: #99bdff;
}

.ui-az7:hover .ui-ay9:not(:checked) + .ui-az8::after {
  background-color: #99bdff;
}

.ui-az7 .ui-az8 {
  position: relative;
  margin-right: 5px;
  box-sizing: border-box;
  flex: 0 0 16px;
  width: 16px;
  max-width: 16px;
  height: 16px;
  border: 2px solid #eaedef;
  border-radius: 50%;
  background-color: #ffffff;
}

.ui-az7 .ui-az8::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  content: "";
  background-color: #ffffff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.ui-az7.ui-d8 {
  width: 100%;
}

.ui-az7.ui-d9 {
  flex-direction: row-reverse;
}

.ui-az7.ui-d9 .ui-az8 {
  margin-right: 0;
  margin-left: 5px;
}

.ui-a9.ui-az7 {
  min-height: 22px;
  line-height: 21px;
}

.ui-a9.ui-az7 .ui-az8 {
  flex: 0 0 22px;
  width: 22px;
  max-width: 22px;
  height: 22px;
}

.ui-a9.ui-az7 .ui-az8::after {
  width: 10px;
  height: 10px;
}

@media (max-width: 767px) {
  .ui-az7 {
    min-height: 22px;
    line-height: 21px;
  }

  .ui-az7 .ui-az8 {
    flex: 0 0 22px;
    width: 22px;
    max-width: 22px;
    height: 22px;
  }

  .ui-az7 .ui-az8::after {
    width: 10px;
    height: 10px;
  }
}

.ui-a0a + .ui-a0a {
  margin-left: 10px;
}

.ui-a2 {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.ui-a2 .ui-a0a + .ui-a0a {
  margin-left: 0;
  margin-top: 16px;
}

.ui-h6 {
  display: flex;
  position: relative;
  width: 96px;
  height: 16px;
}

.ui-h6 .ui-a0d0,
.ui-h6 .ui-a0d7 {
  top: 0;
  left: 0;
  position: absolute;
}

.ui-h6 .ui-a0d7 {
  width: 100%;
}

.ui-h6.ui-p2 {
  overflow: hidden;
}

.ui-h6.ui-a0d4 .ui-p5 {
  margin: 0 2px;
}

.ui-h6.ui-a0d4 .ui-a0d0,
.ui-h6.ui-a0d4 .ui-a0d7 {
  height: 16px;
  width: 96px;
  background-size: 20px 16px;
  background-position-x: -2px;
  background-repeat: repeat-x;
}

.ui-h6.ui-a0d4 .ui-a0d0 {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ff8970'%3E%3Cpath d='M7.952.656a.226.226 0 00-.21.13l-2.22 4.738-5.112.688a.23.23 0 00-.125.393l3.737 3.617-.937 5.163a.234.234 0 00.09.227c.07.052.163.058.24.016l4.531-2.503 4.532 2.503c.077.042.17.036.24-.016a.233.233 0 00.09-.227l-.938-5.163 3.738-3.617a.228.228 0 00-.126-.393l-5.11-.688L8.148.786a.222.222 0 00-.197-.13z'/%3E%3C/svg%3E");
}

.ui-h6.ui-a0d4 .ui-a0d7 {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23d6dbe0'%3E%3Cpath d='M7.952.656a.226.226 0 00-.21.13l-2.22 4.738-5.112.688a.23.23 0 00-.125.393l3.737 3.617-.937 5.163a.234.234 0 00.09.227c.07.052.163.058.24.016l4.531-2.503 4.532 2.503c.077.042.17.036.24-.016a.233.233 0 00.09-.227l-.938-5.163 3.738-3.617a.228.228 0 00-.126-.393l-5.11-.688L8.148.786a.222.222 0 00-.197-.13z'/%3E%3C/svg%3E");
}

.ui-h6.ui-a0d6 {
  width: 136px;
  height: 24px;
}

.ui-h6.ui-a0d6 .ui-p5 {
  margin: 0 2px;
}

.ui-h6.ui-a0d6 .ui-a0d0,
.ui-h6.ui-a0d6 .ui-a0d7 {
  height: 24px;
  background-size: 28px 24px;
  background-position-x: -4px;
  background-repeat: repeat-x;
}

.ui-h6.ui-a0d6 .ui-a0d0 {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23ff8970'%3E%3Cpath d='M12.007 2.2a.291.291 0 00-.27.17L8.863 8.528l-6.61.895a.293.293 0 00-.239.204.303.303 0 00.077.308l4.834 4.7-1.212 6.713a.303.303 0 00.115.295.284.284 0 00.31.021L12 18.411l5.861 3.253c.1.055.22.047.31-.021a.301.301 0 00.116-.295l-1.212-6.712 4.834-4.701c.082-.08.111-.2.077-.308a.293.293 0 00-.24-.204l-6.61-.895-2.873-6.158a.29.29 0 00-.256-.17z'/%3E%3C/svg%3E");
}

.ui-h6.ui-a0d6 .ui-a0d7 {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23d6dbe0'%3E%3Cpath d='M12.007 2.2a.291.291 0 00-.27.17L8.863 8.528l-6.61.895a.293.293 0 00-.239.204.303.303 0 00.077.308l4.834 4.7-1.212 6.713a.303.303 0 00.115.295.284.284 0 00.31.021L12 18.411l5.861 3.253c.1.055.22.047.31-.021a.301.301 0 00.116-.295l-1.212-6.712 4.834-4.701c.082-.08.111-.2.077-.308a.293.293 0 00-.24-.204l-6.61-.895-2.873-6.158a.29.29 0 00-.256-.17z'/%3E%3C/svg%3E");
}

.ui-h6.ui-a0d8 {
  width: 208px;
  height: 40px;
}

.ui-h6.ui-a0d8 .ui-p5 {
  margin: 0 2px;
}

.ui-h6.ui-a0d8 .ui-a0d0,
.ui-h6.ui-a0d8 .ui-a0d7 {
  height: 40px;
  background-size: 44px 40px;
  background-position-x: -6px;
  background-repeat: repeat-x;
}

.ui-h6.ui-a0d8 .ui-a0d0 {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%23ff8970'%3E%3Cpath d='M20.012 4.5a.466.466 0 00-.433.27l-4.598 9.79-10.576 1.423a.468.468 0 00-.382.323.478.478 0 00.122.49l7.734 7.474-1.938 10.67a.48.48 0 00.185.47.46.46 0 00.496.033L20 30.27l9.378 5.172a.458.458 0 00.496-.034.478.478 0 00.185-.47L28.12 24.27l7.734-7.472a.479.479 0 00.122-.49.468.468 0 00-.382-.324L25.019 14.56 20.42 4.77a.465.465 0 00-.408-.27z'/%3E%3C/svg%3E");
}

.ui-h6.ui-a0d8 .ui-a0d7 {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%23d6dbe0'%3E%3Cpath d='M20.012 4.5a.466.466 0 00-.433.27l-4.598 9.79-10.576 1.423a.468.468 0 00-.382.323.478.478 0 00.122.49l7.734 7.474-1.938 10.67a.48.48 0 00.185.47.46.46 0 00.496.033L20 30.27l9.378 5.172a.458.458 0 00.496-.034.478.478 0 00.185-.47L28.12 24.27l7.734-7.472a.479.479 0 00.122-.49.468.468 0 00-.382-.324L25.019 14.56 20.42 4.77a.465.465 0 00-.408-.27z'/%3E%3C/svg%3E");
}

.ui-h6.ui-a0d6.ui-h8 > :first-child {
  margin-left: -2px;
}

.ui-h6.ui-a0d4.ui-h8 > :first-child {
  margin-left: 0;
}

.ui-h6.ui-a0d8.ui-h8 > :first-child {
  margin-left: -4px;
}

.ui-h6.ui-p3 .ui-h7 {
  -webkit-animation: ui-p4 0.25s ease-in-out;
  animation: ui-p4 0.25s ease-in-out;
}

.ui-h6.ui-p3 .ui-h7:nth-child(2) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.ui-h6.ui-p3 .ui-h7:nth-child(3) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.ui-h6.ui-p3 .ui-h7:nth-child(4) {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.ui-h6.ui-p3 .ui-h7:nth-child(5) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes ui-p4 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes ui-p4 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20%);
  }

  to {
    transform: translateY(0);
  }
}

.ui-a0f2 .ui-a7 {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.ui-a0f2 .ui-a0f4 {
  margin-left: 12px;
  color: #e5efff;
}

.ui-a0f2 .ui-a0f4.ui-a0e5 {
  color: #808d9a;
}

.ui-a0f2 .ui-c9 {
  position: relative;
  right: -6px;
  color: #ffffff;
  pointer-events: auto;
  width: 16px;
  height: 16px;
  max-height: 16px;
  max-width: 16px;
  overflow: hidden;
}

.ui-a0f2 .ui-c9:hover {
  opacity: 0.6;
}

.ui-a0f2.ui-b5 .ui-c9 {
  color: #ffffff;
}

.ui-a0f2.ui-a0f3 .ui-a0f4,
.ui-a0f2.ui-a0f3 .ui-c9,
.ui-a0f2.ui-b5 .ui-a0f4,
.ui-a0f2.ui-b6 .ui-a0f4,
.ui-a0f2.ui-b6 .ui-c9 {
  color: #808d9a;
}

.ui-a1k6 {
  box-sizing: border-box;
  font-family: GTEestiPro, arial, sans-serif;
  line-height: 1.15;
  position: relative;
  width: 100%;
}

.ui-a1k6 .ui-a1f {
  display: block;
  width: 100%;
}

.ui-a1k6 .ui-a1f3,
.ui-a1k6 .ui-a1f3:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  display: block;
  border: 0;
  outline: none;
  max-width: 100%;
  background: none;
  box-shadow: none;
  flex-grow: 1;
  cursor: default;
  letter-spacing: inherit;
  line-height: inherit;
  height: auto;
  border-left: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 24px 0 0;
}

.ui-a1k6 .ui-a1g3 {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
  color: #b1b9c2;
  font-size: 12px;
  line-height: 1.33;
}

.ui-a1k6 .ui-a1g3 .ui-a1g4 {
  color: #001a34;
  cursor: pointer;
  transition: transform 0.3s;
}

.ui-a1k6 .ui-a1g3 .ui-a1g4.ui-a1g1 {
  transform: rotate(180deg);
}

.ui-a1k6 .ui-a1g3 .ui-a1g4.ui-a1g5 {
  color: #b1b9c2;
}

.ui-a1k6 .ui-a1j5 {
  margin: 0;
  display: flex;
  box-sizing: border-box;
  position: relative;
}

.ui-a1k6 .ui-a1j5.ui-a1h {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 18px;
  min-height: 28px;
}

.ui-a1k6 .ui-a1j5.ui-a1h2 {
  padding: 8px 10px;
  font-size: 16px;
  line-height: 20px;
  min-height: 36px;
}

.ui-a1k6 .ui-a1j5.ui-a1h5 {
  padding: 18px 10px 6px;
  font-size: 16px;
  line-height: 20px;
  min-height: 44px;
}

.ui-a1k6 .ui-a1j5.ui-a1h8 {
  padding: 13px 10px 6px;
  font-size: 38px;
  line-height: 1.21;
  min-height: 64px;
}

.ui-a1k6 .ui-a1j5 .ui-a1k8 {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.ui-a1k6 .ui-a1j5 .ui-a1k8 .ui-a1k9 {
  display: block;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
}

.ui-a1k6 .ui-a1j5 .ui-a1k8.ui-a1i8 .ui-a1k9 {
  opacity: 0.3;
}

.ui-a1k6 .ui-a1j5 .ui-a1k8.ui-a1j2 .ui-a1k9 {
  visibility: hidden;
}

.ui-a1k6.ui-a1k7.ui-a1k1 .ui-a1k8 {
  padding-right: 42px;
}

.ui-a1k6.ui-a1k7 .ui-a1k8 {
  flex-wrap: nowrap;
  padding-right: 18px;
  box-sizing: border-box;
  max-width: 100%;
}

.ui-a1k6.ui-a1k7 .ui-a1k8 .ui-a1k9 {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}

.ui-f1 {
  box-sizing: border-box;
  font-family: GTEestiPro, arial, sans-serif;
  line-height: 1.15;
  position: relative;
  width: 100%;
}

.ui-f3,
.ui-f4,
.ui-f4:focus {
  display: block;
  width: 100%;
}

.ui-f4,
.ui-f4:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  border: 0;
  outline: none;
  max-width: 100%;
  background: none;
  box-shadow: none;
  flex-grow: 1;
  cursor: default;
  letter-spacing: inherit;
  line-height: inherit;
  height: auto;
  border-left: none;
  position: absolute;
  left: 0;
  padding: 0 24px 0 0;
}

.ui-f5 {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
  color: #b1b9c2;
  font-size: 12px;
  line-height: 1.33;
}

.ui-f6 {
  color: #001a34;
  cursor: pointer;
  transition: transform 0.3s;
}

.ui-f6.ui-f7 {
  transform: rotate(180deg);
}

.ui-f6.ui-f8 {
  color: #b1b9c2;
}

.ui-f9 {
  margin: 0;
  display: flex;
  box-sizing: border-box;
  position: relative;
}

.ui-f9.ui-g {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 18px;
  min-height: 28px;
}

.ui-f9.ui-g0 {
  padding: 8px 10px;
  min-height: 36px;
}

.ui-f9.ui-g0,
.ui-f9.ui-g1 {
  font-size: 16px;
  line-height: 20px;
}

.ui-f9.ui-g1 {
  padding: 18px 10px 6px;
  min-height: 44px;
}

.ui-f9.ui-g2 {
  padding: 13px 10px 6px;
  font-size: 38px;
  line-height: 1.21;
  min-height: 64px;
}

.ui-g3 {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.ui-g3 .ui-g4 {
  display: block;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
}

.ui-g3.ui-g5 .ui-g4 {
  opacity: 0.3;
}

.ui-g3.ui-g6 .ui-g4 {
  visibility: hidden;
}

.ui-g8.ui-g9 .ui-g3 {
  padding-right: 42px;
}

.ui-g8 .ui-g3 {
  flex-wrap: nowrap;
  padding-right: 18px;
  box-sizing: border-box;
  max-width: 100%;
}

.ui-g8 .ui-g3 .ui-g4 {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}

.ui-h {
  display: block;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  box-sizing: border-box;
  padding-top: 6px;
  z-index: 101;
}

.ui-h0 {
  display: flex;
  margin-bottom: 10px;
  padding-left: 16px;
}

.ui-h1 {
  padding: 10px 16px 16px;
  font-size: 16px;
  line-height: 20px;
  color: #005bff;
  font-family: GTEestiPro, arial, sans-serif;
}

.ui-h2 {
  display: block;
  width: 100%;
}

.ui-h3 {
  padding: 0;
  margin: 0;
}

.ui-h4 {
  list-style: none;
  font-size: 16px;
  line-height: 20px;
  color: #001a34;
  padding: 12px 16px 12px 0;
  border-bottom: 1px solid #d6dbe0;
}

.ui-a0m6,
.ui-h4 {
  font-family: GTEestiPro, arial, sans-serif;
}

.ui-a0m6 {
  box-sizing: border-box;
  vertical-align: top;
  width: 100%;
  padding: 0 10px;
  border: 0;
  outline: none;
  -webkit-text-fill-color: #001a34;
  color: inherit;
  text-align: inherit;
  caret-color: #005bff;
  background-color: hsla(0, 0%, 100%, 0);
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  resize: none;
}

.ui-a0m6.ui-a0l9 {
  overflow: auto;
}

.ui-a0m6.ui-a0m1 {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 18px;
}

.ui-a0m6.ui-a0m3 {
  padding: 8px 10px;
  font-size: 16px;
  line-height: 20px;
}

.ui-a0m6.ui-a0m5 {
  padding: 18px 10px 6px;
  font-size: 16px;
  line-height: 20px;
}

.ui-a0m6.ui-a0m7 {
  padding: 13px 10px 6px;
  font-size: 38px;
  line-height: 1.21;
  // font-weight: 700;
}

.ui-i4,
.ui-i5,
.ui-i6,
.ui-i7 {
  transition: transform 0.15s, opacity 0.15s;
}

.ui-i6,
.ui-i7 {
  transition-delay: 0.3s;
}

.ui-i8,
.ui-i9 {
  transition: transform 0.15s, opacity 0.15s;
  transition-delay: 0.6s;
}

.ui-j,
.ui-j0,
.ui-j1,
.ui-j2 {
  transition: transform 40ms, opacity 40ms;
}

.ui-j1,
.ui-j2 {
  transition-delay: 0.3s;
}

.ui-j3,
.ui-j4 {
  transition: transform 40ms, opacity 40ms;
  transition-delay: 0.6s;
}

.ui-j5,
.ui-j6,
.ui-j7,
.ui-j8 {
  transition: transform 0.4s, opacity 0.4s;
}

.ui-j7,
.ui-j8 {
  transition-delay: 0.3s;
}

.ui-j9,
.ui-k {
  transition: transform 0.4s, opacity 0.4s;
  transition-delay: 0.6s;
}

.ui-e9,
.ui-k0 {
  transform: translateY(10px) !important;
  opacity: 0;
}

.ui-k1,
.ui-k2 {
  opacity: 0;
}

.ui-f2,
.ui-k3 {
  opacity: 1;
}

.ui-d6 {
  text-decoration: none;
  color: inherit;
}

.ui-d1 {
  padding: 0 8px;
  min-width: 88px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.ui-d2 {
  font-size: 12px;
  line-height: 1.33;
  margin-top: -10px;
  margin-left: 2px;
}

.ui-d2.ui-d3 {
  color: #f91155;
}

.ui-d2.ui-d4 {
  color: #808d9a;
}

.ui-d5 + .ui-d5 {
  margin-left: 8px;
}

.ui-d7 {
  width: 100%;
}

.ui-e1 {
  display: inline-flex;
  position: relative;
  align-items: flex-end;
}

.ui-e1.ui-d0,
.ui-e2 {
  width: 100%;
}

.ui-e2 {
  position: absolute;
  height: 2px;
  background: #005bff;
  bottom: 0;
  left: 0;
  transition: left 0.2s ease-out 0.1s, width 0.2s ease-out 0.1s;
}

.ui-c {
  box-sizing: border-box;
  position: relative;
  touch-action: none;
  height: 24px;
  margin-bottom: 16px;
  overflow: hidden;
}

.ui-c1 {
  margin: 0;
  -webkit-appearance: none;
  position: absolute;
  display: block;
  width: 100%;
  background: transparent;
  outline: none;
  height: 20px;
  padding: 2px 0;
  pointer-events: none;
}

.ui-c1.ui-c4 {
  z-index: 1;
}

.ui-c1.ui-c4:focus::-webkit-slider-thumb {
  border: 2px solid #005bff;
}

.ui-c1.ui-c4:focus::-moz-range-thumb {
  border: 2px solid #005bff;
}

.ui-c1::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 2px solid #d6dbe0;
  border-radius: 50%;
  pointer-events: auto;
  cursor: pointer;
}

.ui-c1::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 2px solid #d6dbe0;
  border-radius: 50%;
  pointer-events: auto;
  cursor: pointer;
}

.ui-c1::-webkit-slider-thumb:hover {
  border: 2px solid #99bdff;
}

.ui-c1::-moz-range-thumb:hover {
  border: 2px solid #99bdff;
}

.ui-c1::-moz-focus-outer {
  border: 0;
}

.ui-c0 {
  height: 30px;
}

.ui-c0 .ui-c1 {
  height: 30px;
  padding: 0;
  pointer-events: all;
}

.ui-c2 {
  left: 0;
  width: 100%;
  border-radius: 2px;
  background: #d6dbe0;
  pointer-events: none;
}

.ui-c2,
.ui-c3 {
  position: absolute;
  top: 50%;
  height: 4px;
  transform: translateY(-50%);
}

.ui-c3 {
  background-color: #99bdff;
  border-radius: 4px;
}

.ui-c5 {
  display: flex;
  justify-content: space-between;
}

.ui-c6 {
  width: 120px;
}

.ui-c6.ui-i0 {
  width: 100%;
  min-width: 120px;
}

.ui-c6 + .ui-c6 {
  margin-left: 8px;
}

.ui-c7 {
  min-width: 0;
}

.ui-a6 .ui-c3 {
  background-color: #d6dbe0;
}

.ui-a6 .ui-c1::-moz-range-thumb {
  pointer-events: none;
}

.ui-a6 .ui-c1::-webkit-slider-thumb {
  pointer-events: none;
}

.ui-a6 .ui-c1::-webkit-slider-thumb:hover {
  border: 2px solid #d6dbe0;
}

.ui-a6 .ui-c1::-moz-range-thumb:hover {
  border: 2px solid #d6dbe0;
}

.ui-b2 {
  font-family: GTEestiPro, arial, sans-serif;
  display: inline-block;
}

.ui-b2 .ui-b3 {
  border-collapse: collapse;
}

.ui-b2 .ui-b3 .ui-b7 {
  background-color: #ffffff;
  position: relative;
}

.ui-b2 .ui-b3 .ui-b7:nth-child(2n) {
  background-color: #f2f3f5;
}

.ui-b2 .ui-b3 .ui-b7 .ui-e,
.ui-b2 .ui-b3 .ui-b7 .ui-e0 {
  position: relative;
  text-align: left;
  vertical-align: middle;
  padding: 11px 8px 11px 12px;
  font-size: 14px;
  line-height: 1.29;
  color: #001a34;
  font-weight: 400;
  min-width: 120px;
}

.ui-b2 .ui-b3 .ui-b7 .ui-e0.ui-f,
.ui-b2 .ui-b3 .ui-b7 .ui-e.ui-f {
  text-align: right;
}

.ui-b2 .ui-b3 .ui-e3 .ui-b7 .ui-e0 {
  background-color: #e5efff;
  font-size: 14px;
  // font-weight: 700;
  line-height: 1.29;
  color: #001a34;
}

.ui-b2.ui-e4 .ui-b3 {
  overflow: hidden;
}

.ui-b2.ui-e4 .ui-b3 .ui-b7 .ui-e:hover::after {
  content: "";
  mix-blend-mode: multiply;
  position: absolute;
  background-color: #e5efff;
  left: 0;
  top: -10000vh;
  height: 30000vh;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}

.ui-b2.ui-e5 .ui-b3 .ui-b7 .ui-e0 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.ui-b2.ui-e6 .ui-b3 .ui-b7 .ui-e,
.ui-b2.ui-e6 .ui-b3 .ui-b7 .ui-e0 {
  padding: 24px;
}

.ui-b2.ui-e6 .ui-b3 .ui-b7 .ui-e0:first-child,
.ui-b2.ui-e6 .ui-b3 .ui-b7 .ui-e:first-child {
  padding-left: 12px;
}

.ui-b2.ui-e6 .ui-b3 .ui-b7 .ui-e0:last-child,
.ui-b2.ui-e6 .ui-b3 .ui-b7 .ui-e:last-child {
  padding-right: 12px;
}

.ui-b2.ui-e7 .ui-b3 .ui-b7:hover {
  background-color: #e5efff;
}

.ui-b2.ui-e8,
.ui-b2.ui-e8 .ui-b3 {
  width: 100%;
}

.ui-o1 {
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 320px;
  padding: 0 16px;
}

.ui-o1,
.ui-o1 .ui-o2 {
  display: flex;
  position: relative;
  justify-content: center;
}

.ui-o1 .ui-o2 {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.ui-o1 .ui-o3 {
  width: 64px;
  height: 64px;
  background-color: #090089;
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 4px;
  z-index: 1;
}

.ui-o1 .ui-o4 {
  min-height: 16px;
  min-width: 16px;
  max-height: 40px;
  max-width: 40px;
  z-index: 1;
  margin: auto;
}

.ui-o1 .ui-o5 {
  font-size: 16px;
  line-height: 1.25;
  // font-weight: 700;
}

.ui-o1 .ui-o5,
.ui-o1 .ui-o6 {
  text-align: center;
  font-family: GTEestiPro, arial, sans-serif;
  color: #001a34;
}

.ui-o1 .ui-o6 {
  font-size: 14px;
  line-height: 1.29;
}

.ui-o1 .ui-o5 + .ui-o6 {
  margin-top: 6px;
}

.ui-o1 .ui-o6 + .ui-o7 {
  margin-top: 16px;
}

.ui-o1.ui-o8 .ui-o2::before {
  content: "";
  width: 73px;
  height: 69px;
  position: absolute;
  left: 0;
  top: 1px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='73' height='69' xmlns='http://www.w3.org/2000/svg' fill='%23fae670'%3E%3Cpath d='M11.689 58.998c-4.947-4.414-6.772-12.174-8.812-20.234C.839 30.72-1.418 22.359 1.144 15.766c2.56-6.609 9.95-11.47 17.43-13.625C26.049-.031 33.632.515 41.458 1.048c7.846.546 15.955 1.093 21.764 5.066 5.793 3.975 9.285 11.377 9.663 18.648.377 7.27-2.376 14.414-5.027 22.445-2.668 8.034-5.25 16.96-11.086 20.165-5.819 3.202-14.907.69-23.486-1.101-8.58-1.79-16.65-2.859-21.597-7.273z'/%3E%3C/svg%3E");
}

.ui-o1.ui-o8 .ui-o2::after {
  content: "";
  width: 75px;
  height: 72px;
  position: absolute;
  left: 3px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='75' height='72' xmlns='http://www.w3.org/2000/svg' fill='%23ff8970'%3E%3Cpath d='M56.466 66.86c5.453-4.014 8.127-11.732 11.635-19.812 3.508-8.08 7.85-16.522 5.748-22.845-2.101-6.34-10.645-10.578-18.268-14.73-7.624-4.135-14.327-8.2-21.776-9.217-7.45-1.016-15.647 1-20.822 5.927-5.174 4.944-7.328 12.8-9.429 20.398C1.47 34.16-.579 41.5.15 49.253c.712 7.753 4.186 15.936 10.385 19.709 6.182 3.773 15.108 3.153 23.669 2.687 8.578-.465 16.81-.775 22.262-4.789z'/%3E%3C/svg%3E");
}

.ui-o1.ui-o9 .ui-o2::before {
  content: "";
  width: 73px;
  height: 69px;
  position: absolute;
  left: 0;
  top: 1px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='73' height='69' xmlns='http://www.w3.org/2000/svg' fill='%23005BFF'%3E%3Cpath d='M11.689 58.998c-4.947-4.414-6.772-12.174-8.812-20.234C.839 30.72-1.418 22.359 1.144 15.766c2.56-6.609 9.95-11.47 17.43-13.625C26.049-.031 33.632.515 41.458 1.048c7.846.546 15.955 1.093 21.764 5.066 5.793 3.975 9.285 11.377 9.663 18.648.377 7.27-2.376 14.414-5.027 22.445-2.668 8.034-5.25 16.96-11.086 20.165-5.819 3.202-14.907.69-23.486-1.101-8.58-1.79-16.65-2.859-21.597-7.273z'/%3E%3C/svg%3E");
}

.ui-o1.ui-o9 .ui-o2::after {
  content: "";
  width: 75px;
  height: 72px;
  position: absolute;
  left: 3px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='75' height='72' xmlns='http://www.w3.org/2000/svg' fill='%2300A2FF'%3E%3Cpath d='M56.466 66.86c5.453-4.014 8.127-11.732 11.635-19.812 3.508-8.08 7.85-16.522 5.748-22.845-2.101-6.34-10.645-10.578-18.268-14.73-7.624-4.135-14.327-8.2-21.776-9.217-7.45-1.016-15.647 1-20.822 5.927-5.174 4.944-7.328 12.8-9.429 20.398C1.47 34.16-.579 41.5.15 49.253c.712 7.753 4.186 15.936 10.385 19.709 6.182 3.773 15.108 3.153 23.669 2.687 8.578-.465 16.81-.775 22.262-4.789z'/%3E%3C/svg%3E");
}

.ui-p6 {
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  font-family: GTEestiPro, arial, sans-serif;
  background-color: #06ca99;
  color: #ffffff;
  border: 1px solid transparent;
  padding: 3px 8px;
  font-size: 14px;
  line-height: 1.29;
  height: 24px;
  box-sizing: border-box;
}

.ui-p6::before {
  content: "";
}

.ui-p6.ui-p7 {
  padding: 2px 8px;
  font-size: 12px;
  line-height: 1.33;
  height: 20px;
}

// .ui-p6.ui-p8 {
//   font-weight: 700;
// }

.ui-p9 {
  order: 2;
}

.ui-q {
  order: 1;
  margin-right: 4px;
}

.ui-q.ui-q0 {
  order: 3;
  margin-left: 4px;
  margin-right: 0;
}

.ra-d7 {
  margin-bottom: 16px;
}

// .ra-d8 {
// overflow: auto;
// }

.ra-b6 + .ra-b6 {
  margin-top: 48px;
}

.ra-b7 + .ra-b7 {
  margin-top: 32px;
}

@font-face {
  font-family: GTEestiPro;
  // font-weight: 700;
  font-display: swap;
  src: url(//cdn1.ozone.ru/graphics/test/fonts/GT-Eesti-Pro-Display-Bold.woff2) format("woff2"), url(//cdn1.ozone.ru/graphics/test/fonts/GT-Eesti-Pro-Display-Bold.woff) format("woff");
}

@font-face {
  font-family: GTEestiPro;
  font-weight: 400;
  font-display: swap;
  src: url(//cdn1.ozone.ru/graphics/test/fonts/GT-Eesti-Pro-Text-Book.woff2) format("woff2"), url(//cdn1.ozone.ru/graphics/test/fonts/GT-Eesti-Pro-Text-Book.woff) format("woff");
}

body {
  background: #eaedef;
}

.ra-a {
  font-family: GTEestiPro, arial, sans-serif;
  background: #ffffff;
  margin: 0 auto;
  padding: 8px 24px 48px;
  box-sizing: border-box;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  max-width: 1520px;
}

.ra-a .ra-a0 {
  margin-bottom: 16px;
}

.ra-a .ra-a1 li {
  line-height: 1.43;
  font-size: 14px;
}

.ra-a .ra-a2 {
  margin: 32px 0 16px;
  font-size: 20px;
  line-height: 1.3;
}

.ra-a .ra-a3 .ra-a4 {
  margin-bottom: 16px;
}

.ra-a .ra-a3 .ra-a5 {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 300px;
  background: #ffffff;
}

.ra-a .ra-a3 .ra-a5.ra-a6 {
  display: block;
}

.ra-a .ra-a3 .ra-a5 .ra-a7 {
  position: absolute;
  top: 8px;
  right: 128px;
}

.ra-a .ra-a3 .ra-a5 .ra-a8 {
  position: absolute;
  top: 8px;
  right: 24px;
}

.ra-a .ra-a3 .ra-a5 .ra-a9 {
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.ra-a .ra-a3 .ra-a5 .ra-b {
  height: 100%;
  width: 100%;
}

.ra-a .ra-b0 {
  font-family: GTEestiPro, arial, sans-serif;
}

.ra-a .ra-b0 .ra-b1 {
  margin: 0 auto;
  font-family: Consolas, Courier, monospace;
  display: block;
  font-size: 13px;
  color: #ffffff;
  background: #4d5f71;
  border-radius: 4px;
  padding: 15px 16px 14px;
}

.ra-a .ra-b0 .ra-b2 {
  margin-top: 24px;
}

.ra-a .ra-b3 {
  max-width: 1472px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 28px;
  background: #eaedef;
  border: 1px solid rgba(0, 26, 52, 0.05);
}

.ra-a .ra-b3 .ra-b4 {
  border: 1px dashed #b1b9c2;
  background: #ffffff;
}

.ra-a .ra-b3.ra-b5 {
  max-width: 320px;
  padding: 0 16px;
}

.ra-a .ra-b3.ra-b5 .ra-b4 {
  background: #ffffff;
}
</style>
