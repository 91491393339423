<template>
  <div>
    <div class="constructor-add-panel__items-list">
      <div
        :class="'constructor-add-panel__item' + (componentForAdding === component? ' constructor-add-panel__item--active': '')"
        v-for="(component, index) in componentsList"
        :key="index"
        @click="addComponent(component)"
      >
        <div class="constructor-add-panel__item__text" :title="component">
          {{ component }}
        </div>
        <div class="constructor-add-panel__item__icon">
          <Check />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";

import Check from "../ui/icons/constructor/Check.vue";

export default {
  name: "AddComponentPanel",
  components: {
    Check,
  },
  data() {
    return {
      componentForAdding: null,
    };
  },
  computed: {
    ...mapState({
      sectionsParams: (state) => state.constructorData.sectionsParams,
      currentTemplate:(state) => state.constructorData.currentTemplate,
    }),
    componentsList() {
      const componentsList = [];
      Object.keys(this.sectionsParams).map((elem) => {
        const template = (this.currentTemplate !== 'ozon_template' && this.currentTemplate !== 'img_template' && this.currentTemplate !== 'amazon_template' && this.currentTemplate !== 'eld_template') ? 'master_template' : this.currentTemplate;
        if(this.sectionsParams[elem].template === template) {
          componentsList.push(elem);
        }
      });
      return componentsList;
    },
  },
  methods: {
    ...mapActions("constructorData", ["addComponent"]),
  },
};
</script>

<style lang="scss">
.constructor-add-panel {
  &__items-list {
    margin-bottom: 10px;

    &:hover {
      .constructor-add-panel__item {
        color: #8d9ca8;

        &:hover {
          color: #000000;
        }
      }
    }
  }

  &__item {
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background: #f1f4f8;
      color: #000000;

      .constructor-add-panel__item__icon {
        display: block;
      }
    }

    &__text {
      width: calc(100% - 24px);
      font-size: 14px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__icon {
      display: none;
      width: 24px;

      svg {
        display: block;
      }
    }
  }

  &__button {
    display: block;
    margin: 0 auto;

    &--disabled {
      cursor: default;
      opacity: 0.2;
    }
  }
}
</style>
