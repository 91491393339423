<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12L11 15L16 9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: "Check",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
