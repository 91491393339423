<template>
  <div class="font-size__panel">
    <div class="font-size__panel__label">
      {{label}}
    </div>
    <div class="font-size__panel__items">
      <label
        v-for="(option, index) in options"
        :key="index"
        class="font-size__panel__item"
      >
        <input
          type="radio"
          :name="id"
          :value="option"
          v-model="currentFontSize"
        >
        <div :class="['font-size__panel__item__icon', 'font-size__panel__item__icon--size-'+option]">
          {{option}}
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "FontSizePanel",
  
  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    index: {
      type: Number,
    },
    currentValue: {
      type: String,
    },
  },
  data() {
    return {
      currentFontSize: this.currentValue,
      id: null,
    };
  },
  watch: {
    currentFontSize: function() {
      this.$emit('fontSizeUpdate',{index: this.index, value:this.currentFontSize});
    },
    currentValue: function() {
      this.currentFontSize = this.currentValue;
    },
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>

<style lang="scss" scoped>
.font-size__panel {
  &__label {
    font-size: 14px;
    line-height: 1.29;
    margin-bottom: 12px;
  }

  &__items {
    display: flex;
    align-items: stretch;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #e3e9ed;
  }

  &__item {
    // width: calc((100% - 4px * 2) / 3);
    flex: 1 0 auto;

    &:last-child {
      margin-right: 0;
    }

    &__icon {
      margin-right: 4px;
      border-radius: 4px;
      padding: 8px 4px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer;
      line-height: 0.8;
      height: 100%;

      &--size {
        &-1 {
          font-size: 10px;
        }

        &-2 {
          font-size: 13px;
        }

        &-3 {
          font-size: 17px;
        }

        &-4 {
          font-size: 20px;
        }

        &-5 {
          font-size: 24px;
        }

        &-6 {
          font-size: 27px;
        }

        &-7 {
          font-size: 30px;
        }
      }

      svg {
        display: block;
      }
    }

    input {
      display: none;
    }

    input:checked ~ .font-size__panel__item__icon {
      background-color: #e3e9ed;
      cursor: default;
    }
  }
}
</style>
