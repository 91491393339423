<template>
  <div
    class="stream-button"
    :style="{background: params.background}"
  >
  <a
    :href="domain + params.src"
    class="stream-button-button"
    :class="`stream-text-${params.fontSize}`"
    :style="{
      fontFamily: params.fontFamily,
      fontWeight: params.fontWeight,
      background: params.buttonBackground,
      color: params.color,
      borderRadius: params.borderRadius,
    }"
    target="_blank"
    download
  >
    {{ params.content }}
  </a>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "EldButton",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.stream-button {
  max-width: 1200px;
  margin: 0 auto;

  .stream-button-button {
    display: flex;
    width: 100%;
    max-width: 300px;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    border-radius: 8px;
    line-height: 1;
    margin: 30px 0 0;
    cursor: pointer;

    svg {
      display: block;
      width: 22px;
      height: auto;
      margin-right: 16px;
    }
  }
}

.small-pc.tablet {
  .stream-ar {
    .stream-ar-text-outer {
      width: 35%;
    }

    .stream-ar-img {
      width: 65%;
    }
  }

  &.mobile {
    .stream-ar {
      .stream-ar-inner {
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;
      }

      .stream-ar-text-outer {
        width: 100%;
        padding: 0;
      }

      .stream-ar-img {
        width: 100%;
      }
      .stream-ar-text {
        text-align: center;
        max-width: none;
        padding: 40px 0 0;
        width: 95%;
        margin: auto;
      }

      .stream-ar-button {
        margin: 30px auto 0;
        width: 95%;
      }
    }
  }
}
</style>