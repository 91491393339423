<template>
  <div
    class="stream-icons"
    :style="{ background: params.background, justifyContent: params.colsAlign }"
  >
    <div
      v-for="(icon, elementIndex) in params.innerElements"
      :class="
        'stream-icon' +
        (activeElement === elementIndex && activeComponent === componentIndex
          ? ' constructor-stream-icon--active'
          : '')
      "
      :key="elementIndex"
      @click.stop="setActiveElement({ componentIndex, elementIndex })"
    >
      <div class="stream-icon-inner">
        <div class="stream-icon-img">
          <img :src="domain + icon.props.src" alt="" :style="{borderRadius:params.borderRadius+'px'}" />
        </div>
        <div
          :class="['stream-icon-title', 'stream-text-' + params.titleFontSize]"
          :style="{
            textAlign: params.textAlign,
            '--textalignmobile': params.textAlignMobile,
            color: params.titleColor,
            fontFamily: params.titleFontFamily,
            fontWeight: params.titleFontWeight,
          }"
          v-html="handleNewLine(icon.props.titleContent)"
        ></div>
        <div
          :class="[
            'stream-icon-description',
            'stream-text-' + params.descriptionFontSize,
          ]"
          :style="{
            textAlign: params.textAlign,
            '--textalignmobile': params.textAlignMobile,
            color: params.descriptionColor,
            fontFamily: params.descriptionFontFamily,
            fontWeight: params.descriptionFontWeight,
          }"
          v-html="handleNewLine(icon.props.descriptionContent)"
        ></div>
      </div>
      <RemoveItem
        v-if="elementIndex === activeElement"
        @click.native="removeElement({ componentIndex, elementIndex })"
      />
    </div>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";
export default {
  name: "Icons",
  props: {
    handleNewLine: {
      type: Function,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeElement: (state) => state.constructorData.activeElement,
    }),
  },
  methods: {
    ...mapActions("constructorData", [
      "setActiveElement",
      "removeComponent",
      "removeElement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.constructor-stream-icon--active {
  .stream-icon-inner {
    box-shadow: 0 0 1px 1px #f500ed;
  }
}

.stream-icons {
  padding: 16px 0;
  text-align: center;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;

  .stream-icon {
    width: 25%;
    position: relative;
  }

  .stream-icon-inner {
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;

    .stream-icon-img {
      max-width: 168px;
      width: 60%;
      margin: 0 auto 20px;

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }

    .stream-icon-title {
      text-align: center;
      // font-size: 16px !important;
      // line-height: 1.5;
      width: 100%;
    }

    .stream-icon-description {
      text-align: center;
      // font-size: 16px !important;
      // line-height: 1.5;
      width: 100%;
    }
  }
}

.small-pc.tablet.mobile .stream-icon {
  width: 50%;

  .stream-icon-title,
  .stream-icon-description {
    text-align: var(--textalignmobile) !important;
  }
}
</style>
