<template>
  <div class="constructor__input-color">
    <div class="constructor__input-color__title">
      {{label}}
    </div>
    <div class="constructor__input-color__row">
      <input
        class="constructor__input-color__input"
        type="color"
        :value="startValue"
        @input="$emit('input', $event.target.value)"
      >
      <input
        class="constructor__input-color__text-input"
        type="text"
        :value="startValue"
        v-debounce:500ms="updateInput"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorPanel",
  props: {
    startValue: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    updateInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.constructor__input-color {
  margin-right: -16px;
  margin-left: -16px;
  padding: 16px;
  border-top: 1px solid #d6dee2;
  border-bottom: 1px solid #d6dee2;

  &__title {
    font-size: 14px;
    line-height: 1.29;
    margin-bottom: 12px;
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__input {
    padding: 0;
    border: 1px solid #d6dee2;
    display: block;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    min-width: 24px;
    background: none;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    &::-webkit-color-swatch {
      border: none;
    }
  }

  &__text-input {
    margin-left: 12px;
    border: none;
    background: transparent;
    width: auto;
  }
}
</style>
