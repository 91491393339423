var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stream-accordion"},[_c('input',{staticClass:"stream-accordion-indicator",attrs:{"type":"checkbox","name":'stream-accordion-' + _vm.componentIndex,"id":'stream-accordion-' + _vm.componentIndex}}),_c('div',{staticClass:"stream-accordion-image",style:({ background: _vm.params.background })},[(_vm.params.youtubeID)?_c('div',{staticClass:"stream-accordion-youtube__video"},[_c('iframe',{style:({borderRadius:_vm.params.borderRadius+'px'}),attrs:{"src":`https://www.youtube.com/embed/${_vm.params.youtubeID}?enablejsapi=1&rel=0&autoplay=0`,"frameborder":"0"}})]):(_vm.params.videoSrc)?_c('div',[_c('video',{style:({borderRadius:_vm.params.borderRadius+'px'}),attrs:{"src":_vm.domain + _vm.params.videoSrc,"controls":_vm.params.controls == 'true' ? true : false,"playsinline":"","muted":"","loop":_vm.params.repeat == 'true' ? true : false,"autoplay":_vm.params.autoplay == 'true' ? true : false},domProps:{"muted":true}})]):_c('img',{style:({borderRadius:_vm.params.borderRadius+'px'}),attrs:{"src":_vm.domain + _vm.params.src,"alt":""}})]),_c('div',{class:['stream-accordion-content','stream-'+_vm.params.spacer+'-spacer']},_vm._l((_vm.params.innerElements),function(item,elementIndex){return _c('div',{key:elementIndex,class:[
        'stream-accordion-content-item',
        'stream-' + item.props.imgPosition,
        'stream-' + item.props.imgPositionMobile + '-mobile',
        _vm.activeElement === elementIndex && _vm.activeComponent === _vm.componentIndex
          ? 'stream-accordion--active'
          : '',
      ],style:({ background: item.props.background }),on:{"click":function($event){$event.stopPropagation();return _vm.setActiveElement({ componentIndex: _vm.componentIndex, elementIndex })}}},[_c('div',{staticClass:"stream-accordion-content-item-inner"},[_c('div',{staticClass:"stream-accordion-content-img"},[(item.props.youtubeID)?_c('div',{staticClass:"stream-accordion-content-youtube__video"},[_c('iframe',{style:({borderRadius:_vm.params.borderRadius+'px'}),attrs:{"src":`https://www.youtube.com/embed/${item.props.youtubeID}?enablejsapi=1&rel=0&autoplay=0`,"frameborder":"0"}})]):(item.props.videoSrc)?_c('div',[_c('video',{style:({borderRadius:_vm.params.borderRadius+'px'}),attrs:{"src":_vm.domain + item.props.videoSrc,"controls":item.props.controls == 'true' ? true : false,"playsinline":"","muted":"","loop":item.props.repeat == 'true' ? true : false,"autoplay":item.props.autoplay == 'true' ? true : false},domProps:{"muted":true}})]):_c('img',{style:({borderRadius:_vm.params.borderRadius+'px'}),attrs:{"src":_vm.domain + item.props.src,"alt":""}})]),_c('div',{staticClass:"stream-accordion-content-content"},[_c('div',{class:[
              'stream-accordion-content-title',
              'stream-text-' + item.props.titleFontSize,
            ],style:({
              textAlign: item.props.textAlign,
              '--textalignmobile': item.props.textAlignMobile,
              color: item.props.titleColor,
              fontFamily: _vm.params.titleFontFamily,
              fontWeight: _vm.params.titleFontWeight,
            }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(item.props.titleContent))}}),_c('div',{class:[
              'stream-accordion-content-description',
              'stream-text-' + item.props.descriptionFontSize,
            ],style:({
              textAlign: item.props.textAlign,
              '--textalignmobile': item.props.textAlignMobile,
              color: item.props.descriptionColor,
              fontFamily: _vm.params.descriptionFontFamily,
              fontWeight: _vm.params.descriptionFontWeight,
            }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(item.props.descriptionContent))}})])]),(item.props.disclaimerContent.length)?_c('div',{staticClass:"stream-accordion-content-item-disclaimer",class:'stream-text-' + item.props.disclaimerFontSize,style:({
              textAlign: item.props.disclaimerTextAlign,
              color: item.props.disclaimerColor,
              fontFamily: _vm.params.descriptionFontFamily,
              fontWeight: _vm.params.descriptionFontWeight,
            }),domProps:{"innerHTML":_vm._s(_vm.handleNewLine(item.props.disclaimerContent))}}):_vm._e(),(
          _vm.activeElement === elementIndex &&
          _vm.activeComponent === _vm.componentIndex &&
          _vm.params.innerElements.length > 1
        )?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeElement({ componentIndex: _vm.componentIndex, elementIndex })}}}):_vm._e()],1)}),0),_c('label',{staticClass:"stream-accordion-button",class:_vm.params.customCloseIcon ? 'stream-accordion-button-no-rotate' : null,attrs:{"for":'stream-accordion-' + _vm.componentIndex}},[(_vm.params.customIcon)?_c('div',[(_vm.params.customCloseIcon)?_c('div',[_c('div',{staticClass:"stream-custom-button-open",domProps:{"innerHTML":_vm._s(_vm.params.customIcon)}}),_c('div',{staticClass:"stream-custom-button-close",domProps:{"innerHTML":_vm._s(_vm.params.customCloseIcon)}})]):_c('div',{staticClass:"stream-custom-button",domProps:{"innerHTML":_vm._s(_vm.params.customIcon)}})]):_c('svg',{staticClass:"injected-svg",attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","version":"1.1","x":"0px","y":"0px","viewBox":"-20 -20 208 208","xml:space":"preserve"}},[_c('circle',{staticStyle:{"opacity":"0.75","fill":"#000000"},attrs:{"cx":"84","cy":"84","r":"73.5","opacity":"0.75"}}),_c('circle',{staticClass:"open-close-solid-stroke",staticStyle:{"opacity":"1","fill":"none","stroke":"#ffffff","stroke-width":"3","stroke-miterlimit":"10"},attrs:{"cx":"84","cy":"84","r":"61","opacity":"1"}}),_c('g',{staticStyle:{"fill":"#ffffff"},attrs:{"transform":"matrix(1,0,0,1,0,0)"}},[_c('rect',{attrs:{"x":"72","y":"82","width":"24","height":"4"}}),_c('rect',{attrs:{"x":"82","y":"72","width":"4","height":"24"}})])])]),(_vm.componentIndex === _vm.activeComponent)?_c('RemoveItem',{nativeOn:{"click":function($event){return _vm.removeComponent(_vm.componentIndex)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }