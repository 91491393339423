<template>
  <div class="text-align__panel">
    <div class="text-align__panel__label">
      {{label}}
    </div>
    <div class="text-align__panel__items">
      <label
        v-for="(option, index) in options"
        :key="index"
        class="text-align__panel__item"
      >
        <input
          type="radio"
          :name="id"
          :value="option"
          v-model="currentAlign"
        >
        <div class="text-align__panel__item__icon">
          <TextAlignCenter v-if="option === 'center'" />
          <TextAlignLeft v-else-if="option === 'left'" />
          <TextAlignRight v-else-if="option === 'right'" />
          <TextAlignJustify v-else-if="option === 'justify'" />
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import TextAlignCenter from "../../../ui/icons/constructor/TextAlignCenter";
import TextAlignLeft from "../../../ui/icons/constructor/TextAlignLeft";
import TextAlignRight from "../../../ui/icons/constructor/TextAlignRight";
import TextAlignJustify from "../../../ui/icons/constructor/TextAlignJustify";
export default {
  name: "TextAlignPanel",
  components: {
    TextAlignCenter,
    TextAlignLeft,
    TextAlignRight,
    TextAlignJustify,
  },
  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    index: {
      type: Number,
    },
    currentValue: {
      type: String,
    },
  },
  data() {
    return {
      currentAlign: this.currentValue,
      id: null,
    };
  },
  watch: {
    currentAlign: function() {
      this.$emit('textAlignUpdate',{index: this.index, value:this.currentAlign});
    },
    currentValue: function() {
      this.currentAlign = this.currentValue;
    },
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>

<style lang="scss" scoped>
.text-align__panel {
  &__label {
    font-size: 14px;
    line-height: 1.29;
    margin-bottom: 12px;
  }

  &__items {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #e3e9ed;
  }

  &__item {
    width: calc((100% - 4px * 2) / 3);

    &:last-child {
      margin-right: 0;
    }

    &__icon {
      margin-right: 4px;
      border-radius: 4px;
      padding: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        display: block;
      }
    }

    input {
      display: none;
    }

    input:checked ~ .text-align__panel__item__icon {
      background-color: #e3e9ed;
      cursor: default;
    }
  }
}
</style>
