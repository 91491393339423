<template>
  <div :class="['ra-b8', activeType === 'mobile' ? 'ra-b6' : 'ra-b7']" style="padding-top: 56.1667%;">
    <video controls="controls" muted="muted" class="ra-b9">
      <source :src="domain + params.src" type="video/mp4" />
    </video>
    <RemoveItem
      v-if="componentIndex === activeComponent"
      @click.native="removeComponent(componentIndex)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoveItem from "../../../ui/RemoveItem.vue";

export default {
  name: "OzonVideo",
  props: {
    params: {
      type: Object,
    },
    componentIndex: {
      type: Number,
      default: null,
    },
  },
  components: {
    RemoveItem,
  },
  data() {
    return {
      domain: process.env.VUE_APP_CONSTRUCTOR_URL,
    };
  },
  computed: {
    ...mapState({
      activeComponent: (state) => state.constructorData.activeComponent,
      activeType: (state) => state.constructorData.activeType,
    }),
  },
  methods: {
    ...mapActions("constructorData", ["setActiveElement", "removeComponent"]),
  },
};
</script>
