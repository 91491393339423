<template>
  <div class="remove-item">
    <Close />
  </div>
</template>

<script>
import Close from "../../ui/icons/constructor/Close";
export default {
  name: "RemoveItem",
  components: {
    Close,
  },
};
</script>

<style lang="scss" scoped>
.remove-item {
  position: absolute;
  width: 24px;
  right: -12px;
  top: -12px;

  svg {
    display: block;
  }
}
</style>