<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0-570198)">
      <path d="M19.0002 5C19.2654 5 19.5198 5.10536 19.7073 5.29289C19.8949 5.48043 20.0002 5.73478 20.0002 6V15H4.00021V6C4.00021 5.73478 4.10557 5.48043 4.29311 5.29289C4.48064 5.10536 4.735 5 5.00021 5H19.0002ZM5.00021 3C4.20456 3 3.4415 3.31607 2.87889 3.87868C2.31628 4.44129 2.00021 5.20435 2.00021 6V17H22.0002V6C22.0002 5.20435 21.6841 4.44129 21.1215 3.87868C20.5589 3.31607 19.7959 3 19.0002 3H5.00021Z" fill="#8D9CA8"/>
      <path d="M24 19H0C0.411836 20.1652 1.69397 21 3.00019 21H21.0002C22.3064 21 23.5882 20.1652 24 19Z" fill="#8D9CA8"/>
    </g>
    <defs>
      <clipPath id="clip0-570198">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
  export default {
    name: "Laptop",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
